import {Link} from "react-router-dom";
import {Popconfirm, Space, Tooltip} from "antd";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {deleteCable} from "../../../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import PreviewImageCard from "../../../../../Components/Common/PreviewImageCard/PreviewImageCard";

const LineTable = ({item, index, toggleModal}) => {
    const queryClient = useQueryClient();
    const [openConfirmDeleteMdl, setOpenConfirmDeleteMdl] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const deleteCableMutation = useMutation(deleteCable, {
        onSuccess: () => {
            queryClient.invalidateQueries("audit");
            queryClient.invalidateQueries("cassettes");
            toast.success("Câble supprimé avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const showPopconfirm = () => {
        setOpenConfirmDeleteMdl(true);
    };
    const handleOk = (cable) => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpenConfirmDeleteMdl(false);
            setConfirmLoading(false);
        }, 2000);
        if(cable) {
            deleteCableMutation.mutate(cable?.cableId)
        }
    };
    const handleCancel = () => {
        setOpenConfirmDeleteMdl(false);
    };

    return (
        <>
            <tr key={index} style={{cursor: "pointer"}}>
                <td>{item.cableName || '-'}</td>
                <td className="fw-medium">{item.cableCapacity || '-'}</td>
                <td className="fw-medium">{item.cableModulo || '-'}</td>
                <td>{item.ncinumber || '-'}</td>
                <td>{item.manufacturerReference || '-'}</td>
                <td>{item.manufacturingYear || '-'}</td>
                <td>{item.installationYear || '-'}</td>
                <td className="d-flex justify-content-around">
                    <Space size='large'>
                        <Link to="#" onClick={() => toggleModal(index)} className="me-4">
                            <i className="ri-eye-line fs-17 lh-1 align-middle"></i>
                        </Link>

                        <Popconfirm
                            title="Suppression câble"
                            description="Voulez-vous supprimez le câble ?"
                            open={openConfirmDeleteMdl}
                            onConfirm={() => handleOk(item)}
                            okButtonProps={{
                                loading: confirmLoading,
                            }}
                            onCancel={handleCancel}
                        >
                            <Tooltip title="Supprimer">
                                <Link to="" onClick={showPopconfirm}>
                                    <i className="ri-delete-bin-line fs-17 lh-1 align-middle"></i>
                                </Link>
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                </td>
            </tr>
        </>
    )
}
export default LineTable;