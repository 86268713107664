import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BreadCrumb from "../BreadCrumb";

export const AuditListSkeleton = () => {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0">{<Skeleton width={250} />}</h4>
              {<Skeleton width={150} height={40} />}
            </div>
          </CardHeader>

          <CardBody>
            <div className="listjs-table" id="customerList">
              <Row className="g-4 mb-3">
                <Col>
                  <div>
                    <div className="search-box ms-2">
                      {<Skeleton height={40} />}
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive table-card mt-3 mb-1">
                <table
                  className="table align-middle table-nowrap"
                  id="customerTable"
                >
                  <thead className="table-light">
                    <tr>
                      <th scope="col" style={{ width: "50px" }}>
                        <div className="form-check">
                          {<Skeleton width={18} />}
                        </div>
                      </th>
                      <th>{<Skeleton width={50} />}</th>
                      <th>{<Skeleton width={50} />}</th>
                      <th>{<Skeleton width={55} />}</th>
                      <th>{<Skeleton width={90} />}</th>
                      <th>{<Skeleton width={90} />}</th>
                      <th>{<Skeleton width={100} />}</th>
                      <th>{<Skeleton width={90} />}</th>
                      <th>{<Skeleton width={80} />}</th>
                      <th>{<Skeleton width={80} />}</th>
                    </tr>
                  </thead>
                  <tbody className="list form-check-all">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => {
                      return [
                        <tr key={key}>
                          <th scope="row">
                            <div className="form-check">
                              {<Skeleton width={18} />}
                            </div>
                          </th>
                          <td className="customer_name">
                            {<Skeleton width={100} />}
                          </td>
                          <td className="email">{<Skeleton width={130} />}</td>
                          <td className="phone">{<Skeleton width={150} />}</td>
                          <td className="date">{<Skeleton width={150} />}</td>
                          <td className="date">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <div className="flex-shrink-0 avatar-xs me-1">
                                  {<Skeleton circle width={30} height={30} />}
                                </div>
                              </div>
                              <div className="flex-grow-1 ms-1 name fs-12">
                                {<Skeleton width={100} />}
                              </div>
                            </div>
                          </td>
                          <td className="date">{<Skeleton width={150} />}</td>
                          <td className="date">{<Skeleton width={150} />}</td>
                          <td className="status">{<Skeleton width={100} />}</td>
                          <td className="status">{<Skeleton width={100} />}</td>
                        </tr>,
                      ];
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between">
                <div>{<Skeleton width={200} />}</div>
                <div className="d-flex justify-content-end">
                  <div>{<Skeleton width={250} height={40} />}</div>
                  <div className="mx-1 mt-2">{<Skeleton width={120} />}</div>
                  <div className="pagination-wrap hstack gap-2">
                    {<Skeleton width={100} height={40} />}
                    <ul className="pagination listjs-pagination mb-0"></ul>
                    {<Skeleton width={100} height={40} />}
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

// export default AuditListSkeleton;

export const AuditOverviewSkeleton = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Détails Tâches"
          pageTitle="Audits"
          route0="/demandes"
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                    <Col xl={4}>
                      <div>
                        {<Skeleton width={500} height={350} />}
                      </div>
                      <div className="mt-4 mb-2">
                        {<Skeleton width={130} />}
                      </div>
                      <div className="mb-5">
                        {<Skeleton width={500} height={350} />}
                      </div>
                    </Col>
                    <Col xl={8}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="me-3 mt-1">{<Skeleton width={200} height={20} />}</div>
                          <div>{<Skeleton circle width={30} height={30} />}</div>
                        </div>
                        <div>
                          {<Skeleton width={30} height={30} />}
                        </div>
                      </div>
                      <div className="hstack gap-3 flex-wrap mt-2">
                        <div className="mt-1">
                          <div className="hstack flex-wrap gap-2">
                            {<Skeleton width={130} />}
                          </div>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          {<Skeleton width={100} />}
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          {<Skeleton width={100} />}
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          {<Skeleton width={100} />}
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted d-flex">
                            {<Skeleton circle width={30} height={30} />}
                            <div className="mx-1 mt-2">
                              {<Skeleton width={120} />}
                            </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        {<Skeleton width={200} />}
                      </div>
                      <Row>
                        <Col sm={4}>
                          <div className="mt-3">
                            <h5 className="fs-15">{<Skeleton width={50} />}</h5>
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={130} />} <br />
                                  <span className="text-body fw-medium">
                                    {<Skeleton width={200} />}
                                  </span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mt-3">
                            <h5 className="fs-15">{<Skeleton width={70} />}</h5>
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mt-3">
                            <h5 className="fs-15">{<Skeleton width={100} />}</h5>
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>{<Skeleton width={50} />}</th>
                                <th>{<Skeleton width={60} />}</th>
                                <th>{<Skeleton width={100} />}</th>
                                <th>{<Skeleton width={100} />}</th>
                                <th>{<Skeleton width={80} />}</th>
                                <th>{<Skeleton width={50} />}</th>
                                <th>{<Skeleton width={100} />}</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {[1, 2, 3, 4, 5].map((item, key) => {
                                return [
                                  <tr key={key}>
                                    <td className="name">{<Skeleton width={30} />}</td>
                                    <td className="email">{<Skeleton width={30} />}</td>
                                    <td className="phone">{<Skeleton width={30} />}</td>
                                    <td className="date">{<Skeleton width={30} />}</td>
                                    <td className="date">{<Skeleton width={30} />}</td>
                                    <td className="date">{<Skeleton width={30} />}</td>
                                    <td className="date">{<Skeleton width={30} />}</td>
                                  </tr>,
                                ];
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Col>

                  {/* <Col xl={8}>
                    <div className="mt-xl-0 mt-5">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div className="d-flex">
                            <h4 className="mt-1">{<Skeleton width={200} />}</h4>
                            <div
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              data-bs-placement="top"
                              title=""
                              className="mx-2"
                            >
                              {<Skeleton circle width={30} height={30} />}
                            </div>
                          </div>
                          <div className="hstack gap-3 flex-wrap">
                            <div className="mt-1">
                              <div className="hstack flex-wrap gap-2">
                                {<Skeleton width={130} />}
                              </div>
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              {<Skeleton width={100} />}
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              {<Skeleton width={100} />}
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              {<Skeleton width={100} />}
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted d-flex">
                                {<Skeleton circle width={30} height={30} />}
                                <div className="mx-1 mt-2">
                                  {<Skeleton width={120} />}
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <div>
                            {<Skeleton width={30} height={30} />}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 text-muted">
                        {<Skeleton width={200} />}
                      </div>

                      <Row>
                        <Col sm={4}>
                          <div className="mt-3">
                            <h5 className="fs-15">{<Skeleton width={50} />}</h5>
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={180} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={130} />} <br />
                                  <span className="text-body fw-medium">
                                    {<Skeleton width={200} />}
                                  </span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mt-3">
                            <h5 className="fs-15">{<Skeleton width={70} />}</h5>
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mt-3">
                            <h5 className="fs-15">{<Skeleton width={100} />}</h5>
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                  {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                <span className="text-muted">
                                {<Skeleton width={150} />}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                      <div className="product-content mt-3">
                        <h5 className="fs-15 mb-3">{<Skeleton width={100} />}</h5>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// export default AuditOverviewSkeleton;
