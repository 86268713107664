import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Container} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';
import toast from 'react-hot-toast';
import FilterCard from './components/filterCard';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteProject, getProjects } from '../../api/projects/projectsApi';
import AddEditFormProject from './components/addEditFormProject';
import ProjectCardSkeleton from '../../Components/Common/skeletons/projectCardskeleton';
import {Button as BtnAntd, Empty, Result, Spin} from "antd";
import loading from "../../assets/images/loading.gif"
import HistoryContext from "../../Components/Hooks/HistoryContext";
import {PlusOutlined} from "@ant-design/icons";
import ProjectTable from "./components/projectTable";
import ProjectCard from "./components/projectCard";
import CustomPagination from "../../Components/Common/CustomPagination";
import EmptyData from "../../Components/Common/EmptyData/EmptyData";

const ProjectList = () => {
    const history = useContext(HistoryContext)
    document.title = `Projets | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;

    const queryClient = useQueryClient();
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [project, setProject] = useState(null); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 8;
    const [toggleList, setToggleList] = useState(false);
    const [key, setKey] = useState("")

    const {
        isLoading,
        isError,
        error,
        data: projects,
    } = useQuery("projects", getProjects);

    // Delete Project
    const deleteProjectMutation = useMutation(deleteProject, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("projects");
            toast.success("Le projet a été supprimé avec succès")
        },
    });

    // Toggle Modal
    const toggle = useCallback(() => {
        if (modal) {
          setModal(false);
          setProject(null);
        } else {
          setModal(true);
        }
    }, [modal]);
    
    // Set updated Project
    const handleProjectClick = useCallback(
        (arg) => {
            const project = arg;
            setProject({
                projectId: project.projectId,
                projectName: project.projectName,
                projectDescription: project.projectDescription,
                deadline: project.deadline,
                status: project.status,
                projectImgUrl: project.projectImgUrl
            });

            setIsEdit(true);
            toggle();
        },
        [toggle]
    );

    const handleProjectClicks = () => {
        setProject(null);
        setModal(!modal);
        setIsEdit(false);
        toggle();
    };

    const onClickData = (project) => {
        setProject(project);
        setDeleteModal(true);
    };

    const handleDeleteProjectData = () => {
        if (project) {
            deleteProjectMutation.mutate(project.projectId)
            setDeleteModal(false);
        }
    };

    const filteredData = projects?.subProjects?.filter((item) =>
        item.projectName.toLowerCase().includes(key?.toLowerCase())
    );

    useEffect(() => {
        setCurrentPage(1);
    }, [key]);

    const paginatedData = filteredData?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    useEffect(() => {
        const list = document.querySelectorAll(".team-list");
        const buttonGroups = document.querySelectorAll(".filter-button");
        for (let i = 0; i < buttonGroups.length; i++) {
            buttonGroups[i].addEventListener("click", onButtonGroupClick);
        }

        function onButtonGroupClick(event) {
            if (
            event.target.id === "list-view-button" ||
            event.target.parentElement.id === "list-view-button"
            ) {
            document.getElementById("list-view-button").classList.add("active");
            document.getElementById("grid-view-button").classList.remove("active");
            list.forEach(function (el) {
                // el.classList.add("list-view-filter");
                el.classList.remove("grid-view-filter");
            });
            } else {
            document.getElementById("grid-view-button").classList.add("active");
            document.getElementById("list-view-button").classList.remove("active");
            list.forEach(function (el) {
                // el.classList.remove("list-view-filter");
                el.classList.add("grid-view-filter");
            });
            }
        }
    }, []);

    const taskbarwidth = (item) => {
        return ((item.completedTaskCount+item.validatedTaskCount) * 100) / item.totalTaskCount || 0;
    };

    if (isLoading) {
        //return <Spin spinning={isLoading} fullscreen />;
      // return <ProjectCardSkeleton />;
        return (
            <React.Fragment>
           
            <div className="page-content">
                <Container style={{display:"flex",'justify-content': 'center','align-items': 'center',height:"80vh"}}>
                <img src={loading} alt='loading'/>
                </Container>
            </div>
        </React.Fragment>
  
        );
    }

    if (isError) {
        return (
            <Result
                status="warning"
                title={`${error.message}}`}
                extra={
                    <Button type="primary" key="console" onClick={() => window.location.reload()}>
                        Refresh
                    </Button>
                }
            />
        );
    }

    const listProject = () => {
        return (
            <>
                <Button
                    onClick={() => toggleList ? setToggleList(!toggleList) : null}
                    color="info"
                    id="grid-view-button"
                    // className={`btn btn-soft-info nav-link btn-icon fs-14 active filter-button`}
                    className={`btn btn-soft-info nav-link btn-icon fs-10 ${!toggleList ? 'active' : null} filter-button`}
                >
                    <i className="ri-grid-fill"></i>
                </Button>
                <Button
                    onClick={() => !toggleList ? setToggleList(!toggleList) : null}
                    color="info"
                    id="list-view-button"
                    className={`btn btn-soft-info nav-link btn-icon fs-10 ${toggleList ? 'active' : null} filter-button`}
                >
                    <i className="ri-list-unordered"></i>
                </Button>
            </>
        )
    }

    return (
        <React.Fragment>
            <DeleteModal
                item={project?.projectName}
                show={deleteModal}
                onDeleteClick={() => handleDeleteProjectData()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        page="Projets"
                        history={history}
                        data={projects?.subProjects?.map((item) => ({...item, name: item?.projectName}))}
                        setKey={setKey}
                        btnName="Nouveau projet"
                        handleClick={handleProjectClicks}
                        children={listProject}
                    />

                    {toggleList && (
                        <ProjectTable
                            paginatedData={paginatedData}
                            handleProjectClick={handleProjectClick}
                            onClickData={onClickData}
                            taskbarwidth={taskbarwidth}
                        />
                    )}
                    {!toggleList && (
                        <ProjectCard
                            paginatedData={paginatedData}
                            handleProjectClick={handleProjectClick}
                            onClickData={onClickData}
                            taskbarwidth={taskbarwidth}
                        />
                    )}

                    {projects?.subProjects?.length<=0 && (
                        <EmptyData
                            handleClick={handleProjectClicks}
                            message="Aucun projet trouvé"
                        />
                    )}
                    {filteredData?.length > 8 && (
                        <div className="d-flex justify-content-end">
                            <CustomPagination
                                itemsCount={filteredData.length}
                                itemsPerPage={pageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                alwaysShown={true}
                            />
                        </div>
                    )}

                    {/*<FilterCard*/}
                    {/*    handleProjectClicks={handleProjectClicks}*/}
                    {/*    handleProjectClick={handleProjectClick}*/}
                    {/*    onClickData={onClickData}*/}
                    {/*    taskbarwidth={taskbarwidth}*/}
                    {/*    data={projects?.subProjects}*/}
                    {/*    loading={isLoading}*/}
                    {/*/>*/}
                    <AddEditFormProject
                        projectItem={project}
                        modal={modal}
                        setModal={setModal}
                        isEdit={isEdit}
                        toggle={toggle}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectList;