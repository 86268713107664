//CHECK LICENCE KEY
export const CHECK_COMPANY_LICENCE = (company) => `/clientSpace/check-company-license?company=${company}`
//USERS
export const GET_USERS = "/users";
export const GET_USERS_BY_SA = (id) => `/users/bySuperAdmin/${id}`;
export const ADD_USER = `/auth/register?company=${JSON.parse(sessionStorage.getItem("authUser"))?.company}`;
export const ADD_ADMIN_SPACE = "/clientSpace/admin?companyInitial=xyz";
export const ADD_MULTI_USERS = `/users/register-all?company=${JSON.parse(sessionStorage.getItem("authUser"))?.company}`;
export const UPDATE_USER = "/users";
export const DELETE_USER = "/users";
export const UPLOAD_USER_IMG = (userId) => `/users/${userId}/update-user-profile`;
export const IMAGE_URL_PRINT= "/data-file/print"
///api/v1/data-file/support/{userId}
//ENTERPRISES
export const GET_ENTERPRISES = "/client";
export const ADD_ENTERPRISE = "/client";
export const UPDATE_ENTERPRISE = "/client";
export const DELETE_ENTERPRISE = "/client";
export const ASSIGN_DEMANDE_TO_ENTERPRISE = (data) => `/client/${data.clientId}/auditTasks/${data.auditTaskId}`;

//SPACE
export const GET_SPACES = "/clientSpace";
export const GET_SPACE_BY_COMPANY = (company) => `/clientSpace/company?company=${company}`;
export const GET_SPACES_BY_SUPER_ADMIN_ID = (id) => `/clientSpace/bySuperAdmin/${id}`;
export const ADD_SPACE = `/clientSpace`;
// export const ADD_SPACE = (cl) => `/clientSpace?companyLicense=${cl}`;
export const UPDATE_SPACE = (id) => `/clientSpace/${id}`;
export const DELETE_SPACE = (id) => `/clientSpace/${id}`;

//DEMANDES
export const GET_QCM = "/qcm";
export const GET_DEMANDES = "/audit-task";
export const GET_ALL_AUDITS = "/audit-task/get-all";
export const ADD_DEMANDE = "/audit-task";
export const ADD_MULTI_TASKS = `/audit-task/register-all?company=${JSON.parse(sessionStorage.getItem("authUser"))?.company}`;
export const UPDATE_DEMANDE = "/audit-task";
export const UPDATE_CABLE = "/cable";
export const UPDATE_ALL_BOX_IMAGES = (imageName) => `/data-file/update-image?imageName=${imageName}&company=${JSON.parse(sessionStorage.getItem("authUser"))?.company}`;
export const DELETE_DEMANDE = "/audit-task";
export const GENERATE_PDF_REPORT =  (id) =>`/audit-task/${id}/get-task-pdf-report`
export const ADD_DEMANDE_TO_PROJECT = (data) => `/project-type/${data.projectId}/move-task-to-project/${data.taskId}`;
export const ASSIGN_DEMANDE_TO_USER = (data) => `/users/${data.userId}/assign-audit-task-technician/${data.auditTaskId}`;
export const ASSIGN_DEMANDE_TO_ENGINEER = (data) => `/users/${data.userId}/assign-audit-task-engineer/${data.auditTaskId}`;
export const REMOVE_ASSIGNATING_USER_TO_DEMANDE = (data) => `/users/${data.userId}/remove-audit-task/${data.auditTaskId}`;
export const UPDATE_DEMANDE_STATUS = (id) => `/audit-task/${id}/status`;
export const ADD_COMMENT_TO_DEMANDE = (data) => `/audit-task/${data.taskId}/add-audit-task-comment?author=${data.author}&comment=${data.comment}`;
export const GET_HISTORY_AUDIT_BY_REF = (ref) => `/box/getAllAuditTaskByBoxReference?boxReference=${ref}`;
export const AUDIT_TASK_REPORT = (id) => `/audit-task/${id}/get-audit-task-report`;
export const GET_CASSETTE_BY_ID = (id) => `/cassette/${id}/getAllCassettesLine`;
export const GET_IMAGES_AFTER_AUDIT_BY_ID = (taskId) => `/data-file/user/${taskId}/get-task-after-filenames`;
export const CHANGE_TMP_FILE_FOR_BOX =(boxId) => `/box/update-newBoxTypeImage/${boxId}`; 
export const ADD_CASSETTE_LINE = (data) => `/cassette/${data.idCassette}/add-line`
export const EDIT_CASSETTE_LINE = (data) => `/cassette/${data.idCassette}/update-line/${data.idLine}`
export const DELETE_CASSETTE_LINE = (idLine) => `/cassette/delete-line/${idLine}`
export const DELETE_CASSETTE = (id) => `/cassette/${id}`
export const DELETE_BOX_BOTTOM_CASSETTE = (id) => `/box-bottom/${id}`
export const DELETE_CABLE = (id) => `/cable/${id}`
///api/v1/cassette/{idCassette}/update-line/{idLine}
// FILES ENDPOINTS
export const GET_DEMANDE_IMG = (imgName) => `/data-file/${imgName}`;
export const DOWNLOAD_FILE_BY_FILENAME = (fileName) => `/data-file/download-file/${fileName}`;
export const ADD_JOINT_FILE_TO_DEMANDE = (id) => `/data-file/task/${id}/add-joint-file`;
export const GET_JOINT_FILE_TO_DEMANDE = (id) => `/data-file/task/${id}/get-joint-filenames`;
export const ADD_BOX_TYPE_IMAGE = "/data-file/add-box-type-image";
export const GET_BOX_TYPE_IMAGES = "/box-type/get-boxType-and-imagesNames";
export const DELETE_BOX_TYPE_IMAGES = (imgName) => `/data-file/${imgName}`;


//Authenticate
export const GET_USER_LOGGED = "/auth/getUserInfoByUsernameAndToken";
export const POST_JWT_LOGIN = process.env.KEYCLOAK_SERVER;

//PROJECT
export const GET_PROJECTS ="/project-type";
export const ADD_PROJECT = "/project-type";
export const DELETE_PROJECT = (id)=> `/project-type/${id}`;
export const UPDATE_PROJECT = (id)=> `/project-type/${id}`;
export const GET_PROJECT_TASK = (id)=> `/project-type/${id}/get-audit-tasks`;
export const GET_PROJECT = (id)=> `/project-type/${id}/get-project-and-content`;
export const GET_ALL_PROJECTS = "/project-type";
export const GET_PROJECT_FILE =  `/project/1/get-joint-filenames`;
export const ADD_FILE_TO_PROJECT = (id)=> `/data-file/project/${id}/add-joint-file`;
export const ADD_TASK_TO_PROJECT = (data)=> `/project-type/${data.projectId}/move-task-to-project/${data.taskId}`;
export const DELETE_PROJECT_FILE = (name)=> `/data-file/${name}`;

































//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// Calendar
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const GET_UPCOMMINGEVENT = "/upcommingevents";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";


// Project list
export const GET_PROJECT_LIST = "/project/list";

// Task
export const GET_TASK_LIST = "/apps/task";
export const ADD_NEW_TASK = "/apps/task";
export const UPDATE_TASK = "/apps/task";
export const DELETE_TASK = "/apps/task";


// TicketsList
export const GET_TICKETS_LIST = "/apps/ticket";
export const ADD_NEW_TICKET = "/apps/ticket";
export const UPDATE_TICKET = "/apps/ticket";
export const DELETE_TICKET = "/apps/ticket";

// Dashboard Analytics

// Sessions by Countries
export const GET_ALL_DATA = "/all-data";
export const GET_HALFYEARLY_DATA = "/halfyearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

// Audiences Metrics
export const GET_ALLAUDIENCESMETRICS_DATA = "/allAudiencesMetrics-data";
export const GET_MONTHLYAUDIENCESMETRICS_DATA = "/monthlyAudiencesMetrics-data";
export const GET_HALFYEARLYAUDIENCESMETRICS_DATA = "/halfyearlyAudiencesMetrics-data";
export const GET_YEARLYAUDIENCESMETRICS_DATA = "/yearlyAudiencesMetrics-data";

// Users by Device
export const GET_TODAYDEVICE_DATA = "/todayDevice-data";
export const GET_LASTWEEKDEVICE_DATA = "/lastWeekDevice-data";
export const GET_LASTMONTHDEVICE_DATA = "/lastMonthDevice-data";
export const GET_CURRENTYEARDEVICE_DATA = "/currentYearDevice-data";

// Audiences Sessions by Country
export const GET_TODAYSESSION_DATA = "/todaySession-data";
export const GET_LASTWEEKSESSION_DATA = "/lastWeekSession-data";
export const GET_LASTMONTHSESSION_DATA = "/lastMonthSession-data";
export const GET_CURRENTYEARSESSION_DATA = "/currentYearSession-data";

// Dashboard CRM

// Balance Overview
export const GET_TODAYBALANCE_DATA = "/todayBalance-data";
export const GET_LASTWEEKBALANCE_DATA = "/lastWeekBalance-data";
export const GET_LASTMONTHBALANCE_DATA = "/lastMonthBalance-data";
export const GET_CURRENTYEARBALANCE_DATA = "/currentYearBalance-data";

// Deal type
export const GET_TODAYDEAL_DATA = "/todayDeal-data";
export const GET_WEEKLYDEAL_DATA = "/weeklyDeal-data";
export const GET_MONTHLYDEAL_DATA = "/monthlyDeal-data";
export const GET_YEARLYDEAL_DATA = "/yearlyDeal-data";

// Sales Forecast

export const GET_OCTSALES_DATA = "/octSales-data";
export const GET_NOVSALES_DATA = "/novSales-data";
export const GET_DECSALES_DATA = "/decSales-data";
export const GET_JANSALES_DATA = "/janSales-data";

// Dashboard Ecommerce
// Revenue
export const GET_ALLREVENUE_DATA = "/allRevenue-data";
export const GET_MONTHREVENUE_DATA = "/monthRevenue-data";
export const GET_HALFYEARREVENUE_DATA = "/halfYearRevenue-data";
export const GET_YEARREVENUE_DATA = "/yearRevenue-data";

// Dashboard Crypto
// Portfolio
export const GET_BTCPORTFOLIO_DATA = "/btcPortfolio-data";
export const GET_USDPORTFOLIO_DATA = "/usdPortfolio-data";
export const GET_EUROPORTFOLIO_DATA = "/euroPortfolio-data";

// Market Graph
export const GET_ALLMARKETDATA_DATA = "/allMarket-data";
export const GET_YEARMARKET_DATA = "/yearMarket-data";
export const GET_MONTHMARKET_DATA = "/monthMarket-data";
export const GET_WEEKMARKET_DATA = "/weekMarket-data";
export const GET_HOURMARKET_DATA = "/hourMarket-data";

// Dashboard Crypto
// Project Overview
export const GET_ALLPROJECT_DATA = "/allProject-data";
export const GET_MONTHPROJECT_DATA = "/monthProject-data";
export const GET_HALFYEARPROJECT_DATA = "/halfYearProject-data";
export const GET_YEARPROJECT_DATA = "/yearProject-data";

// Project Status
export const GET_ALLPROJECTSTATUS_DATA = "/allProjectStatus-data";
export const GET_WEEKPROJECTSTATUS_DATA = "/weekProjectStatus-data";
export const GET_MONTHPROJECTSTATUS_DATA = "/monthProjectStatus-data";
export const GET_QUARTERPROJECTSTATUS_DATA = "/quarterProjectStatus-data";

// Dashboard NFT
// Marketplace
export const GET_ALLMARKETPLACE_DATA = "/allMarketplace-data";
export const GET_MONTHMARKETPLACE_DATA = "/monthMarketplace-data";
export const GET_HALFYEARMARKETPLACE_DATA = "/halfYearMarketplace-data";
export const GET_YEARMARKETPLACE_DATA = "/yearMarketplace-data";

// Project
// export const ADD_NEW_PROJECT = "/add/project";
// export const UPDATE_PROJECT = "/update/project";
// export const DELETE_PROJECT = "/delete/project";

// Pages > Team
export const GET_TEAMDATA = "/teamData";
export const DELETE_TEAMDATA = "/delete/teamData";
export const ADD_NEW_TEAMDATA = "/add/teamData";
export const UPDATE_TEAMDATA = "/update/teamData";

// To do
export const GET_TODOS = "/todo";
export const DELETE_TODO = "/delete/todo";
export const ADD_NEW_TODO = "/add/todo";
export const UPDATE_TODO = "/update/todo";

// To do Project
export const ADD_NEW_TODO_PROJECT = "/add/project";

//JOB APPLICATION
export const GET_APPLICATION_LIST = "/application-list";