import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import exportRapportToCompare from "./exportCompareRapport";

const CompareCassette = () => {
  const {state} = useLocation();

  const [casette1, setcasette1] = useState(state[0])
  const [casette2, setcasette2] = useState(state[1])
  const [datas,setdatas] = useState([])

  const DIFFERENTS = [];
  const NOUVELLESLIGNES = [];
  const ANCIENNESLIGNES = [];

  var plus_grand_nbr_cassette = state[0];
  if(state[0].length<state[1].length){
    plus_grand_nbr_cassette=state[1]
  }
 
  function cassettewhoIsGreater(cas1,cas2){
    var plus_grand_nbr_ligne = cas1?.linesData

    if(cas1?.linesData.length ==null || cas1?.linesData.length < cas2?.linesData.length){
      plus_grand_nbr_ligne=cas2?.linesData
    }
     return plus_grand_nbr_ligne;
  }

  function linesToCompare(cas1type,cas2type,line1 , line2 ){
    const _ = require('lodash');
   
    if(line1==null && line2 != null){
      NOUVELLESLIGNES.push({"cassettetype":cas2type,"line":line2})
      return (
        <td colSpan={6}
        height="17"
        align="center"
        valign="middle"
        bgcolor="#000080">
           <b className="text text-white">
           NOUVELLE LIGNE 
           </b>
        </td>
      );
    }

    else if (line1!=null && line2 == null){
      ANCIENNESLIGNES.push({"cassettetype":cas1type,"line":line1})
      
      return (
        <td colSpan={6}
        height="17"
        align="center"
        valign="middle"
        bgcolor="#BA55D3">
           <b className="text text-white">
            ANCIENNE LIGNE
           </b>
        </td>
      );
    }

    else{
      if(_.isEqual(line1,line2)){
        return (
          <td colSpan={6}
          height="17"
          align="center"
          valign="middle"
          bgcolor="#228B22">
            <b className="text text-white">
            EGAUX 
            </b>
              
          </td>
        );
      }else{
        //add les 2 lignes differentes

        DIFFERENTS.push([{"cassettetype":cas1type,"line":line1},{"cassettetype":cas2type,"line":line2}])
        return (
          <td colSpan={6}
          height="17"
          align="center"
          valign="middle"
          bgcolor="#B22222">
            <b className="text text-white">
            DIFFERENTS
            </b>
              
          </td>
        );
      }
    }

  }

  function exportRapport (){
    exportRapportToCompare({
      "differents":DIFFERENTS,
      "nouvelles":NOUVELLESLIGNES,
      "ancienne":ANCIENNESLIGNES
    })
  }
  return (
    <React.Fragment>
        <div className="page-content">
      
      <button className="btn btn-primary m-2" onClick={exportRapport}> EXPORT RAPPORT D'ERREUR</button>

      <Row>
        <Col md={5}>
          <div className="content-table-cassette mb-4">
            <table cellSpacing="0" border="0">
              <colgroup span="11" width="100"></colgroup>
              <thead>
                <tr>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Capacité</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Câble</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Tube/Gaine</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Bague</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Fibre/Fil</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Statut</b>
                  </th>
                  <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Fibre/Fil</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Bague</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Tube/Gaine</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Câble</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Capacité</b>
                        </th>
                </tr>
              </thead>
              <tbody>
                {casette1?.map((cassette, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        colSpan={6}
                        height="17"
                        align="center"
                        valign="middle"
                        bgcolor="#C2BEBE"
                      >
                        <b>{cassette?.cassetteType}</b>
                      </td>
                      <td
                        colSpan={5}
                        height="17"
                        align="center"
                        valign="middle"
                        bgcolor="#C2BEBE"
                      ></td>
                    </tr>
                    {cassette?.linesData.map((line, i) => (
                      <tr key={i}>
                        <td>{line.fiberDataReportIn?.cableCapacity}</td>
                        <td align="center" valign="middle" >{line.fiberDataReportIn?.cableName}</td>
                        <td align="center" valign="middle">{line.fiberDataReportIn?.tubeNumber}</td>
                        <td>{line.fiberDataReportIn?.ringNumber}</td>
                        <td>{line.fiberDataReportIn?.fiberNumber}</td>
                        <td>{line?.status}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.fiberNumber}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.ringNumber}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.tubeNumber}</td>
                        <td>{line.fiberDataReportOut?.cableName}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.cableCapacity}</td>
                        
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Col>

        <Col md={5}>
        <div className="content-table-cassette mb-4">
            <table cellSpacing="0" border="0">
              <colgroup span="11" width="100"></colgroup>
              <thead>
                <tr>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Capacité</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Câble</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Tube/Gaine</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Bague</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Fibre/Fil</b>
                  </th>
                  <th
                    className="th-header"
                    align="center"
                    valign="middle"
                    bgcolor="#C2BEBE"
                  >
                    <b>Statut</b>
                  </th>
                  <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Fibre/Fil</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Bague</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Tube/Gaine</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Câble</b>
                        </th>
                        <th
                          className="th-header"
                          align="center"
                          valign="middle"
                          bgcolor="#C2BEBE"
                        >
                          <b>Capacité</b>
                        </th>
                </tr>
              </thead>
              <tbody>
                {casette2?.map((cassette, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        colSpan={6}
                        height="17"
                        align="center"
                        valign="middle"
                        bgcolor="#C2BEBE"
                      >
                        <b>{cassette?.cassetteType}</b>
                      </td>
                      <td
                        colSpan={5}
                        height="17"
                        align="center"
                        valign="middle"
                        bgcolor="#C2BEBE"
                      ></td>
                    </tr>
                    {cassette?.linesData.map((line, i) => (
                      <tr key={i}>
                        <td>{line.fiberDataReportIn?.cableCapacity}</td>
                        <td  align="center" valign="middle">{line.fiberDataReportIn?.cableName}</td>
                        <td align="center" valign="middle">{line.fiberDataReportIn?.tubeNumber}</td>
                        <td>{line.fiberDataReportIn?.ringNumber}</td>
                        <td>{line.fiberDataReportIn?.fiberNumber}</td>
                        <td>{line?.status}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.fiberNumber}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.ringNumber}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.tubeNumber}</td>
                        <td>{line.fiberDataReportOut?.cableName}</td>
                        <td align="center" valign="middle">{line.fiberDataReportOut?.cableCapacity}</td>
                        
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Col>

        <Col>
        <div className="content-table-cassette mb-4">
  <table cellSpacing="0" border="0">
    <thead>
      <tr>
        <th
          className="th-header"
          align="center"
          valign="middle"
          bgcolor="#C2BEBE"
        >
          <b>Compare</b>
        </th>
      </tr>
    </thead>

    <tbody>
      {plus_grand_nbr_cassette.map((_, index) => 
      
      (
        <React.Fragment key={index}>
          <tr>
            <td
              colSpan={6}
              height="17"
              align="center"
              valign="middle"
              bgcolor="#C2BEBE"
              
            >
              
              <b>{casette1[index]?.cassetteType} --- </b>
              <b>{casette2[index]?.cassetteType}</b>
            </td>
            <td
              colSpan={5}
              height="17"
              align="center"
              valign="middle"
              bgcolor="#C2BEBE"
            ></td>
          </tr>
          {cassettewhoIsGreater(casette1[index],casette2[index])?.map((_,i) => (
            <tr key={i}>
                {linesToCompare(
                  casette1[index]?.cassetteType,
                  casette2[index]?.cassetteType,
                  casette1[index]?.linesData[i],
                  casette2[index]?.linesData[i]
                )}
              
            </tr>
          ))}
        </React.Fragment>
      )
      )}
    </tbody>
  </table>
</div>

        </Col>
      </Row>
      </div>
    </React.Fragment>
  );
};

export default CompareCassette;
