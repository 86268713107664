import React, { useState } from 'react';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, Upload} from 'antd';
const { Option } = Select;
const AddEditDrawer = ({open, setOpen}) => {
    //const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Drawer
                zIndex={9999}
                title="Creation d'un nouveau compte utilisateur"
                width={520}
                onClose={onClose}
                open={open}
                maskClosable={false}
                styles={{
                    // body: {
                    //     paddingBottom: 80,
                    // },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Annuler</Button>
                        <Button onClick={onClose} type="primary">
                            Enregistrer
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            {/*<Form.Item*/}
                            {/*    name="upload"*/}
                            {/*    label="Upload"*/}
                            {/*    valuePropName="fileList"*/}
                            {/*    getValueFromEvent={normFile}*/}
                            {/*    extra="longgggggggggggggggggggggggggggggggggg"*/}
                            {/*>*/}
                            {/*    <Upload name="logo" action="/upload.do" listType="picture">*/}
                            {/*        <Button icon={<UploadOutlined />}>Click to upload</Button>*/}
                            {/*    </Upload>*/}
                            {/*</Form.Item>*/}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="firstname"
                                label="Prénom"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter user name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastname"
                                label="Nom"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter user name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="username"
                                label="Nom utilisateur"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter user name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter user name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="mobileNumber"
                                label="Téléphone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter user name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="userRole"
                                label="Ajouter un Role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please choose the role',
                                    },
                                ]}
                            >
                                <Select placeholder="Please choose the role">
                                    <Option value="jack">ADMIN</Option>
                                    <Option value="tom">ENGINEER</Option>
                                    <Option value="tom">TECHNICIAN</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default AddEditDrawer;