import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const BreadCrumb = ({ title, subTitle, pageTitle, route0, route1 }) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-start">
                        {/* <h4 className="mb-sm-0">{title}</h4> */}

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to={route0 ? route0 : '/Dashboard'}>{pageTitle}</Link></li>
                                {!subTitle ? <li className="breadcrumb-item active">{title}</li> : <li className="breadcrumb-item"><Link to={route1}>{title}</Link></li> }
                                {subTitle ? <li className="breadcrumb-item active">{subTitle}</li> : null}
                            </ol>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;