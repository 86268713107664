import {APIClient} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";

const api = new APIClient();

export const createAudit = async (data) => {
    const response =  await api.create(url.ADD_DEMANDE, data.formData);
    if (response && data.userId) {
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:response.id}))
    }
    
    if (response && data.attachedFiles) {
        for (const file of data.attachedFiles) {
            await axios.post(url.ADD_JOINT_FILE_TO_DEMANDE(response.id), {'file': file}, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        }
    }

    return {
        "id": response.id,
        "name": response.name,
        "address": response.address,
        "status": response.status,
        "priority": response.priority,
        "createdDate": response.createdDate,
        "updatedDate": response.updatedOn,
        "dueDate": response.dueDate,
        "endDate": response.endDate,
        "assignedTo": [],
        "project": {
            "projectName": "root",
            "projectId": response.projectId
        }
      }
}

export const createMultiTasks = async (data) => {
    const response =  await api.create(url.ADD_MULTI_TASKS, data);
    return response;
}

export const getAudits = async () => {
    const response = await api.get(url.GET_ALL_AUDITS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    return response;
}

export const updateAudit = async (data) => {
    const response = await api.put(`${url.UPDATE_DEMANDE}/${data.id}`, data.formData);
    if (data.userId && response.assignedToTechnicianId) {
        await api.delete(url.REMOVE_ASSIGNATING_USER_TO_DEMANDE({userId:response.assignedToTechnicianId, auditTaskId:response.id}));
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:response.id}))
    } else {
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:response.id}))
    }
    
    if (response && data.attachedFiles) {
        data.attachedFiles.forEach(async (file) => {
            await axios.post(url.ADD_JOINT_FILE_TO_DEMANDE(response.id), {'file': file}, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        })
    }

    return {...response, userId: response.id, phone: response.mobileNumber};
}

export const updateAuditStatus = async ({id, status}) => {
    const response = await api.put(url.UPDATE_DEMANDE_STATUS(id), JSON.stringify(status));
    return response;
}

export const deleteAudit = async (id) => {
    const response = await api.delete(url.DELETE_DEMANDE+'/'+id);
    return {id, ...response.data};
}

export const getAuditById = async (id) => {

    return await api.get(`${url.GET_DEMANDES}/${id}`)
            .then(async (data) => {
                if(data?.box) {
                    let technician, project;
                    if (data?.assignedToTechnicianId) {
                        technician = await api.get(`${url.GET_USERS}/${data?.assignedToTechnicianId}`)
                            .then(tech => tech)
                            .catch(e => null);
                    }

                    if (data?.projectId) {
                        project = await api.get(`${url.GET_PROJECT(data?.projectId)}`)
                            .then(pro => pro)
                            .catch(e => null);
                    }

                    let cassettesDataForReport = await api.get(url.AUDIT_TASK_REPORT(data.id))

                    return {
                        id: data?.id,
                        name: data?.name,
                        status: data?.status,
                        address: data?.address,
                        place: data?.place,
                        description: data?.description,
                        created: data?.createdDate,
                        updated: data?.updatedDate,
                        endDate: data?.endDate,
                        priority: data?.priority,
                        qcmResponses: data?.taskQsResponses,
                        remarque: data?.comments,
                        box: data?.box || null,
                        boxImages: Array.of(
                            {img: data?.box?.boxImageUrl, name: "Boîte"},
                            {img: data?.box?.boxReferenceImageUrl, name: "Référence Boîte"},
                            {img: data?.box?.cablesNumberImageUrl, name: "Nombre de Câbles"},
                            {img: data?.box?.cassetteNumberImageUrl, name: "Nombre de Cassettes"}
                        ) || [],
                        imagesBeforeAudit: Array.of(
                            {img: data?.box?.boxImageUrl, name: "Boîte"},
                            {img: data?.box?.boxReferenceImageUrl, name: "Référence Boîte"},
                            {img: data?.box?.cablesNumberImageUrl, name: "Nombre de Câbles"},
                            {img: data?.box?.cassetteNumberImageUrl, name: "Nombre de Cassettes"},
                            data?.box?.cables.map((item) => ({img: item.cableImageUrl, name: item?.cableName})),
                            data?.box?.cassettes.map((item) => ({
                                img: item.cassetteImageUrl,
                                name: `Image Cassette ${item?.cassetteType}`
                            }))
                        ).flat().filter((i) => i?.img != null) || [],
                        technician,
                        project,
                        contact: {
                            firstname: data?.interlocFirstname,
                            lastname: data?.interlocLastname,
                            email: data?.interlocEmail,
                            phone: data?.interlocPhone,
                        },
                        company: data?.company,
                        cables: data?.box?.cables,
                        cassettesLines: [
                            ...data?.box?.cassettes,
                            data?.box?.boxBottom
                        ],
                        cassettesData: cassettesDataForReport?.cassettesData
                    }
                }
                else {
                    return Promise.reject('Cette boîte ne contient pas de données!')
                }
            })//.catch((e) => Promise.reject('Cette boîte ne contient pas de données!'));
}

export const getImgAfterAuditById = async (id) => {
    const response = await api.get(url.GET_IMAGES_AFTER_AUDIT_BY_ID(id));
    return response.filter(el => el.url !== null).map((item) => ({img: item?.url, mane: item?.name, comment: item?.comment}))
    // return response.map((item) => ({img: item.filename, name: item?.name, comment: item?.comment})).filter((i) => i.img != null);
}

export const getAuditHistoryByRef = async () => {}

export const getAuditCassetteById = async () => {}

export const assignAuditToTechnician = async (data) => {
    if (data.assignedUser) {
        await api.delete(url.REMOVE_ASSIGNATING_USER_TO_DEMANDE({userId:data.assignedUser, auditTaskId:data.auditId}));
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:data.auditId}))
    } else {
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:data.auditId}))
    }
}

export const assignAuditToEngineer = async () => {}

export const assignAuditToEnterprise = async (data) => {
    await api.create(url.ASSIGN_DEMANDE_TO_ENTERPRISE(data))
}

export const removeAssignation = async (data) => {
    const response = await api.delete(url.REMOVE_ASSIGNATING_USER_TO_DEMANDE(data));
    return response;
}

export const getImagesAfterAuditById = async (id) => {
    const response = await api.get(url.GET_IMAGES_AFTER_AUDIT_BY_ID(id))
    return response;

}

export const getAuditJointFiles = async () => {}

export const downloadAuditJointFiles = async () => {}

export const joinFiletoDemande = async ({id,data})=>{
    //console.log("----api call --id  "+id+"-- data  "+data);
    const response = await api.sendFormData(url.ADD_JOINT_FILE_TO_DEMANDE(id),data);
    return response;
}

export const addAuditComment = async (data) => {
    const response = await axios.post(url.ADD_COMMENT_TO_DEMANDE(data));
    return response;
}

export const generateAuditTask = async (id) => {
    const response = await api.get(url.AUDIT_TASK_REPORT(id));

    return response;
}

export const changetmpImage = async ({boxId,data}) =>{

    const response =  await api.putFormData(url.CHANGE_TMP_FILE_FOR_BOX(boxId),data);
    return response ;
}

export const updateCable = async (data) => {
    const response = await api.update(`${url.UPDATE_CABLE}/${data.cableId}`, data.formData);
    return response;
}

export const addCassetteLine = async (data) => {
    const response = await api.create(url.ADD_CASSETTE_LINE(data), data.formData);
    return response;
}

export const updateCassetteLine = async (data) => {
    const response = await api.put(url.EDIT_CASSETTE_LINE(data), data.formData);
    return response;
}

export const deleteCassetteLine = async (idLine) => {
    const response = await api.delete(url.DELETE_CASSETTE_LINE(idLine));
    return response;
}

export const deleteCassette = async (id) => {
    const response = await api.delete(url.DELETE_CASSETTE(id));
    return response;
}

export const deleteBoxBottomCassette = async (id) => {
    const response = await api.delete(url.DELETE_BOX_BOTTOM_CASSETTE(id));
    return response;
}

export const deleteCable = async (id) => {
    const response = await api.delete(url.DELETE_CABLE(id));
    return response;
}

export const getJointFileToDemande = async (id) => {
    const response = await api.get(url.GET_JOINT_FILE_TO_DEMANDE(id));
    return response ;
}
export const getExportPdfReport = async (id)=>{
    const response =  await api.get(url.GENERATE_PDF_REPORT(id));
    return response;
}

export const getQCM = async () => {
    const response =  await api.get(url.GET_QCM, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    return response;
}