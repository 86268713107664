import flagus from "../assets/images/flags/us.svg";
import flagspain from "../assets/images/flags/spain.svg";
import flaggermany from "../assets/images/flags/germany.svg";
import flagitaly from "../assets/images/flags/italy.svg";
import flagrussia from "../assets/images/flags/russia.svg";
import flagchina from "../assets/images/flags/china.svg";
import flagfrench from "../assets/images/flags/french.svg";
import flagarabic from "../assets/images/flags/ar.svg";

const languages = {
  // sp: {
  //   label: "Española",
  //   flag: flagspain,
  //   initial:"ES"
  // },
  // gr: {
  //   label: "Deutsche",
  //   flag: flaggermany,
  //   initial:"GR"
  // },
  // it: {
  //   label: "Italiana",
  //   flag: flagitaly,
  //   initial:"IT"
  // },
  // rs: {
  //   label: "русский",
  //   flag: flagrussia,
  //   initial:"RU"
  // },
  // en: {
  //   label: "English",
  //   flag: flagus,
  //   initial:"EN"
  // },
  // cn: {
  //   label: "中国人",
  //   flag: flagchina,
  //   initial:"CH"
  // },
  fr: {
    label: "français",
    flag: flagfrench,
    initial:"FR"
  },
  // ar: {
  //   label: "Arabic",
  //   flag: flagarabic,
  //   initial:"AR"
  // },
}

export default languages
