import {useMutation, useQueryClient} from "react-query";
import React, {useContext, useState} from "react";
import {size} from "lodash";
import axios from "axios";
import {changetmpImage} from "../../../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import SimpleBar from "simplebar-react";
import {Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Link} from "react-router-dom";
import audexaLogo from "../../../../../assets/login_page_assets/images/xnoir.png";
import TaskContext from "../../../hook/TaskContext";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";

const BoxTypes = () => {
    const {box, boxImages} = useContext(TaskContext)
    const queryClient = useQueryClient();

    const [btn_visible, setbtn_visible] = useState(false)
    const [modal_visible, set_modal_visible] = useState(false)
    const [result_image_reverse_visible, setresult_image_reverse_visible] = useState(false)
    const [image_choice, set_image_choice] = useState([])
    const [images_find, set_images_find] = useState([])
    const [image_link, set_image_link] = useState("")
    const [image_rename, set_image_rename] = useState("")
    const [isLoading,setisLoading] = useState(false)

    function image_selected(image_data) {
        size = image_choice.length;

        if (image_choice.find((item) => item == image_data.img) == null) {
            set_image_choice([...image_choice, image_data.img])
            size = size + 1;
        }
        else {
            const tab = image_choice.filter(item => item != image_data.img)
            set_image_choice(tab)
            size = size - 1;
        }

        if (size == 1) {

            setbtn_visible(true)
        }
        else {
            setbtn_visible(false)
            setresult_image_reverse_visible(false)
        }

    }

    async function submit_image_for_search() {
        setisLoading(true)
        const api_key = "7d4dc3ffad7a0ba7b8804833c141143808ed46fd2c4b156433d7b92590a59364"
        const url_builder = `${process.env.REACT_APP_API_IMG_URL}/${image_choice[0]}`
        const api_url_builder = "https://serpapi.com/search.json?url=" + url_builder + "&api_key=" + api_key + "&engine=google_lens"
        //const proxy_url = "https://cors-anywhere.herokuapp.com/"
        const proxy_url = "https://corsproxy.io/?"
        const res = await axios.get(proxy_url + api_url_builder, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        })

        let res_tab = res?.visual_matches.length
        if (res?.visual_matches.length > 30) {
            res_tab = res.visual_matches.slice(0, 30)
        }

        set_images_find(res_tab)
        setresult_image_reverse_visible(true)
        setisLoading(false)
    }

    const toggle = (image_submit=null) =>{
        if(modal_visible){
            set_modal_visible(false)
            set_image_link("")
        }
        else{
            set_modal_visible(true)
            set_image_link(image_submit)
        }
    }

    const changetmpImageMutation = useMutation(changetmpImage,{
        onSuccess : () => {
            queryClient.invalidateQueries("audit");
            toast.success("Image changée avec succées");
        },
        onError : (error) =>{
            toast.error(error)
        }
    });

    async  function Submit_Image_Change( event ) {
        event.preventDefault()

        const current_user = JSON.parse(sessionStorage.getItem('authUser'))

        const img_file = await fetch(image_link);
        const blob_file = await img_file.blob();
        const file = new File([blob_file],image_rename+"."+blob_file.type.replace('/','.'),{type:blob_file.type});
        const data = new FormData()
        data.append("imageName",image_rename)
        data.append("company",current_user?.company)
        data.append("image",file)
        changetmpImageMutation.mutate({boxId:box.boxId,data: data});
    }

    console.log(boxImages)

    return (
        <>
            {boxImages.length>0 && (
                <SimpleBar style={{maxHeight: "440px"}} className="px-3">
                    <Row>
                        {boxImages
                            .filter((i) => i.img != null && i.img.split("_").at(0) === "tmp")
                            .map((item, index) => {
                                return [
                                    <Col lg={3} md={4} key={index}>
                                        <div className="swiper-wrapper">
                                            <div
                                                className="gallery-box card"
                                                style={{ height: "160px" }}
                                            >
                                                <div className="gallery-container">
                                                    <div
                                                        className="form-check form-check-danger fs-15 d-flex justify-content-end"
                                                        style={{
                                                            position: "absolute",
                                                            zIndex: "1000",
                                                            marginTop: "2px",
                                                        }}
                                                    >
                                                        <input
                                                            onChange={() => image_selected(item)}
                                                            className="imgCheckBoxTb form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="folderlistCheckbox_tb"
                                                        />
                                                        {/* <input onChange={() => selectCheckbox()} className="imgCheckBox form-check-input" type="checkbox" value={item.img} id={"folderlistCheckbox_" + item.img} /> */}
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="folderlistCheckbox_tb"
                                                        ></label>
                                                    </div>
                                                    <Link
                                                        className="image-popup"
                                                        to={
                                                            item.img
                                                                ? `${process.env.REACT_APP_API_IMG_URL}/${item.img}`
                                                                : null
                                                        }
                                                        target="_blanc"
                                                    >
                                                        {/*<LazyLoadImage*/}
                                                        {/*    className="gallery-img img-fluid mx-auto"*/}
                                                        {/*    effect="blur"*/}
                                                        {/*    src={*/}
                                                        {/*        item.img*/}
                                                        {/*            ? `${process.env.REACT_APP_API_IMG_URL}/${item.img}`*/}
                                                        {/*            : null*/}
                                                        {/*    }*/}
                                                        {/*    alt="img_name"*/}
                                                        {/*    placeholderSrc={audexaLogo}*/}
                                                        {/*    height={100}*/}
                                                        {/*/>*/}
                                                        <AsyncImage
                                                            key={index}
                                                            src={`${process.env.REACT_APP_API_IMG_URL}/${item.img}`}
                                                            style={{ width: 250, height: 100, borderRadius: 3 }}
                                                            loader={<div style={{ background: '#888' }} />}
                                                            Transition={Grow}
                                                        />
                                                        <div className="gallery-overlay">
                                                            <h5 className="overlay-caption">Image After</h5>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="box-content">
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="flex-shrink-0">
                                                            <div className="d-flex gap-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
                                                                    // onClick={() => toggleModal(item)}
                                                                >
                                                                    <i className="ri-crop-line text-muted align-bottom me-1"></i>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
                                                                    // onClick={() => downloadImage(item)}
                                                                >
                                                                    <i className="ri-download-fill text-muted align-bottom me-1"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>,
                                ];
                            })}

                        <div className="box-content">
                            {btn_visible ? (
                                <button
                                    className="btn btn-xl btn-primary"
                                    onClick={submit_image_for_search}
                                >
                                    <i className="ri-search-line  align-bottom me-1 mx-1"></i>
                                    {isLoading ? (
                                        <lord-icon
                                            src="https://cdn.lordicon.com/gkryirhd.json"
                                            trigger="loop"
                                            colors="primary:#405189,secondary:#0ab39c"
                                            style={{ width: "20px", height: "20px" }}
                                        ></lord-icon>
                                    ) : (
                                        "Chercher"
                                    )}
                                </button>
                            ) : (
                                <div></div>
                            )}
                        </div>

                        <div className="box-content">
                            {result_image_reverse_visible ? (
                                <Row>
                                    {images_find.length > 0 ? (
                                        images_find.map((item, index) => (
                                            <Col lg={4} md={3} key={index}>
                                                <div className="swiper-wrapper">
                                                    <div
                                                        className="gallery-box card"
                                                        style={{ height: "160px" }}
                                                    >
                                                        <div className="gallery-container">
                                                            <div
                                                                className="form-check form-check-danger fs-15 d-flex justify-content-end"
                                                                style={{
                                                                    position: "absolute",
                                                                    zIndex: "1000",
                                                                    marginTop: "2px",
                                                                }}
                                                            ></div>
                                                            <Link
                                                                className="image-popup"
                                                                to={item ? item.thumbnail : null}
                                                                target="_blanc"
                                                            >
                                                                {/*<LazyLoadImage*/}
                                                                {/*    className="gallery-img img-fluid mx-auto"*/}
                                                                {/*    effect="blur"*/}
                                                                {/*    src={item ? item.thumbnail : null}*/}
                                                                {/*    alt="img_name"*/}
                                                                {/*    placeholderSrc={audexaLogo}*/}
                                                                {/*    height={100}*/}
                                                                {/*/>*/}
                                                                <AsyncImage
                                                                    key={index}
                                                                    src={`${item.thumbnail}`}
                                                                    style={{ width: 250, height: 100, borderRadius: 3 }}
                                                                    loader={<div style={{ background: '#888' }} />}
                                                                    Transition={Grow}
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="box-content">
                                                            <div className="d-flex align-items-center mt-1">
                                                                <div className="flex-shrink-0">
                                                                    <div className="d-flex gap-3">
                                                                        <button
                                                                            type="button"
                                                                            className="btn  btn-sm fs-15 btn-link text-body text-decoration-none px-0"
                                                                            onClick={() => toggle(item?.thumbnail)}
                                                                        >
                                                                            <i className="ri-checkbox-line text-muted align-bottom me-1"></i>
                                                                            Appliquer
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))
                                    ) : (
                                        <div>
                                            <h3>Aucune Image similaire trouvée</h3>
                                        </div>
                                    )}
                                </Row>
                            ) : (
                                <div></div>
                            )}
                        </div>

                        <Modal
                            id="showModalChangeBoxImage"
                            isOpen={modal_visible}
                            centered
                            size="md"
                            toggle={toggle}
                        >
                            <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
                                Changer Image
                            </ModalHeader>
                            <Form onSubmit={Submit_Image_Change}>
                                <ModalBody>
                                    <Col>
                                        <div>
                                            <img src={image_link} className="my-2 mx-2" />
                                        </div>
                                        <Label htmlFor="owner-field" className="form-label">
                                            Image Nom
                                            <span className="text-danger mx-1">:</span>
                                        </Label>

                                        <Input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                set_image_rename(e.target.value);
                                            }}
                                        />

                                        <Input
                                            className="my-2 btn btn-primary"
                                            value={"Changer Image"}
                                            type="submit"
                                        />
                                    </Col>
                                </ModalBody>
                            </Form>
                        </Modal>
                    </Row>
                </SimpleBar>
            )}
            {!boxImages.length>0 && (
                <div className="py-4 text-center">
                    <div>
                        <lord-icon
                            src="https://cdn.lordicon.com/ftbzrilf.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{width: "72px", height: "72px"}}
                        ></lord-icon>
                    </div>

                    <div className="mt-4">
                        <h5>Aucune image type de boîte temporaire trouvée!</h5>
                    </div>
                </div>
            )}
        </>
    );
}

export default BoxTypes