import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";

//import images
import dummyImg from "../../../../assets/images/users/user-dummy-img.jpg";
import { UserRoleFormat } from '../../../../helpers/role_format';
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import { getMemberById, updateMember } from '../../../../api/users/usersApi';

const Profile = () => {
    document.title = `Profil | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
    const params = useParams();
    const queryClient = useQueryClient();
    const {
        isLoading,
        isError,
        error,
        data: user,
    } = useQuery(["user", params.id], () => getMemberById(params.id));

    const [activeTab, setActiveTab] = useState("1");
    const [fileUpload, setFileUpload] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState(null);

    const updateMemberMutation = useMutation(updateMember, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("user");
            toast.success("L'utilisateur a été modifié avec succès");
        },
        onError: (error) => {
            // An error happened!
            toast.error(error);
        },
    });

    // Update user
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstname: (user && user.firstname) || "",
            lastname: (user && user.lastname) || "",
            email: (user && user.email) || "",
            mobileNumber: (user && user.mobileNumber) || "",
            username: (user && user.username) || "",
            userRole: (user && user.userRole) || "",
            userImageUrl: (user && user.userImageUrl) || "",
            password: (user && user.password) || null,
            new_password: "",
            confirm_password: "",
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Le Prénom est obligatoire"),
            lastname: Yup.string().required("Le Nom est obligatoire"),
            email: Yup.string().required("L'adresse email est obligatoire"),
            mobileNumber: Yup.string().required("Le Numéro Téléphone est obligatoire"),
            username: Yup.string().required("Le Nom d'utilisateur est obligatoire"),
            userRole: Yup.string().required("Le Rôle est obligatoire"),
            userImageUrl: Yup.string(),
        }),

        onSubmit: (values) => {
            const {
                firstname,
                lastname,
                email,
                mobileNumber,
                username,
                userRole,
                userImageUrl,
                password,
            } = validation.values;
            const formData = new FormData();
            formData.append("firstname", firstname);
            formData.append("lastname", lastname);
            formData.append("email", email);
            formData.append("mobileNumber", mobileNumber);
            formData.append("username", username);
            formData.append("userImageUrl", userImageUrl);
            formData.append("userRole", userRole);
            formData.append("password", password);
            formData.append("company", sessionStorage.getItem("company"));

            const data = {
                formData,
                userId: user && user.id,
                profile: fileUpload,
            };

            updateMemberMutation.mutate(data);
        },
    });

    // Update Password
    const validation2 = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: null,
            new_password: "",
            confirm_password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Veuillez saisir l\'ancien mot de passe'),
            new_password: Yup.string().required('Veuillez saisir le nouveau mot de passe').min(8, 'Minimum 8 caracteres'),
            confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Les mots de Passe ne correspondent pas!'),
        }),

        onSubmit: (values) => {
            const updatedPassword = {
                password: values.password,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            };
        },
    });

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const roles = [
        { label: "Technicien", value: "ROLE_MOBILE_USER" },
        { label: "Ingénieur", value: "ROLE_BACK_OFFICE_MANAGER" },
        { label: "Admin", value: "ROLE_API_MANAGER" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Profil" pageTitle="Staff" route0="/teams" />
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n6">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <Formik
                                            htmlFor="profileImage"
                                            initialValues={validation.initialValues}
                                            validationSchema={validation.validationSchema}
                                        >
                                            {({ setFieldValue }) => (
                                                <Form
                                                    className="tablelist-form"
                                                    id="profileImage"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                        {avatarPreview ? (
                                                            <img
                                                                src={avatarPreview}
                                                                alt="dummyImg"
                                                                id="customer-img"
                                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    user?.userImageUrl
                                                                        ? `${
                                                                            process.env.REACT_APP_API_IMG_URL +
                                                                            "/" +
                                                                            user?.userImageUrl
                                                                        }`
                                                                        : dummyImg
                                                                }
                                                                alt="dummyImg"
                                                                id="customer-img"
                                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                            />
                                                        )}
                                                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                            <Input
                                                                name="userImageUrl"
                                                                className="profile-img-file-input"
                                                                id="customer-image-input"
                                                                type="file"
                                                                accept="image/png, image/jpg, image/jpeg"
                                                                onChange={(e) => {
                                                                    setFileUpload(e.target.files[0]);
                                                                    const fileReader = new FileReader();
                                                                    fileReader.onload = () => {
                                                                        if (fileReader.readyState === 2) {
                                                                            setFieldValue(
                                                                                "userImageUrl",
                                                                                fileReader.result
                                                                            );
                                                                            setAvatarPreview(fileReader.result);
                                                                        }
                                                                    };
                                                                    fileReader.readAsDataURL(e.target.files[0]);
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                // value={validation.values.userImageUrl || ""}
                                                            />
                                                            <Label htmlFor="customer-image-input"
                                                                   className="profile-photo-edit avatar-xs">
                                                            <span className="avatar-title rounded-circle bg-light text-body">
                                                                <i className="ri-camera-fill"></i>
                                                            </span>
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        <h5 className="fs-17 mb-1">{user?.firstname} {user?.lastname}</h5>
                                        <p className="text-muted mb-0">{UserRoleFormat(user?.userRole)}</p>
                                    </div>
                                </CardBody>
                            </Card>

                            {/* <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Tâches en cours</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-primary fs-12"><i
                                                className="ri-add-fill align-bottom me-1"></i> Ajouter</Link>
                                        </div>
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-body text-body">
                                                <i className="ri-github-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="email" className="form-control" id="gitUsername" placeholder="Username"
                                            defaultValue="@daveadame" />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-primary">
                                                <i className="ri-global-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="websiteInput"
                                            placeholder="www.example.com" defaultValue="www.velzon.com" />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-success">
                                                <i className="ri-dribbble-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="dribbleName" placeholder="Username"
                                            defaultValue="@dave_adame" />
                                    </div>
                                    <div className="d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-danger">
                                                <i className="ri-pinterest-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="pinterestName"
                                            placeholder="Username" defaultValue="Advance Dave" />
                                    </div>
                                </CardBody>
                            </Card> */}
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n6">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                         role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }} type="button">
                                                <i className="fas fa-home"></i>
                                                Informations utilisateur
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                     className={classnames({ active: activeTab === "2" })}
                                                     onClick={() => {
                                                         tabChange("2");
                                                     }}
                                                     type="button">
                                                <i className="far fa-user"></i>
                                                Changer Mot de Passe
                                            </NavLink>
                                        </NavItem>
                                        <NavItem >
                                            <NavLink to="#"
                                                     className={classnames({ active: activeTab === "3" })}
                                                     onClick={() => {
                                                         tabChange("3");
                                                     }}
                                                     type="button">
                                                <i className="far fa-envelope"></i>
                                                Experience
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                     className={classnames({ active: activeTab === "4" })}
                                                     onClick={() => {
                                                         tabChange("4");
                                                     }}
                                                     type="button">
                                                <i className="far fa-envelope"></i>
                                                Privacy Policy
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Form
                                                className="tablelist-form"
                                                id="amazing"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                {user &&
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstnameInput" className="form-label">Prénom</Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="firstnameInput"
                                                                    name='firstname'
                                                                    placeholder="Entrez le prenom"
                                                                    // value={`${user?.firstname}`}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.firstname || ""}
                                                                    invalid={
                                                                        validation.touched.firstname &&
                                                                        validation.errors.firstname
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.firstname &&
                                                                validation.errors.firstname ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.firstname}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="lastnameInput" className="form-label">Nom</Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="lastnameInput"
                                                                    name='lastname'
                                                                    placeholder="Entrez le nom"
                                                                    // value={`${user?.lastname}`}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.lastname || ""}
                                                                    invalid={
                                                                        validation.touched.lastname &&
                                                                        validation.errors.lastname
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.lastname &&
                                                                validation.errors.lastname ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.lastname}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="phonenumberInput" className="form-label">Téléphone</Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="phonenumberInput"
                                                                    name='mobileNumber'
                                                                    placeholder="Entrez le Téléphone"
                                                                    // value={`${user.mobileNumber}`}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.mobileNumber || ""}
                                                                    invalid={
                                                                        validation.touched.mobileNumber &&
                                                                        validation.errors.mobileNumber
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.mobileNumber &&
                                                                validation.errors.mobileNumber ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.mobileNumber}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="emailInput" className="form-label">Email</Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="emailInput"
                                                                    name='email'
                                                                    placeholder="Entrez l'adresse email"
                                                                    // value={`${user.email}`}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.email || ""}
                                                                    invalid={
                                                                        validation.touched.email &&
                                                                        validation.errors.email
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.email && validation.errors.email ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.email}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="choices-categories-input" className="form-label">Rôle</Label>
                                                                <select
                                                                    name="userRole"
                                                                    className="form-select"
                                                                    data-choices
                                                                    data-choices-search-false
                                                                    id="choices-categories-input"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.userRole || ""}
                                                                >
                                                                    <option defaultValue="undefined"></option>
                                                                    {roles.map(({ value, label }, index) => (
                                                                        <option key={index} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {validation.touched.userRole &&
                                                                validation.errors.userRole ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.userRole}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3 pb-2">
                                                                <Label htmlFor="designationInput"
                                                                       className="form-label">Nom d'utilisateur</Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="designationInput"
                                                                    name='username'
                                                                    placeholder="Entrez le nom d'utilisateur"
                                                                    // value={`${user.username}`}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.username || ""}
                                                                    invalid={
                                                                        validation.touched.username &&
                                                                        validation.errors.username
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.username &&
                                                                validation.errors.username ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.username}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button type="submit"
                                                                        className="btn btn-primary">Mise à jour</button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Form>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <Form
                                                className="tablelist-form"
                                                id="pwd-update"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation2.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <Row className="g-2">
                                                    <Col lg={4}>
                                                        <div>
                                                            <Label
                                                                htmlFor="oldpasswordInput"
                                                                className="form-label">Ancien
                                                                Mot de Passe*</Label>
                                                            <Input
                                                                type="password"
                                                                className="form-control"
                                                                id="oldpasswordInput"
                                                                name='password'
                                                                placeholder="Entrez l'ancien mot de passe"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation2.handleChange}
                                                                onBlur={validation2.handleBlur}
                                                                value={validation2.values.password || ""}
                                                                invalid={
                                                                    validation2.touched.password &&
                                                                    validation2.errors.password
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation2.touched.password &&
                                                            validation2.errors.password ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation2.errors.password}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label
                                                                htmlFor="newpasswordInput"
                                                                className="form-label">Nouveau
                                                                Mot de Passe*</Label>
                                                            <Input
                                                                type="password"
                                                                className="form-control"
                                                                id="newpasswordInput"
                                                                name='new_password'
                                                                placeholder="Entrez le nouveau mot de passe"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation2.handleChange}
                                                                onBlur={validation2.handleBlur}
                                                                value={validation2.values.new_password || ""}
                                                                invalid={
                                                                    validation2.touched.new_password &&
                                                                    validation2.errors.new_password
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation2.touched.new_password &&
                                                            validation2.errors.new_password ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation2.errors.new_password}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label
                                                                htmlFor="confirmpasswordInput"
                                                                className="form-label">Confirmez
                                                                Mot de Passe*</Label>
                                                            <Input
                                                                type="password"
                                                                className="form-control"
                                                                id="confirmpasswordInput"
                                                                name='confirm_password'
                                                                placeholder="Confirmez le mot de passe"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                onChange={validation2.handleChange}
                                                                onBlur={validation2.handleBlur}
                                                                value={validation2.values.confirm_password || ""}
                                                                invalid={
                                                                    validation2.touched.confirm_password &&
                                                                    validation2.errors.confirm_password
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation2.touched.confirm_password &&
                                                            validation2.errors.confirm_password ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation2.errors.confirm_password}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Link to="#"
                                                                  className="link-primary text-decoration-underline">Mot de Passe
                                                                Oublié ?</Link>
                                                        </div>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <div className="text-end">
                                                            <button type="submit" className="btn btn-success">Changez
                                                                Mot de Passe</button>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </Form>
                                            <div className="mt-4 mb-3 border-bottom pb-2">
                                                <div className="float-end">
                                                    <Link to="#" className="link-primary">Tous les Logout</Link>
                                                </div>
                                                <h5 className="card-title">Historiques Login</h5>
                                            </div>
                                            {/* <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-smartphone-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>iPhone 12 Pro</h6>
                                                    <p className="text-muted mb-0">Los Angeles, United States - March 16 at
                                                        2:47PM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-tablet-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Apple iPad Pro</h6>
                                                    <p className="text-muted mb-0">Washington, United States - November 06
                                                        at 10:43AM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-smartphone-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Galaxy S21 Ultra 5G</h6>
                                                    <p className="text-muted mb-0">Conneticut, United States - June 12 at
                                                        3:24PM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-macbook-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Dell Inspiron 14</h6>
                                                    <p className="text-muted mb-0">Phoenix, United States - July 26 at
                                                        8:10AM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div> */}
                                        </TabPane>

                                        <TabPane tabId="3">
                                            {/* <form>
                                                <div id="newlink">
                                                    <div id="1">
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="jobTitle" className="form-label">Job
                                                                        Title</Label>
                                                                    <Input type="text" className="form-control"
                                                                        id="jobTitle" placeholder="Job title"
                                                                        defaultValue="Lead Designer / Developer" />
                                                                </div>
                                                            </Col>

                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="companyName" className="form-label">Company
                                                                        Name</Label>
                                                                    <Input type="text" className="form-control"
                                                                        id="companyName" placeholder="Company name"
                                                                        defaultValue="Themesbrand" />
                                                                </div>
                                                            </Col>

                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <label htmlFor="experienceYear"
                                                                        className="form-label">Experience Years</label>
                                                                    <Row>
                                                                        <Col lg={5}>
                                                                            <select className="form-control" data-choices
                                                                                data-choices-search-false
                                                                                name="experienceYear"
                                                                                id="experienceYear">
                                                                                <option defaultValue="">Select years</option>
                                                                                <option value="Choice 1">2001</option>
                                                                                <option value="Choice 2">2002</option>
                                                                                <option value="Choice 3">2003</option>
                                                                                <option value="Choice 4">2004</option>
                                                                                <option value="Choice 5">2005</option>
                                                                                <option value="Choice 6">2006</option>
                                                                                <option value="Choice 7">2007</option>
                                                                                <option value="Choice 8">2008</option>
                                                                                <option value="Choice 9">2009</option>
                                                                                <option value="Choice 10">2010</option>
                                                                                <option value="Choice 11">2011</option>
                                                                                <option value="Choice 12">2012</option>
                                                                                <option value="Choice 13">2013</option>
                                                                                <option value="Choice 14">2014</option>
                                                                                <option value="Choice 15">2015</option>
                                                                                <option value="Choice 16">2016</option>
                                                                                <option value="Choice 17" >2017</option>
                                                                                <option value="Choice 18">2018</option>
                                                                                <option value="Choice 19">2019</option>
                                                                                <option value="Choice 20">2020</option>
                                                                                <option value="Choice 21">2021</option>
                                                                                <option value="Choice 22">2022</option>
                                                                            </select>
                                                                        </Col>

                                                                        <div className="col-auto align-self-center">
                                                                            to
                                                                        </div>

                                                                        <Col lg={5}>
                                                                            <select className="form-control" data-choices
                                                                                data-choices-search-false
                                                                                name="choices-single-default2">
                                                                                <option defaultValue="">Select years</option>
                                                                                <option value="Choice 1">2001</option>
                                                                                <option value="Choice 2">2002</option>
                                                                                <option value="Choice 3">2003</option>
                                                                                <option value="Choice 4">2004</option>
                                                                                <option value="Choice 5">2005</option>
                                                                                <option value="Choice 6">2006</option>
                                                                                <option value="Choice 7">2007</option>
                                                                                <option value="Choice 8">2008</option>
                                                                                <option value="Choice 9">2009</option>
                                                                                <option value="Choice 10">2010</option>
                                                                                <option value="Choice 11">2011</option>
                                                                                <option value="Choice 12">2012</option>
                                                                                <option value="Choice 13">2013</option>
                                                                                <option value="Choice 14">2014</option>
                                                                                <option value="Choice 15">2015</option>
                                                                                <option value="Choice 16">2016</option>
                                                                                <option value="Choice 17">2017</option>
                                                                                <option value="Choice 18">2018</option>
                                                                                <option value="Choice 19">2019</option>
                                                                                <option value="Choice 20">2020</option>
                                                                                <option value="Choice 21">2021</option>
                                                                                <option value="Choice 22">2022</option>
                                                                            </select>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>

                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="jobDescription" className="form-label">Job
                                                                        Description</Label>
                                                                    <Input type='textarea'
                                                                        rows="3"
                                                                        onChange={(e) => jobDescription(e)}
                                                                        value={jobdescription}
                                                                        placeholder="Enter description"
                                                                        className="form-control" id="jobDescription"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <div className="hstack gap-2 justify-content-end">
                                                                <Link className="btn btn-success"
                                                                    to="#">Delete</Link>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div id="newForm" style={{ "display": "none" }}>
                                                </div>

                                                <Col lg={12}>
                                                    <div className="hstack gap-2">
                                                        <button type="submit" className="btn btn-success">Update</button>
                                                        <Link to="#" className="btn btn-primary">Add
                                                            New</Link>
                                                    </div>
                                                </Col>
                                            </form> */}
                                        </TabPane>

                                        <TabPane tabId="4">
                                            {/* <div className="mb-4 pb-2">
                                                <h5 className="card-title text-decoration-underline mb-3">Security:</h5>
                                                <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                                                    <div className="flex-grow-1">
                                                        <h6 className="fs-15 mb-1">Two-factor Authentication</h6>
                                                        <p className="text-muted">Two-factor authentication is an enhanced
                                                            security meansur. Once enabled, you'll be required to give
                                                            two types of identification when you log into Google
                                                            Authentication and SMS are Supported.</p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-3">
                                                        <Link to="#"
                                                            className="btn btn-sm btn-primary">Enable Two-facor
                                                            Authentication</Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                                                    <div className="flex-grow-1">
                                                        <h6 className="fs-15 mb-1">Secondary Verification</h6>
                                                        <p className="text-muted">The first factor is a password and the
                                                            second commonly includes a text with a code sent to your
                                                            smartphone, or biometrics using your fingerprint, face, or
                                                            retina.</p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-3">
                                                        <Link to="#" className="btn btn-sm btn-primary">Set
                                                            up secondary method</Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                                                    <div className="flex-grow-1">
                                                        <h6 className="fs-15 mb-1">Backup Codes</h6>
                                                        <p className="text-muted mb-sm-0">A backup code is automatically
                                                            generated for you when you turn on two-factor authentication
                                                            through your iOS or Android Twitter app. You can also
                                                            generate a backup code on twitter.com.</p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-3">
                                                        <Link to="#"
                                                            className="btn btn-sm btn-primary">Generate backup codes</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h5 className="card-title text-decoration-underline mb-3">Application Notifications:</h5>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <label htmlFor="directMessage"
                                                                className="form-check-label fs-15">Direct messages</label>
                                                            <p className="text-muted">Messages from people you follow</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="directMessage" defaultChecked />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-15"
                                                                htmlFor="desktopNotification">
                                                                Show desktop notifications
                                                            </Label>
                                                            <p className="text-muted">Choose the option you want as your
                                                                default setting. Block a site: Next to "Not allowed to
                                                                send notifications," click Add.</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="desktopNotification" defaultChecked />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-15"
                                                                htmlFor="emailNotification">
                                                                Show email notifications
                                                            </Label>
                                                            <p className="text-muted"> Under Settings, choose Notifications.
                                                                Under Select an account, choose the account to enable
                                                                notifications for. </p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="emailNotification" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-15"
                                                                htmlFor="chatNotification">
                                                                Show chat notifications
                                                            </Label>
                                                            <p className="text-muted">To prevent duplicate mobile
                                                                notifications from the Gmail and Chat apps, in settings,
                                                                turn off Chat notifications.</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="chatNotification" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-15"
                                                                htmlFor="purchaesNotification">
                                                                Show purchase notifications
                                                            </Label>
                                                            <p className="text-muted">Get real-time purchase alerts to
                                                                protect yourself from fraudulent charges.</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="purchaesNotification" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 className="card-title text-decoration-underline mb-3">Delete This
                                                    Account:</h5>
                                                <p className="text-muted">Go to the Data & Privacy section of your profile
                                                    Account. Scroll to "Your data & privacy options." Delete your
                                                    Profile Account. Follow the instructions to delete your account :
                                                </p>
                                                <div>
                                                    <Input type="password" className="form-control" id="passwordInput"
                                                        placeholder="Enter your password" defaultValue="make@321654987"
                                                        style={{ maxWidth: "265px" }} />
                                                </div>
                                                <div className="hstack gap-2 mt-3">
                                                    <Link to="#" className="btn btn-soft-danger">Close &
                                                        Delete This Account</Link>
                                                    <Link to="#" className="btn btn-light">Cancel</Link>
                                                </div>
                                            </div> */}
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Profile;