import React, {useState, useCallback, useMemo, useContext} from "react";
import { Link } from "react-router-dom";
// Import Images
import companyImg from "../../assets/images/companies/company-logo.png";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import toast from "react-hot-toast";
import TableContainerMin from "../../Components/Common/TableContainerMin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteClient, getClients } from "../../api/clients/clientsApi";
import AddEditForm from "./component/addEditForm";
import CustomerOverview from "./overview";
import {DownloadOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Empty, Result, Spin} from "antd";
import HistoryContext from "../../Components/Hooks/HistoryContext";

const Enterprises = () => {
    const history = useContext(HistoryContext)
  document.title = `Clients | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
  const queryClient = useQueryClient();
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [client, setClient] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);

  const {
    isLoading,
    isError,
    error,
    data: clients,
  } = useQuery("clients", getClients);

  // Delete Customer
  const deleteClientMutation = useMutation(deleteClient, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("clients");
      toast.success("Le client a été supprimé avec succès")
    },
  });

  // Toggle Modal
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setClient(null);
      setAvatarPreview(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const handleDeleteClient = () => {
    if (client) {
      deleteClientMutation.mutate(client.id)
      setDeleteModal(false);
    }
  };

  const onClickDelete = (client) => {
    setClient(client);
    setDeleteModal(true);
  };

  // Add Data
  const handleClientClicks = () => {
    setClient("");
    setIsEdit(false);
    toggle();
  };

  // Update Data
  const handleClientClick = useCallback(
    (arg) => {
      const client = arg;
      setClient({
        id: client.id,
        clientImageUrl: client.clientImageUrl,
        name: client.name,
        address: client.address,
        email: client.email,
        phone: client.phone,
        fax: client.fax,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".companyCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      deleteClientMutation.mutate(element.value)
    });

    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".companyCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="companyCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Entreprise",
        Cell: (enterprise) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {
                  enterprise.row.original.clientImgUrl ? (
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        "/data-file/" +
                        enterprise.row.original.clientImgUrl
                      }
                      alt=""
                      className="avatar-xxs rounded-circle"
                    />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                        {/* {enterprise.row.original.name.charAt(0)} */}
                        <img
                          src={companyImg}
                          alt=""
                          className="avatar-xxs rounded-circle"
                        />
                      </div>
                    </div>
                  )
                  // <img src={multiUser} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                {enterprise.row.original.name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Adresse",
        accessor: "address",
        filterable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Téléphone",
        accessor: "phone",
        filterable: false,
      },
      {
        Header: "Fax",
        accessor: "fax",
        filterable: false,
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Voir">
                <Link
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    setInfo(companyData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Modifier">
                <Link
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    handleClientClick(companyData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Supprimer">
                <Link
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    onClickDelete(companyData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleClientClick, checkedAll]
  );

  const [info, setInfo] = useState(null);

  const HeaderTable = () => {
    return (
      <div className="d-flex align-items-center justify-content-end flex-wrap gap-2">
        <div className="flex-shrink-0">
          <div className="hstack text-nowrap gap-2">
            {isMultiDeleteButton && (
              <button
                className="btn btn-danger"
                onClick={() => setDeleteModalMulti(true)}
              >
                <i className="ri-delete-bin-2-line"></i>
              </button>
            )}
            <Button onClick={() => setIsExportCSV(true)} icon={<DownloadOutlined/>}>
            </Button>
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => {
                  setModal(true);
                  setClient("");
                  setIsEdit(false);
                }}
            >Enregistrer un client</Button>
          </div>
        </div>
      </div>
    );
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

    if (isLoading) {
        return <Spin spinning={isLoading} fullscreen />
    }
    if (isError) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Result
                        status="warning"
                        title={`${error.message}}`}
                        extra={
                            <Button type="primary" key="console" onClick={() => window.location.reload()}>
                                Refresh
                            </Button>
                        }
                    />
                </Container>
            </div>
        );
    }

    // if (clients && !clients?.length>0) {
    //     return (
    //         <div className="page-content">
    //             <Container fluid>
    //                 <Empty
    //                     image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    //                     imageStyle={{
    //                         height: 150,
    //                     }}
    //                     description={
    //                         <span>
    //                     Aucune entreprise n'a été enregistrée!
    //                 </span>
    //                     }
    //                 >
    //                     <Button
    //                         onClick={() => {
    //                             setModal(true);
    //                             setClient("");
    //                             setIsEdit(false);
    //                         }}
    //                         type="primary">Créer Maintenant
    //                     </Button>
    //                 </Empty>
    //             </Container>
    //         </div>
    //     )
    // }

  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={clients || []}
        />

        <DeleteModal
          item={client?.name}
          show={deleteModal}
          onDeleteClick={handleDeleteClient}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Container fluid>
            <BreadCrumb
                page="Entreprises"
                history={history}
            />
            <Row>
                <Col xxl={`${info ? 9 : 12}`}>
                    <Card id="contactList">
                        <CardHeader>
                            <HeaderTable />
                        </CardHeader>
                        <CardBody className="pt-0">
                            <div>
                                <TableContainerMin
                                    columns={columns}
                                    data={clients || []}
                                    isGlobalSearch={true}
                                    isGlobalFilter={true}
                                    isUsersFilter={false}
                                    customPageSize={5}
                                    className="custom-header-css"
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light"
                                    handleUserClick={handleClientClicks}
                                    SearchPlaceholder="Recherche..."
                                    HeaderTable={HeaderTable}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                {info && <CustomerOverview info={info} setInfo={setInfo}/>}
            </Row>
          <AddEditForm
            modal={modal}
            setModal={setModal}
            isEdit={isEdit}
            client={client}
            toggle={toggle}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Enterprises;
