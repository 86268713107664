import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
const api = new APIClient();

export const getDashboardMetrics = async () => {
    const members = await api.get(url.GET_USERS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    const projects = await api.get(url.GET_ALL_PROJECTS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    const tasks = await api.get(url.GET_ALL_AUDITS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});

    return {
        taskCount: tasks.length || 0,
        projectCount: projects.filter((item) => item.projectId !== 0).length || 0,
        completedTaskCount: tasks.filter((item) => item.status === 'Completed').length || 0,
        inprogressTaskCount: tasks.filter((item) => item.status === 'In_Progress').length || 0,
        createdTaskCount: tasks.filter((item) => item.status === 'Created').length || 0,
        inprogressProjectCount: projects.filter((item) => item.status === 'En cours').length || 0,
        technicianCount: members.filter((item) => item.userRole === 'ROLE_MOBILE_USER').length || 0,
        engineerCount: members.filter((item) => item.userRole === 'ROLE_BACK_OFFICE_MANAGER').length || 0,
        tasks: tasks || [],
        projects: projects.filter((item) => item.projectId !== 0) || [],
        projectsStatus: projects.filter((item) => item.projectId !== 0).map((p) =>
            (p.createdTaskCount+p.assignedTaskCount) === p.totalTaskCount
            ? p.status = 'Initié' 
            : (p.inProgressTaskCount > 0 || p.completedTaskCount > 0) 
                ? p.status = 'En Cours' 
                :  (p.totalTaskCount > 0 && p.totalTaskCount === p.validatedTaskCount)
                    ? p.status = 'Terminé'
                    : p.status = 'StandBy'
        ) || [],
        memberCount: members.length || 0,
        boxPlaces: tasks.map((item) =>({
            'tache': item?.name, 
            'status': item?.status,
            'place':item?.place,
            'ref': '',
            'technician': item?.assignedTo[0]
        })).filter((item) => item?.place?.location?.latitude != null) || []
        // boxPlaces: tasks.map((item) => item.place).filter((item) => item.location.latitude != null) || []
    };
}