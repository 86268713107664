import { Link, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
//import images
import avatar7 from "../../../../assets/images/users/avatar-user.png";
import { useEffect, useState } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import { addAuditComment } from "../../../../api/audits/auditsApi";

const AuditComment = (props) => {
  const param = useParams();
  const queryClient = useQueryClient();
  //Add Audit To Project Mutation
  const addCommentToAuditMutation = useMutation(addAuditComment, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("audit");
      // toast.success('successfully');
    },
    onError: (error) => {
      toast.error(error);
    },
  });
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (props.comments) {
      setComments(props.comments);
    }
  }, [props.comments]);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, "hh:mm").format("HH:mm");
    return updateTime;
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      author: "",
      comment: "",
      taskId: "",
    },
    validationSchema: Yup.object({
      author: Yup.string(),
      comment: Yup.string().required("Veuillez ajouter un commentaire"),
    }),
    onSubmit: (values) => {
      setComments([
        ...comments,
        {
          authorName: JSON.parse(sessionStorage.getItem("authUser"))?.username,
          commentText: values["comment"],
          taskId: param.id,
          updated: new Date(),
        },
      ]);
      addCommentToAuditMutation.mutate({
        author: JSON.parse(sessionStorage.getItem("authUser"))?.username,
        comment: values["comment"],
        taskId: param.id,
      });
      validation.resetForm();
    },
  });

  return (
    <>
      <h5 className="card-title mb-4">Commentaires</h5>
      {props?.comments?.length > 0 ? (
        <SimpleBar style={{ height: "300px" }} className="px-3 mx-n3 mb-2">
          {[...comments]
            .sort((a, b) =>
              new Date(a.updated) < new Date(b.updated)
                ? 1
                : new Date(a.updated) > new Date(b.updated)
                ? -1
                : 0
            )
            .map((item, i) => {
              return [
                <div className="d-flex mb-4" key={i}>
                  <div className="flex-shrink-0">
                    <img
                      src={avatar7}
                      alt=""
                      className="avatar-xs rounded-circle"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="fs-15">
                      <Link to="/pages-profile">
                        {item?.authorName ===
                        JSON.parse(sessionStorage.getItem("authUser"))?.username
                          ? "Moi"
                          : item?.authorName}
                      </Link>{" "}
                      <small className="text-muted">
                        {handleValidDate(item.updated)} -{" "}
                        {handleValidTime(item.updated)}
                      </small>
                    </h5>
                    <p className="text-muted">{item.commentText}</p>
                  </div>
                </div>,
              ];
            })}
        </SimpleBar>
      ) : (
        <div className="py-4 text-center">
          <div>
            <lord-icon
              src="https://cdn.lordicon.com/wzrwaorf.json"
              trigger="loop"
              colors="primary:#405189,secondary:#0ab39c"
              style={{ width: "72px", height: "72px" }}
            ></lord-icon>
          </div>

          <div className="mt-4">
            <h5>Désolé! Pas de commentaire!</h5>
          </div>
        </div>
      )}

      
      <form
        className="mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row className="g-3">
          <Col lg={12}>
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              Laissez un Commentaire
            </label>
            <textarea
              name="comment"
              className="form-control bg-light border-light"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Entrez votre commentaire"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.comment || ""}
            ></textarea>
          </Col>
          <Col xs={12} className="text-end">
            <button type="submit" className="btn btn-success">
              Commentez
            </button>{" "}
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AuditComment;
