import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Cassette from "./cassette/Cassette";
import {useContext, useEffect, useRef} from "react";
import TaskContext from "../../../../hook/TaskContext";
import {useInfiniteQuery} from "react-query";
import {useIntersection} from "@mantine/hooks";
import {Button, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";


const ShowAllCassetteModal = ({toggleAllModal, showAllCassetteMdl}) => {
    const {cassettesData} = useContext(TaskContext);

    const fetchCassette = async (page) => {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        return cassettesData.slice((page - 1) * 2, page * 2)
    }

    const {data, fetchNextPage, isFetchingNextPage} = useInfiniteQuery(
        ['query'],
        async ({ pageParam = 1}) => {
            const response = await fetchCassette(pageParam)
            return response;
        },
        {
            getNextPageParam: (_, pages) => {
                return pages.length + 1;
            },
            initialData: {
                pages: [cassettesData.slice(0, 2)],
                pageParams: [1]
            }
        })

    const lastCassetteRef = useRef(null);
    const {ref, entry} = useIntersection({
        root: lastCassetteRef.current,
        threshold: 1
    })

    useEffect(() => {
        if (entry?.isIntersecting) fetchNextPage()
    }, [entry]);

    const _cassettes = data?.pages.flatMap((page) => page)
    return (
        <Modal
            size="xl"
            isOpen={showAllCassetteMdl}
            toggle={toggleAllModal}
            className="modal-fullscreen"
            id="allCassetteModal"
        >
            <ModalHeader
                className="modal-title"
                id="allCassetteModal"
                toggle={toggleAllModal}
            >
            </ModalHeader>
            <ModalBody>
                {_cassettes?.map((cassette, index) => {
                    if (index === _cassettes.length-1) return <div ref={ref} className='mb-3'><Cassette
                        cassette={cassette} key={index}></Cassette></div>
                    return <div className='mb-3'><Cassette cassette={cassette} key={index}></Cassette></div>
                })}

                <div class="col text-center">
                    <Button onClick={() => fetchNextPage()} disabled={isFetchingNextPage} type="link">
                        {isFetchingNextPage
                            ? 'Charger plus de cassettes...'
                            : (data?.pages.length ?? 0) < cassettesData?.length
                                ? 'Charger plus de cassettes...'
                                : ''
                        }
                    </Button>
                    {isFetchingNextPage && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                </div>
            </ModalBody>
        </Modal>
);
}

export default ShowAllCassetteModal;