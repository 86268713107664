import {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {updateCable} from "../../../../../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import {Badge, Button, Form} from "reactstrap";
import InlineInputEdit from "./InlineInputEdit/InlineInputEdit";

const InlineInputEditCable = ({cable, onSave, isEdit, setIsEdit}) => {

    const queryClient = useQueryClient();
    //Add Audit To Project Mutation
    const editCableAuditMutation = useMutation(updateCable, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            toast.success("Câble modifié avec succès!");
            setIsEdit(false)
        },
        onError: (error) => {
            toast.error(error);
        },
    });
    const [cableName, setCableName] = useState(cable?.cableName);
    const [cableCapacity, setCableCapacity] = useState(cable?.cableCapacity);
    const [cableModulo, setCableModulo] = useState(cable?.cableModulo);
    const [ncinumber, setNcinumber] = useState(cable?.ncinumber);
    const [cableManufacturing, setCableManufacturing] = useState(cable?.manufacturerReference);
    const [cableManufacturingYear, setCableManufacturingYear] = useState(cable?.manufacturingYear);
    const [cableInstallationYear, setCableInstallationYear] = useState(cable?.installationYear);

    const [isFocused, setIsFocused] = useState(false);
    function focused() {
        setIsFocused(true)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("cableName", cableName);
        formData.append("cableCapacity", cableCapacity);
        formData.append("cableModulo", cableModulo);
        formData.append("ncinumber", ncinumber);
        formData.append("manufacturerReference", cableManufacturing);
        formData.append("manufacturingYear", cableManufacturingYear);
        formData.append("installationYear", cableInstallationYear);
        formData.append("cableImageUrl", cable?.cableImageUrl || "")
        const data = {
            formData,
            cableId: cable && cable.cableId,
        };
        editCableAuditMutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit}
        >
            {/*<div className="d-flex justify-content-end ">*/}
            {/*    <Button color="link" onClick={onEdit} className="btn-icon mx-1" outline>*/}
            {/*        <i className={isEdit ? 'ri-close-line' : 'ri-edit-line'} style={{color: isEdit ? "red" : null, fontSize: '18px'}}/>*/}
            {/*    </Button>*/}
            {/*    {isEdit*/}
            {/*        ? <Button color="link" className="btn-icon" outline> <i className="ri-save-2-fill" style={{fontSize: '18px'}} /></Button>*/}
            {/*        : null*/}
            {/*    }*/}
            {/*</div>*/}
            <div className="table-responsive ms-2">

                <table className="table mb-0">
                    <tbody>
                    <tr>
                        <th style={{textAlign: 'left'}} scope="row">
                            Référence Câble
                        </th>
                        <td>
                            <Badge color="warning" pill>
                                {isEdit ? (
                                    <InlineInputEdit
                                        name="cableName"
                                        value={cableName}
                                        setValue={setCableName}
                                        onfocus={focused}
                                        setIsFocused={setIsFocused}
                                    />
                                ) : cable?.cableName}
                            </Badge>
                        </td>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'left'}} scope="row">Capacité</th>
                        <td>
                            {isEdit ? (
                                <InlineInputEdit name="cableCapacity" value={cableCapacity} setValue={setCableCapacity}
                                            onfocus={focused} setIsFocused={setIsFocused}/>
                            ) : cable?.cableCapacity}
                        </td>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'left'}} scope="row">Modulo</th>
                        <td>
                            {isEdit ? (
                                <InlineInputEdit name="cableModulo" value={cableModulo} setValue={setCableModulo}
                                            onfocus={focused} setIsFocused={setIsFocused}/>
                            ) : cable?.cableModulo}
                        </td>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'left'}} scope="row">Numéro FCI</th>
                        <td>
                            {isEdit ? (
                                <InlineInputEdit name="ncinumber" value={ncinumber} setValue={setNcinumber}
                                            onfocus={focused} setIsFocused={setIsFocused}/>
                            ) : cable?.ncinumber}
                        </td>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'left'}} scope="row">Fabriquant</th>
                        <td>
                            {isEdit ? (
                                <InlineInputEdit name="cableManufacturing" value={cableManufacturing}
                                            setValue={setCableManufacturing} onfocus={focused}
                                            setIsFocused={setIsFocused}/>
                            ) : cable?.manufacturerReference}
                        </td>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'left'}} scope="row">Année de Fabrication</th>
                        <td>
                            {isEdit ? (
                                <InlineInputEdit name="cableManufacturingYear" value={cableManufacturingYear}
                                            setValue={setCableManufacturingYear} onfocus={focused}
                                            setIsFocused={setIsFocused}/>
                            ) : cable?.manufacturingYear}
                        </td>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'left'}} scope="row">Année d'Installation</th>
                        <td>
                            {isEdit ? (
                                <InlineInputEdit name="cableInstallationYear" value={cableInstallationYear}
                                            setValue={setCableInstallationYear} onfocus={focused}
                                            setIsFocused={setIsFocused}/>
                            ) : cable?.installationYear}
                        </td>
                    </tr>
                    </tbody>
                </table>
               
            </div>
        </Form>
    );
}

export default InlineInputEditCable;