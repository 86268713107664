import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import Dragula from 'react-dragula';
import { StatusFormat } from "../../../../helpers/status_format";
import React, { useCallback, useState } from "react";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { deleteAudit } from "../../../../api/audits/auditsApi";
import * as moment from "moment";
import {Button} from "antd";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";

const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
};

const TaskTab = ({tasks}) => {
    const queryClient = useQueryClient();
    const deleteProjectTaskMutation = useMutation(deleteAudit, {
        onSuccess: () => {
        // Invalidates cache and refetch data
        queryClient.invalidateQueries("projects");
        toast.success("La tache a été supprimée avec succès");
        },
        onError: (error) => {
        // An error happened!
        toast.error(error);
        },
    });

    const navigate = useNavigate();
    const [task, setTask] = useState({});
    // Delete Task
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    const onClickTodoDelete = (task) => {
        setTask(task);
        setDeleteModal(true);
    }

     // Delete Data
    const handleDeleteTask = () => {
        if (task) {
            deleteProjectTaskMutation.mutate(task.id)
            setDeleteModal(false);
        }
    };

    const handleTodoClick = (item) => {
        navigate(`/demande-overview/${item.id}`)
    }

    const dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
            let options = {};
            Dragula([componentBackingInstance], options);
        }
    };

    // Checked All
    const checkedAllTask = useCallback(() => {
        const checkall = document.getElementById("checkBoxAllTask");
        const ele = document.querySelectorAll(".taskCheckBox");

        if (checkall.checked) {
        ele.forEach((ele) => {
            ele.checked = true;
        });
        } else {
        ele.forEach((ele) => {
            ele.checked = false;
        });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".taskCheckBox:checked");
        ele.length > 0
          ? setIsMultiDeleteButton(true)
          : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    //Delete multiples tasks
    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAllTask");
        selectedCheckBoxDelete.forEach((element) => {
            deleteProjectTaskMutation.mutate(element.value)
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                deleteMultiple();
                setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteTask}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            {isMultiDeleteButton && (
                                <div className="flex-shrink-0 mb-2">
                                    <div className="d-flex justify-content-end hstack text-nowrap gap-2">
                                        <Button onClick={() => setDeleteModalMulti(true)} type="primary" danger shape="circle" icon={<DeleteOutlined />} size="large" />
                                    </div>
                                </div>
                                // <div className="flex-shrink-0">
                                //     <div className="d-flex justify-content-end hstack text-nowrap gap-2">
                                //         <button
                                //         className="btn btn-danger"
                                //         onClick={() => setDeleteModalMulti(true)}
                                //         >
                                //         <i className="ri-delete-bin-2-line"></i>
                                //         </button>
                                //     </div>
                                // </div>

                            )}
                            <div className="todo-content position-relative px-4 mx-n4" id="todo-content">
                                <div className="todo-task" id="todo-task">
                                    <div className="table-responsive">
                                        <table className="table align-middle position-relative table-nowrap">
                                            <thead className="table-active">
                                                <tr>
                                                    <th scope="col">
                                                        <span className="mx-1">
                                                            <input
                                                                type="checkbox"
                                                                id="checkBoxAllTask"
                                                                className="form-check-input"
                                                                onClick={() => checkedAllTask()}
                                                            />
                                                        </span>
                                                        Tâches
                                                    </th>
                                                    <th scope="col">Intervention</th>
                                                    <th scope="col">Date Limite</th>
                                                    <th scope="col">Adresse</th>
                                                    <th scope="col">Statut</th>
                                                    <th scope="col">Priorité</th>
                                                    {/* <th scope="col">Assignée à</th> */}
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody id="task-list" ref={dragulaDecorator}>
                                                {(tasks || []).map((item, key) => (<tr key={key}>
                                                    <td>
                                                        <input
                                                            className="form-control filelist-id"
                                                            type="hidden"
                                                            value="1"
                                                            id="tasklist-1"
                                                        />
                                                        <div className="d-flex align-items-center">
                                                            <div className="mx-1">
                                                                <input
                                                                    type="checkbox"
                                                                    className="taskCheckBox form-check-input"
                                                                    value={item.id}
                                                                    onChange={() => deleteCheckbox()}
                                                                />
                                                            </div>
                                                            <div className="flex-grow-1 filelist-name">
                                                                {item?.name}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{handleValidDate(item.endDate)}</td>
                                                    <td>{handleValidDate(item.dueDate)}</td>
                                                    <td>{item.address}</td>
                                                    <td>
                                                        <Status status={item.status} />
                                                    </td>
                                                    <td>
                                                        <Priority priority={item.priority} />
                                                    </td>
                                                    {/* <td>
                                                        <div className="avatar-group">
                                                            {(item.subItem || []).map((item, key) => (<Link to="#" className="avatar-group-item" key={key}>
                                                                <img src={item.img} alt="" className="rounded-circle avatar-xxs" />
                                                            </Link>))}
                                                        </div>
                                                    </td> */}
                                                    <td>
                                                        <div className="hstack gap-2">
                                                            <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(item)}>
                                                                <i className="ri-delete-bin-5-fill align-bottom" />
                                                            </button>
                                                            <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleTodoClick(item)}>
                                                                <i className="ri-eye-fill align-bottom" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="py-4 mt-4 text-center" id="noresult" style={{ display: "none" }}>
                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{ width: "72px", height: "72px" }}></lord-icon>
                                    <h5 className="mt-4">Sorry! No Result Found</h5>
                                </div>
                            </div>                
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default TaskTab;

const Status = ({ status }) => {
    switch (status) {
        case "Affected":
            return <span className="badge bg-primary-subtle text-primary text-uppercase">{StatusFormat(status)}</span>;
        case "In_Progress":
            return <span className="badge bg-secondary-subtle text-secondary text-uppercase">{StatusFormat(status)}</span>;
        case "Completed":
            return <span className="badge bg-info-subtle text-info text-uppercase">{StatusFormat(status)}</span>;
        case "Validated":
            return <span className="badge bg-warning-subtle text-warning text-uppercase">{StatusFormat(status)}</span>;
        case "Shared":
            return <span className="badge bg-success-subtle text-success text-uppercase">{StatusFormat(status)}</span>;
        case "Created":
            return <span className="badge bg-danger-subtle text-danger text-uppercase">{StatusFormat(status)}</span>;
        default:
            return null
    }
}

const Priority = ({ priority }) => {
    switch (priority) {
        case "High":
            return <span className="badge bg-warning text-uppercase">{priority}</span>;
        case "Highest":
            return <span className="badge bg-danger text-uppercase">{priority}</span>;
        case "Medium":
            return <span className="badge bg-primary text-uppercase">{priority}</span>;
        case "Low":
            return <span className="badge bg-info text-uppercase">{priority}</span>;
        case "Lowest":
            return <span className="badge bg-secondary text-uppercase">{priority}</span>;
        default:
            return null
    }
}
