import { useDispatch } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import AttachedFileComponent from "../../../Components/Common/AttachedFileComponent";
import {createClient, getClients} from "../../../api/clients/clientsApi";
import {getMembers} from "../../../api/users/usersApi";
import {useMutation, useQuery} from "react-query";
import {addTaskToProject, getAllProjects} from "../../../api/projects/projectsApi";
import {assignAuditToEnterprise, createAudit, updateAudit} from "../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import {message} from "../../../Components/constants/message";

const AddEditForm = ({
  modal,
  demande,
  toggle,
  isEdit,
  toggleTabForm,
  activeTabForm,
  queryClient,
    setTask
}) => {
  const [techniciens, setTechniciens] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [displayForm, setDisplayForm] = useState(false);

  const {isLoading, data: projets, isError, error, isFetched: isFetchedProject} = useQuery("projects", getAllProjects);
  // const projectsQuery = useQuery({
  //   queryKey: ["projects"],
  //   queryFn: getAllProjects
  // });
  const customersQuery = useQuery({
    queryKey: ["companies"],
    queryFn: getClients
  });
  const techniciansQuery = useQuery({
    queryKey: ["members"],
    queryFn: getMembers,
    select: (data) =>
        data.filter((item) => item.userRole === "ROLE_MOBILE_USER"),
  });

  useEffect(() => {
    if (techniciansQuery.status === 'success') {
      setTechniciens(techniciansQuery.data)
    }
    if (customersQuery.status === 'success') {
      setCompanies(customersQuery.data)
    }
    // if (projectsQuery.status === 'success') {
    //   setProjects(projectsQuery.data)
    // }
  }, [toggle]);


  const addAuditMutation = useMutation(createAudit, {
    onSuccess: (data) => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("audits");
      setTask(data)
      toast.success(message.AUDIT_CREATED_SUCCESSFULLY);
    },
    onError: (error) => {
      toast.error(error)
    }
  });

  const updateAuditMutation = useMutation(updateAudit, {
    onSuccess: (data) => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("audits");
      setTask(data)
      toast.success(message.AUDIT_UPDATED_SUCCESSFULLY)
    },
    onError: (error) => {
      toast.error(error)
    }
  });

  const assignAuditToEnterpriseMutation = useMutation(assignAuditToEnterprise, {
    onSuccess: () => {
      queryClient.invalidateQueries("audits");
      toast.success(message.AUDIT_ASSIGNATION_ENTERPRISE_SUCCESSFULLY);
    }
  });

  const addClientMutation = useMutation(createClient, {
    onSuccess: (data) => {
      assignAuditToEnterpriseMutation.mutate({clientId: data.id, auditTaskId: task.id})
    }
  })

  const addTaskToProjectMutation = useMutation(addTaskToProject, {
    onSuccess: () => {
      queryClient.invalidateQueries("audits");
      toast.success(message.AUDIT_ASSIGNATION_PROJECT_SUCCESSFULLY);
    }
  })


  const handleForm = () => {
    return setDisplayForm(!displayForm);
  };

  // validation form task
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (demande && demande.name) || "",
      description: (demande && demande.description) || "",
      address: (demande && demande.address) || "",
      priority: (demande && demande.priority) || "Medium",
      status: (demande && demande.status) || "Created",
      dueDate: (demande && demande.dueDate) || "",
      endDate: (demande && demande.endDate) || "",
      createdDate: (demande && demande.createdDate) || "",
      interlocFirstname: (demande && demande.interlocFirstname) || "",
      interlocLastname: (demande && demande.interlocLastname) || "",
      interlocEmail: (demande && demande.interlocEmail) || "",
      interlocPhone: (demande && demande.interlocPhone) || "",
      userId: (demande && demande.userId) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez donner un libellé à la tâche"),
      address: Yup.string().required("Veuillez donner le lieu d'intervention"),
      description: Yup.string(),
      dueDate: Yup.string(),
      endDate: Yup.string(),
    }),
    onSubmit: (values) => {
      const {
        name,
        description,
        address,
        priority,
        status,
        dueDate,
        endDate,
        interlocFirstname,
        interlocLastname,
        interlocEmail,
        interlocPhone
      } = values;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("address", address);
      formData.append("priority", priority);
      formData.append("status", status);
      formData.append("dueDate", dueDate);
      formData.append("endDate", endDate);
      formData.append("interlocFirstname", interlocFirstname);
      formData.append("interlocLastname", interlocLastname);
      formData.append("interlocEmail", interlocEmail);
      formData.append("interlocPhone", interlocPhone);
      formData.append("company", JSON.parse(sessionStorage.getItem("authUser"))?.company);
      if (isEdit) {
        const data = {
          formData,
          attachedFiles: selectedFiles,
          userId: values.userId,
          id: demande && demande.id
        };
        updateAuditMutation.mutate(data)
        // validation.resetForm();
      } else {
        const data = {
          formData,
          attachedFiles: selectedFiles,
          userId: values.userId,
        };

        addAuditMutation.mutate(data);
        validation.resetForm();
      }
    },
  });

  //validation form client
  const validation1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientId: (demande && demande.clientId) || "",
      name: "",
      address: "",
      email: "",
      phone: "",
      fax: "",
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      address: Yup.string(),
      email: Yup.string(),
      phone: Yup.string(),
      fax: Yup.string(),
    }),
    onSubmit: (values) => {
      const {
        name,
        address,
        email,
        phone,
        fax
      } = values;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("address", address);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("fax", fax);

      if (displayForm) {
        const data = {
          'client' : {
            'name': name,
            'address': address,
            'email': email,
            'phone': phone,
            'fax': fax
          }
        }
        addClientMutation.mutate(data)
      } else {
        assignAuditToEnterpriseMutation.mutate({clientId: values.clientId, auditTaskId: demande && demande.id})
      }
    }
  });

  const validation2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectId: (demande && demande.projectId) || "",
    },
    onSubmit: (values) => {
      addTaskToProjectMutation.mutate({projectId: values.projectId, taskId: demande && demande.id})
    },
  });

  return (
    <Modal
      className="zoomIn"
      id="addSeller"
      size={activeTabForm === "1" ? "xl" : "lg"}
      isOpen={modal}
      toggle={toggle}
      centered
      backdrop={'static'}
    >
      <ModalHeader toggle={toggle}>
        {!!isEdit ? "Mise a jour Demande d'audit" : "Nouvelle Demande d'audit"}
      </ModalHeader>
      <div className="modal-content border-0 mt-3">
        <Nav className="nav-tabs nav-tabs-custom nav-success p-2 pb-0 bg-light">
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: activeTabForm === "1" })}
              onClick={() => {
                toggleTabForm("1");
              }}
            >
              Formulaire Demande d'Audit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: activeTabForm === "2" })}
              onClick={() => {
                toggleTabForm("2");
              }}
            >
              Propriétaire
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: activeTabForm === "3" })}
              onClick={() => {
                toggleTabForm("3");
              }}
            >
              Projet
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <ModalBody>
        <TabContent activeTab={activeTabForm}>
          <TabPane tabId="1">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg={6}>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="phonenumberInput" className="form-label">
                          Tâche
                          <span className="text-danger mx-1">*</span>
                        </Label>
                        <Input
                          name="name"
                          type="text"
                          className="form-control"
                          id="phonenumberInput"
                          placeholder="Donnez un titre à la Tâche"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Description
                        </Label>
                        <textarea
                          name="description"
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Donnez une description à la Tâche"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                        ></textarea>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="birthdayidInput" className="form-label">
                          Lieu d'intervention
                          <span className="text-danger mx-1">*</span>
                        </Label>
                        <Input
                          name="address"
                          type="text"
                          className="form-control"
                          id="cityidInput"
                          placeholder="Entrez l'adresse d'intervention"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address &&
                            validation.errors.address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="countryidInput" className="form-label">
                          Date d'intervention
                        </Label>
                        <Input
                          name="dueDate"
                          id="event-start-date"
                          className="form-control"
                          type="datetime-local"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.dueDate || ""}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="zipcodeidInput" className="form-label">
                          Date limite
                        </Label>
                        <Input
                          name="endDate"
                          id="event-end-date"
                          className="form-control"
                          type="datetime-local"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.endDate || ""}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="cityidInput" className="form-label">
                          Priorité
                        </Label>
                        <select
                          className="form-select"
                          name="priority"
                          data-choices
                          data-choices-search-false
                          id="choices-priority-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.priority || ""}
                        >
                          <option value="Medium">Important</option>
                          <option value="High">Urgent</option>
                          <option value="Highest">Très Urgent</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="cityidInput" className="form-label">
                          Techniciens
                        </Label>
                        <Input
                          className="form-select"
                          name="userId"
                          id="event-userId"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.userId || ""}
                        >
                          <option value=""></option>
                          {techniciens &&
                            techniciens.map(
                              ({ firstname, lastname, userId }, index) => (
                                <option key={index} value={userId}>
                                  {firstname} {lastname}
                                </option>
                              )
                            )}
                        </Input>
                        {validation.touched.userId &&
                        validation.errors.userId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.userId}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="firstnameInput" className="form-label">
                          Prénom
                        </Label>
                        <Input
                          name="interlocFirstname"
                          type="text"
                          className="form-control"
                          id="firstnameInput"
                          placeholder="Entrez le Prénom du contact"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.interlocFirstname || ""}
                          invalid={
                            validation.touched.interlocFirstname &&
                            validation.errors.interlocFirstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.interlocFirstname &&
                        validation.errors.interlocFirstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.interlocFirstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="lastnameInput" className="form-label">
                          Nom
                        </Label>
                        <Input
                          name="interlocLastname"
                          type="text"
                          className="form-control"
                          id="lastnameInput"
                          placeholder="Entrez le Nom du contact"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.interlocLastname || ""}
                          invalid={
                            validation.touched.interlocLastname &&
                            validation.errors.interlocLastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.interlocLastname &&
                        validation.errors.interlocLastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.interlocLastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="contactnumberInput" className="form-label">
                          Téléphone
                        </Label>
                        <Input
                          name="interlocPhone"
                          type="text"
                          className="form-control"
                          id="contactnumberInput"
                          placeholder="Entrez le Téléphone du contact"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.interlocPhone || ""}
                          invalid={
                            validation.touched.interlocPhone &&
                            validation.errors.interlocPhone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.interlocPhone &&
                        validation.errors.interlocPhone ? (
                          <FormFeedback type="invalid">
                            {validation.errors.interlocPhone}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="emailidInput" className="form-label">
                          Email
                        </Label>
                        <Input
                          name="interlocEmail"
                          type="email"
                          className="form-control"
                          id="emailidInput"
                          placeholder="Entrez l'email du contact"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.interlocEmail || ""}
                          invalid={
                            validation.touched.interlocEmail &&
                            validation.errors.interlocEmail
                              ? true
                              : false
                          }
                        />
                        {validation.touched.interlocEmail &&
                        validation.errors.interlocEmail ? (
                          <FormFeedback type="invalid">
                            {validation.errors.interlocEmail}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      {/* <Card>
                          <CardBody>
                            <Dropzone onDrop={handleAcceptedFiles}>
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone dz-clickable">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h5>
                                      Déposez les fichiers ici ou cliquez pour
                                      les télécharger.
                                    </h5>
                                  </div>
                                </div>
                              )}
                            </Dropzone>

                            <ul
                              className="list-unstyled mb-0"
                              id="dropzone-preview"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                            </ul>
                          </CardBody>
                        </Card> */}
                      <AttachedFileComponent
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      //disabled={!(validation.isValid && validation.dirty)}
                      type="submit"
                      className="btn btn-primary"
                    >
                      <i className="ri-save-3-line align-bottom me-1"></i>
                      {!!isEdit ? "Modifier" : "Enregistrer"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tabId="2">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation1.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label for="choices-single-default" className="form-label">
                      Entreprises
                    </Label>
                    <select
                      name="clientId"
                      className="form-select"
                      data-choices
                      data-choices-search-false
                      id="choices-categories-input"
                      onChange={(e) => {
                        validation1.handleChange(e);
                      }}
                      onBlur={validation1.handleBlur}
                      value={validation1.values.clientId || ""}
                    >
                      <option defaultValue="undefined"></option>
                      {companies &&
                        companies.map(({ name, id }, index) => (
                          <option key={index} value={id}>
                            {name}
                          </option>
                        ))}
                    </select>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      id="create-btn"
                      onClick={() => handleForm()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>
                      {displayForm ? "Annuler" : "Nouvelle Entreprise"}
                    </button>{" "}
                  </div>
                </Col>
                {displayForm ? (
                  <>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="companynameInput" className="form-label">
                          Nom Entreprise
                        </Label>
                        <Input
                          name="name"
                          type="text"
                          className="form-control"
                          id="companynameInput"
                          placeholder="Enter your company name"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.name || ""}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="pancardInput" className="form-label">
                          Adresse
                        </Label>
                        <Input
                          name="address"
                          type="text"
                          className="form-control"
                          id="pancardInput"
                          placeholder="Enter your pan-card number"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.address || ""}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label for="websiteInput" className="form-label">
                          Téléphone
                        </Label>
                        <Input
                          name="phone"
                          type="text"
                          className="form-control"
                          id="websiteInput"
                          placeholder="Enter your URL"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.phone || ""}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label for="faxInput" className="form-label">
                          Fax
                        </Label>
                        <Input
                          name="fax"
                          type="text"
                          className="form-control"
                          id="faxInput"
                          placeholder="Enter your fax"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.fax || ""}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label for="companyemailInput" className="form-label">
                          Email
                        </Label>
                        <Input
                          name="email"
                          type="email"
                          className="form-control"
                          id="companyemailInput"
                          placeholder="Enter your email"
                          onChange={validation1.handleChange}
                          onBlur={validation1.handleBlur}
                          value={validation1.values.email || ""}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="companylogoInput" className="form-label">
                          Logo Entreprise
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="companylogoInput"
                        />
                      </div>
                    </Col>
                  </>
                ) : null}
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                      Enregistrer
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tabId="3">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation2.handleSubmit();
                return false;
              }}
            >
              <div className="row">
                <Col lg={12}>
                  <div className="mb-3">
                    <Label for="choices-single-default" className="form-label">
                      Projets
                    </Label>
                    <select
                      name="projectId"
                      className="form-select"
                      data-choices
                      data-choices-search-false
                      id="choices-categories-input"
                      onChange={(e) => {
                        validation2.handleChange(e);
                      }}
                      onBlur={validation2.handleBlur}
                      value={validation2.values.projectId || ""}
                    >
                      <option defaultValue="undefined"></option>
                      {projets && Object.values(projets).filter((item) => item.projectName !== "root")
                          .map(({ projectName, projectId }, index) => (
                            <option key={index} value={projectId}>
                              {projectName}
                            </option>
                          ))}
                    </select>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    {/* <button
                      onClick={toggle}
                      className="btn btn-link link-success text-decoration-none fw-medium"
                      data-bs-dismiss="modal"
                    >
                      <i className="ri-close-line me-1 align-middle"></i> Fermer
                    </button> */}
                    <button type="submit" className="btn btn-primary">
                      <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                      Enregistrer
                    </button>
                  </div>
                </Col>
              </div>
            </Form>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default AddEditForm;
