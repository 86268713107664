import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { createTask, updateTask } from "../../../api/planning/planningApi";
import { getMembers } from "../../../api/users/usersApi";
import * as moment from "moment/moment";
import {Tag, Tooltip} from "antd";
import {CheckCircleOutlined, ClockCircleOutlined, FileDoneOutlined, SyncOutlined} from "@ant-design/icons";
import React from "react";

const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
};

const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
        meridiem = "PM";
    } else {
        meridiem = "AM";
    }
    const updateTime = moment(getTime, "hh:mm").format("HH:mm");

    return updateTime;
};

const AddEditForm = ({
  modal,
  toggle,
  isEdit,
  event,
  isEditButton,
  submitOtherEvent,
  setDeleteModal
}) => {

    const queryClient = useQueryClient();
    //Get All Projects
    const techniciansQuery = useQuery({
        queryKey: ['users'],
        queryFn: getMembers,
        select: (data) => data.filter((item) => item.userRole === "ROLE_MOBILE_USER"),
    })

    function fullName(event) {
        const user = techniciansQuery && techniciansQuery?.data?.filter((technician) => technician?.userId === event?.userId)
        return user ? `${user[0]?.firstname} ${user[0]?.lastname}` : 'Aucun Technicien'
    }

    const addTaskMutation = useMutation(createTask, {
        onSuccess: () => {
        // Invalidates cache and refetch data
        queryClient.invalidateQueries("tasks");
        toast.success("L'utilisateur a été créé avec succès")
        },
        onError: (error) => {
        // An error happened!
        toast.error(error)
        },
    });

    const updateTaskMutation = useMutation(updateTask, {
        onSuccess: () => {
        // Invalidates cache and refetch data
        queryClient.invalidateQueries("tasks");
        toast.success("L'utilisateur a été modifié avec succès")
        },
    });

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
        name: (event && event.name) || "",
        description: (event && event.description) || "",
        address: (event && event.address) || "",
        dueDate: (event && event.dueDate) || "",
        endDate: (event && event.endDate) || "",
        priority: (event && event.priority) || "",
        userId: (event && event.userId) || "",
        },

        validationSchema: Yup.object({
        name: Yup.string().required("Le libellé de la tâche est obligatoire"),
        address: Yup.string().required("L'adresse est obligatoire"),
        }),
        onSubmit: (values) => {
            const {
                name,
                description,
                address,
                priority,
                dueDate,
                endDate
            } = values;
            const formData = new FormData();
            formData.append("name", name);
            formData.append("description", description);
            formData.append("address", address);
            formData.append("priority", priority);
            formData.append("status", "In_Progress");
            formData.append("dueDate", dueDate);
            formData.append("endDate", endDate);
            formData.append("interlocFirstname", "");
            formData.append("interlocLastname", "");
            formData.append("interlocEmail", "");
            formData.append("interlocPhone", "");
            formData.append("company", JSON.parse(sessionStorage.getItem("authUser"))?.company);
            
        if (isEdit) {
            const data = {
                formData,
                userId: values.userId,
                id: event && event.id
            };

            updateTaskMutation.mutate(data)
            validation.resetForm();
        } else {
            const data = {
                formData,
                userId: values.userId
            };
            addTaskMutation.mutate(data)
            validation.resetForm();
        }
        },
    });

    // const dateControl = document.querySelector('input[type="datetime-local"]');
    // console.log(dateControl);

    return (
        <Modal isOpen={modal} id="event-modal" centered backdrop={"static"}>
        <ModalHeader
            toggle={toggle}
            tag="h5"
            className="p-3 bg-info-subtle modal-title"
        >
            {!!isEdit ? `${event.name}` : "Nouvelle tâche"}
            <span className="ms-2">
                {event?.status === "In_Progress" ? (
                    <Tag icon={<SyncOutlined spin />} color="processing">
                        En cours
                    </Tag>
                ) : event?.status === "Affected" ? (
                    <Tag icon={<ClockCircleOutlined />} color="default">
                        En attente
                    </Tag>
                ) : event?.status === "Created" ? (
                    <Tag icon={<ClockCircleOutlined />} color="default">
                        En attente
                    </Tag>
                ) : event?.status === "Completed" ? (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Complète
                    </Tag>
                ) : event?.status === "Validated" ? (
                    <Tag icon={<FileDoneOutlined />} color="warning">
                        Validée
                    </Tag>
                ) : null}
            </span>
        </ModalHeader>
        <ModalBody>
            <Form
            className={
                !!isEdit ? "needs-validation view-event" : "needs-validation"
            }
            name="event-form"
            id="form-event"
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
            >
            {!!isEdit ? (
                <div className="text-end">
                <Link
                    to={`/demande-overview/${event.id}`}
                    className="btn btn-sm btn-soft-primary mx-1"
                    id="view-event-btn"
                >
                    Ouvrir
                </Link>
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-primary"
                    id="edit-event-btn"
                    onClick={(e) => {
                    e.preventDefault();
                    submitOtherEvent();
                    return false;
                    }}
                >
                    Modifier
                </Link>
                </div>
            ) : null}

            <div className="event-details">
                <div className="d-flex mb-2">
                <div className="flex-grow-1 d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                    <i className="ri-calendar-event-line text-muted fs-16"></i>
                    </div>
                    <div className="flex-grow-1">
                    <h6
                        className="d-block fw-semibold mb-0"
                        id="event-start-date-tag"
                    >
                        Du {event ? handleValidDate(event.dueDate) : ""} au{" "}
                        {event ? handleValidDate(event.endDate) : ""}
                    </h6>
                    </div>
                </div>
                </div>
                <div className="d-flex mb-2">
                <div className="flex-grow-1 d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                    <i className="ri-time-line text-muted fs-16"></i>
                    </div>
                    <div className="flex-grow-1">
                    <h6
                        className="d-block fw-semibold mb-0"
                        id="event-start-date-tag"
                    >
                        De {event ? handleValidTime(event.dueDate) : ""} à{" "}
                        {event ? handleValidTime(event.endDate) : ""}
                    </h6>
                    </div>
                </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0 me-3">
                    <i className="ri-map-pin-line text-muted fs-16"></i>
                </div>
                <div className="flex-grow-1">
                    <h6 className="d-block fw-semibold mb-0">
                    {" "}
                    <span id="event-location-tag">
                        {event && event.address !== undefined
                        ? event.address
                        : "No Location"}
                    </span>
                    </h6>
                </div>
                </div>
                <div className="d-flex mb-3">
                <div className="flex-shrink-0 me-3">
                    <i className="ri-user-line text-muted fs-16"></i>
                </div>
                <div className="flex-grow-1">

                        <p
                            className="d-block text-muted mb-0"
                            id="event-description-tag"
                        >
                            <Tooltip title={fullName(event)}>
                                {event && fullName(event)}
                            </Tooltip>
                        </p>
                </div>
                </div>
            </div>
                <Row className="event-form">
                    <Col xs={12}>
                        <div className="mb-3">
                            <Label className="form-label">Titre</Label>
                    <Input
                    className={
                        !!isEdit ? "form-control d-none" : "form-control d-block"
                    }
                    placeholder="Enter task name"
                    type="text"
                    name="name"
                    id="event-title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    // invalid={
                    //   validation.touched.name &&
                    //   validation.errors.name
                    //     ? true
                    //     : false
                    // }
                    />
                    {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                        {validation.errors.name}
                    </FormFeedback>
                    ) : null}
                </div>
                </Col>
                <Col xs={12}>
                <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <textarea
                    className={
                        !!isEdit ? "form-control d-none" : "form-control d-block"
                    }
                    id="event-description"
                    name="description"
                    placeholder="Enter a description"
                    rows="3"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    // invalid={
                    //   validation.touched.description &&
                    //   validation.errors.description
                    //     ? true
                    //     : false
                    // }
                    ></textarea>
                    {validation.touched.description &&
                    validation.errors.description ? (
                    <FormFeedback type="invalid">
                        {validation.errors.description}
                    </FormFeedback>
                    ) : null}
                </div>
                </Col>
                <Col xs={12}>
                <div className="mb-3">
                    <Label htmlFor="event-location">Lieu d'Intervention</Label>
                    <div>
                    <Input
                        type="text"
                        className={
                        !!isEdit ? "form-control d-none" : "form-control d-block"
                        }
                        name="address"
                        id="event-location"
                        placeholder="Enter location"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        // invalid={
                        //   validation.touched.address &&
                        //   validation.errors.address
                        //     ? true
                        //     : false
                        // }
                    />
                    {validation.touched.address && validation.errors.address ? (
                        <FormFeedback type="invalid">
                        {validation.errors.address}
                        </FormFeedback>
                    ) : null}
                    </div>
                </div>
                </Col>
                <Col xs={6}>
                <div className="mb-3">
                    <Label>Date d'Intervention</Label>
                    <div
                    className={!!isEdit ? "input-group d-none" : "input-group"}
                    >
                    <Input
                        name="dueDate"
                        id="event-start-date"
                        className="form-control"
                        type="datetime-local"
                        min={new Date()}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.dueDate || ""}
                        
                    />
                    </div>
                </div>
                </Col>
                <Col xs={6}>
                <div className="mb-3">
                    <Label>Date Limite</Label>
                    <div
                    className={!!isEdit ? "input-group d-none" : "input-group"}
                    >
                    <Input
                        name="endDate"
                        id="event-end-date"
                        className="form-control"
                        type="datetime-local"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.endDate || ""}
                    />
                    </div>
                </div>
                </Col>
                <Col xs={12}>
                <div className="mb-3">
                    <Label className="form-label">Technicien</Label>
                    <Input
                    className={
                        !!isEdit ? "form-select d-none" : "form-select d-block"
                    }
                    name="userId"
                    id="event-category"
                    type="select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.userId || ""}
                    >
                    <option value=""></option>
                    {techniciansQuery &&
                        techniciansQuery?.data?.map(
                        ({ firstname, lastname, userId }, index) => (
                            <option key={index} value={userId}>
                            {firstname} {lastname}
                            </option>
                        )
                        )}
                    </Input>
                    {validation.touched.userId && validation.errors.userId ? (
                    <FormFeedback type="invalid">
                        {validation.errors.userId}
                    </FormFeedback>
                    ) : null}
                </div>
                </Col>
                <Col lg={12}>
                <div className="mt-4 mt-lg-0">
                    <div className="form-check form-check-inline">
                    <Input
                        onChange={validation.getFieldProps("priority").onChange}
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="inlineRadio1"
                        value="Lowest"
                        checked={validation.values.priority === "Lowest"}
                    />
                    <Label className="form-check-label" htmlFor="inlineRadio1">
                        Lowest
                    </Label>
                    </div>
                    <div className="form-check form-check-inline">
                    <Input
                        onChange={validation.getFieldProps("priority").onChange}
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="inlineRadio2"
                        value="Low"
                        checked={validation.values.priority === "Low"}
                    />
                    <Label className="form-check-label" htmlFor="inlineRadio2">
                        Low
                    </Label>
                    </div>
                    <div className="form-check form-check-inline">
                    <Input
                        onChange={validation.getFieldProps("priority").onChange}
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="inlineRadio3"
                        value="Medium"
                        checked={validation.values.priority === "Medium"}
                    />
                    <Label className="form-check-label" htmlFor="inlineRadio3">
                        Medium
                    </Label>
                    </div>
                    <div className="form-check form-check-inline">
                    <Input
                        onChange={validation.getFieldProps("priority").onChange}
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="inlineRadio4"
                        value="High"
                        checked={validation.values.priority === "High"}
                    />
                    <Label className="form-check-label" htmlFor="inlineRadio4">
                        High
                    </Label>
                    </div>
                    <div className="form-check form-check-inline">
                    <Input
                        onChange={validation.getFieldProps("priority").onChange}
                        className="form-check-input"
                        type="radio"
                        name="priority"
                        id="inlineRadio5"
                        value="Highest"
                        checked={validation.values.priority === "Highest"}
                    />
                    <Label className="form-check-label" htmlFor="inlineRadio5">
                        Highest
                    </Label>
                    </div>
                </div>
                </Col>
            </Row>
            <div className="hstack gap-2 justify-content-end mt-2">
                {!!isEdit && (
                <button
                    type="button"
                    className="btn btn-soft-danger"
                    id="btn-delete-event"
                    onClick={() => setDeleteModal(true)}
                >
                    <i className="ri-close-line align-bottom"></i> Supprimer
                </button>
                )}
                {isEditButton && (
                <button
                    type="submit"
                    className="btn btn-success"
                    id="btn-save-event"
                >
                    {!!isEdit ? "Modifier" : "Ajouter"}
                </button>
                )}
            </div>
            </Form>
        </ModalBody>
        </Modal>
    );
};

export default AddEditForm;
