import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";
const api = new APIClient();

export const createProject = async (data) => {
    const response = await api.sendFormData(url.ADD_PROJECT, data.formData);

    if (response && data.attachedFiles.length>0) {
        for (const file of data.attachedFiles) {
            await api.sendFormData(url.ADD_FILE_TO_PROJECT(response.id), {'file': file.source});
        }
    }

    return response;
}

export const getProjects = async () => {
    const response = await api.get(url.GET_PROJECT(0), {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    return response;
}

export const getAllProjects = async () => {
    const response = await api.get(url.GET_ALL_PROJECTS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    return response.filter((item) => item.projectId != 0);
}

export const getProjectContent = async (id) => {
    const response = await api.get(url.GET_PROJECT(id));
    return response;
}


export const updateProject = async (data) => {
    const response = await api.putFormData(url.UPDATE_PROJECT(data.id), data.formData);

    if (response && data.attachedFiles) {
        data.attachedFiles.forEach(async (file) => {
            await api.sendFormData(url.ADD_FILE_TO_PROJECT(response.id), {'file': file});
        })
    }

    return response;
}

export const deleteProject = async (id) => {
    const response = await api.delete(url.DELETE_PROJECT(id));
    return response;
}

export const getProjectById = async (id) => {
    const response = await api.get(url.GET_PROJECT(id));
    let parentProject = null;
    if (response.actualProject.projectParentId !== 0) {
        parentProject = await api.get(url.GET_PROJECT(response.actualProject.projectParentId));
    }
    return {...response, parentProject: parentProject};
}

export const addTaskToProject = async (data) => {
    const response = await api.create(url.ADD_TASK_TO_PROJECT(data));
    return response;
}

export const addProjectFiles = async (data) => {
    for (const file of data.files) {
        await api.sendFormData(url.ADD_FILE_TO_PROJECT(data.id), {'file':file.source});
    }
}

export const getProjectFiles = async () => {
    const response = await api.get(url.GET_PROJECT_FILE(id));
    return response;
}

export const deleteProjectFiles = async (name) => {
    const response = await api.delete(url.DELETE_PROJECT_FILE(name));
    return response;
}