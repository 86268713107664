import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //Menu
    const [isDashboard, setIsDashboard] = useState(false);
    const [isProject, setIsProject] = useState(false);
    const [isPlanning, setIsPlanning] = useState(false);
    const [isDemande, setIsDemande] = useState(false);
    const [isEnterprise, setIsEnterprise] = useState(false);
    const [isTeam, setIsTeam] = useState(false);

    // General
    const [isProfile, setIsProfile] = useState(false);
    const [isResources, setIsResources] = useState(false);
    const [isSetting, setIsSetting] = useState(false);
    const [isSupport, setIsSupport] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isCustomerSpace, setIsCustomerSpace] = useState(false);
    const [isLicenseKey, setIsLicenseKey] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Projets') {
            setIsProject(false);
        }
        if (iscurrentState !== 'Planning') {
            setIsPlanning(false);
        }
        if (iscurrentState !== 'Demandes') {
            setIsDemande(false);
        }
        if (iscurrentState !== 'Entreprises') {
            setIsEnterprise(false);
        }
        if (iscurrentState !== 'Staff') {
            setIsTeam(false);
        }
        if (iscurrentState !== 'Profile') {
            setIsProfile(false);
        }
        if (iscurrentState !== 'Ressources') {
            setIsResources(false);
        }
        if (iscurrentState !== 'Espace Client') {
            setIsResources(false);
        }
        if (iscurrentState !== 'Parametres') {
            setIsSetting(false);
        }
        if (iscurrentState !== 'Clés de Licence') {
            setIsSetting(false);
        }
        if (iscurrentState !== 'Centre D\'aide') {
            setIsSupport(false);
        }
        if (iscurrentState !== 'Deconnexion') {
            setIsLogout(false);
        }
        // if (iscurrentState !== 'Landing') {
        //     setIsLanding(false);
        // }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isProject,
        isPlanning,
        isDemande,
        isEnterprise,
        isTeam,
        isProfile,
        isResources,
        isCustomerSpace,
        isSetting,
        isLicenseKey,
        isSupport,
        isLogout,
    ]);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
            isSA: false,
        },
        {
            id: "dashboard",
            label: "Tableau de bord",
            icon: "las la-tachometer-alt",
            link: "/dashboard",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Tableau de bord');
            }
        },
        {
            id: "projects",
            label: "Nos Projets",
            icon: "las la-briefcase",
            link: "/projects",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Projets');
            }
        },
        {
            id: "planning",
            label: "Planification",
            icon: "lab la-trello",
            link: "/planning",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Planning');
            }
        },
        {
            id: "demandes",
            label: "Audits BPE",
            icon: "lab la-buffer",
            // icon: "las la-tasks",
            link: "/demandes",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Demandes');
            }
        },
        {
            id: "enterprise",
            label: "Entreprises",
            icon: "las la-handshake",
            link: "/enterprises",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Entreprises');
            }
        },
        {
            label: "General",
            isHeader: true,
            isSA: false,
        },
        {
            id: "teams",
            label: "Personnels",
            icon: "las la-users",
            link: "/teams",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Staff');
            }
        },
        {
            id: "resources",
            label: "Ressources",
            icon: "las la-photo-video",
            link: "/resources",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Ressources');
            }
        },
        {
            id: "customer-space",
            label: "Espace Client",
            icon: "las la-rocket",
            link: "/customer-space",
            isSA: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Espace Client');
            }
        },
        // {
        //     id: "license-key",
        //     label: "Clés de Licence",
        //     icon: "las la-key",
        //     link: "/license-key",
        //     isSA: true,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIscurrentState('Clés de Licence');
        //     }
        // },
        // {
        //     id: "settings",
        //     label: "Paramètres",
        //     icon: "las la-cog",
        //     link: "/settings",
        //     isSA: false,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIscurrentState('Paramètres');
        //     }
        // },
        // {
        //     id: "support",
        //     label: "Centre D'aide",
        //     icon: "las la-headset",
        //     link: "/support",
        //     isSA: false,
        //     click: function (e) {
        //         e.preventDefault();
        //         setIscurrentState("Centre D'aide");
        //     }
        // },
        {
            id: "logout",
            label: "Déconnexion",
            icon: "las la-sign-out-alt",
            link: "/logout",
            isSA: false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Deconnexion');
            }
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;