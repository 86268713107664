import './EasyCropImage.css';

import React, {useCallback, useEffect, useRef, useState} from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import { saveAs } from 'file-saver';
import {useMutation, useQuery, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {onLoad, updateAllBoxImages} from "../../../api/boxImages/boxImgAPI";
import {Col} from "reactstrap";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import {Progress, Row, Spin} from "antd";
import {getAuditById} from "../../../api/audits/auditsApi";
import {LoadingOutlined} from "@ant-design/icons";

const EasyCropImage = ({ item, content, isCassette }) => {
    const queryClient = useQueryClient();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [imageUpload, setImageUpload] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const {
        data
    } = useQuery(["img", item.img], () => onLoad(item.img));

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ORIGINAL_IMG_URL}/${item.img}`)
            .then((data) => data.blob())
            .then(blob => {
                // readFile(blob)
                const file = new File([blob], 'image', { type: blob.type})
                readFile(file)
            })
    }, [])

    const [file, setFile] = useState('')
    function readFile(input) {
        const fr = new FileReader()
        fr.readAsDataURL(input)
        fr.addEventListener('load', () => {
            const res = fr.result;
            setFile(res)
        })

    }


    // Updated mutation for all box Images
    const updateAllBoxImgMutation = useMutation(updateAllBoxImages, {
        onSuccess: (data) => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            toast.success("Image modifiée avec succès!");
        },
        onError: (error) => {
            // An error happened!
            toast.error(error);
        },
    });

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                `${process.env.REACT_APP_API_ORIGINAL_IMG_URL}/${item.img}`,
                croppedAreaPixels,
                rotation
            );
            // console.log("donee", { croppedImage });
            setCroppedImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation, item]);

    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, []);

    const downloadImage = () => {
        saveAs(`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`, `${item.name}`) // Put your image URL here.
    }

    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const updateImageCable = (e) => {
        if (e.target.files[0] && item) {
            setImageUpload(e.target.files[0]);
            const fileReader = new FileReader();
            fileReader.onload = () => {
                if (fileReader.readyState === 2) {
                    setImagePreview(fileReader.result);
                }
            };
            fileReader.readAsDataURL(e.target.files[0]);
            const data = {
                imageName: item.img,
                file: e.target.files[0]
            }
            updateAllBoxImgMutation.mutate(data)
        }
    }

    return (
        <Row>
            <Col xxl={12}>
                <div className="crop">
                    <div className="d-flex">
                        <button
                            className="crop-btn"
                            style={{
                                display: item.img === null || croppedImage !== null ? "none" : "block",
                            }}
                            onClick={showCroppedImage}
                        >
                            Plein Ecran
                        </button>
                        <button onClick={downloadImage} className="crop-btn">
                            <i className="ri-download-2-fill me-2 align-middle"></i>
                        </button>
                        <button onClick={() => handleClick(item.img)} className="crop-btn">
                            <i className="ri-camera-fill me-2 align-middle"></i>
                        </button>
                        <input
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={updateImageCable}
                            ref={hiddenFileInput}
                            style={{display: "none"}}
                        />
                    </div>
                    <div
                        className="container"
                        style={{
                            display: item.img === null || croppedImage !== null ? "none" : "block",
                        }}
                    >
                        <div className="crop-container">
                            {file ? (
                                <Cropper
                                    image={imagePreview
                                        ? imagePreview
                                        : file}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    zoomSpeed={4}
                                    maxZoom={3}
                                    zoomWithScroll={true}
                                    showGrid={true}
                                    aspect={4 / 3}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    onRotationChange={setRotation}
                                />
                            ) : (
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 24,
                                                }}
                                                spin
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {!croppedImage && (
                        <div className="controls" style={{marginTop: '-120px', width: '700px'}}>
                            <label>
                                <span className="text-white">
                                Pivoter
                                </span>
                                <Slider
                                    style={{color: 'yellow'}}
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby="rotate"
                                    onChange={(e, rotation) => setRotation(rotation)}
                                    className="range"
                                />
                            </label>
                            <label>
                                <span className="text-white">
                                Zoom
                                </span>
                                <Slider
                                    style={{color: 'red'}}
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                    className="range"
                                />
                            </label>
                        </div>
                    )}
                    {/*{isCassette && !croppedImage && (*/}
                    {/*    <div className="controls" style={{marginTop: '35px', width: '570px'}}>*/}
                    {/*        <label>*/}
                    {/*            Pivoter*/}
                    {/*            <Slider*/}
                    {/*                style={{color: 'green'}}*/}
                    {/*                value={rotation}*/}
                    {/*                min={0}*/}
                    {/*                max={360}*/}
                    {/*                step={1}*/}
                    {/*                aria-labelledby="rotate"*/}
                    {/*                onChange={(e, rotation) => setRotation(rotation)}*/}
                    {/*                className="range"*/}
                    {/*            />*/}
                    {/*        </label>*/}
                    {/*        <label>*/}
                    {/*            Zoom*/}
                    {/*            <Slider*/}
                    {/*                style={{color: 'red'}}*/}
                    {/*                value={zoom}*/}
                    {/*                min={1}*/}
                    {/*                max={3}*/}
                    {/*                step={0.1}*/}
                    {/*                aria-labelledby="zoom"*/}
                    {/*                onChange={(e, zoom) => setZoom(zoom)}*/}
                    {/*                className="range"*/}
                    {/*            />*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <div className="cropped-image-container">
                        {croppedImage && (
                            <img className="cropped-image" src={croppedImage} alt="cropped"/>
                        )}
                        {croppedImage && <button className="crop-btn" onClick={onClose}>Fermer</button>}
                    </div>
                </div>
            </Col>
            {/*<Col xxl={12}>*/}
            {/*    <div className="mt-xl-0 mt-3">*/}
            {/*        <div className="product-content">*/}
            {/*            <div className="controls ms-2">*/}
            {/*                <label>*/}
            {/*                    Pivoter*/}
            {/*                    <Slider*/}
            {/*                        style={{color: "green"}}*/}
            {/*                        value={rotation}*/}
            {/*                        min={0}*/}
            {/*                        max={360}*/}
            {/*                        step={1}*/}
            {/*                        aria-labelledby="rotate"*/}
            {/*                        onChange={(e, rotation) => setRotation(rotation)}*/}
            {/*                        className="range"*/}
            {/*                    />*/}
            {/*                </label>*/}
            {/*                <label>*/}
            {/*                    Zoom*/}
            {/*                    <Slider*/}
            {/*                        style={{color: "red"}}*/}
            {/*                        value={zoom}*/}
            {/*                        min={1}*/}
            {/*                        max={3}*/}
            {/*                        step={0.1}*/}
            {/*                        aria-labelledby="zoom"*/}
            {/*                        onChange={(e, zoom) => setZoom(zoom)}*/}
            {/*                        className="range"*/}
            {/*                    />*/}
            {/*                </label>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Col>*/}
        </Row>
    // <div className="d-flex">
    //     <Col xxl={isCassette ? 12 : 12}>
    //         <div className="crop">
    //             <div className="d-flex">
    //                 <button
    //                     className="crop-btn"
    //                     style={{
    //                         display: item.img === null || croppedImage !== null ? "none" : "block",
    //                     }}
    //                     onClick={showCroppedImage}
    //                 >
    //                     Plein Ecran
    //                 </button>
    //                 <button onClick={downloadImage} className="crop-btn">
    //                     <i className="ri-download-2-fill me-2 align-middle"></i>
    //                 </button>
    //                 <button onClick={() => handleClick(item.img)} className="crop-btn">
    //                     <i className="ri-camera-fill me-2 align-middle"></i>
    //                 </button>
    //                 <input
    //                     type="file"
    //                     accept="image/png, image/jpg, image/jpeg"
    //                     onChange={updateImageCable}
    //                     ref={hiddenFileInput}
    //                     style={{display: "none"}}
    //                 />
    //             </div>
    //             <div
    //                 className="container"
    //                 style={{
    //                     display: item.img === null || croppedImage !== null ? "none" : "block",
    //                 }}
    //             >
    //                 <div className="crop-container">
    //                     {file ? (
    //                         <Cropper
    //                             image={imagePreview
    //                                 ? imagePreview
    //                                 : file}
    //                             crop={crop}
    //                             rotation={rotation}
    //                             zoom={zoom}
    //                             zoomSpeed={4}
    //                             maxZoom={3}
    //                             zoomWithScroll={true}
    //                             showGrid={true}
    //                             aspect={4 / 3}
    //                             onCropChange={setCrop}
    //                             onCropComplete={onCropComplete}
    //                             onZoomChange={setZoom}
    //                             onRotationChange={setRotation}
    //                         />
    //                     ) : (
    //                         <div className="d-flex justify-content-center align-items-center mt-4">
    //                             <Spin
    //                                 indicator={
    //                                     <LoadingOutlined
    //                                         style={{
    //                                             fontSize: 24,
    //                                         }}
    //                                         spin
    //                                     />
    //                                 }
    //                             />
    //                         </div>
    //                     )}
    //                 </div>
    //             </div>
    //             {isCassette && !croppedImage && (
    //                 <div className="controls" style={{marginTop: '35px', width: '570px'}}>
    //                     <label>
    //                         Pivoter
    //                         <Slider
    //                             style={{color: 'green'}}
    //                             value={rotation}
    //                             min={0}
    //                             max={360}
    //                             step={1}
    //                             aria-labelledby="rotate"
    //                             onChange={(e, rotation) => setRotation(rotation)}
    //                             className="range"
    //                         />
    //                     </label>
    //                     <label>
    //                         Zoom
    //                         <Slider
    //                             style={{color: 'red'}}
    //                             value={zoom}
    //                             min={1}
    //                             max={3}
    //                             step={0.1}
    //                             aria-labelledby="zoom"
    //                             onChange={(e, zoom) => setZoom(zoom)}
    //                             className="range"
    //                         />
    //                     </label>
    //                 </div>
    //             )}
    //             <div className="cropped-image-container">
    //                 {croppedImage && (
    //                     <img className="cropped-image" src={croppedImage} alt="cropped"/>
    //                 )}
    //                     {croppedImage && <button className="crop-btn" onClick={onClose}>Fermer</button>}
    //                 </div>
    //             </div>
    //         </Col>
    //         {!isCassette && (
    //             <Col xxl={4}>
    //                 <div className="mt-xl-0 mt-3">
    //                     <div className="product-content">
    //                         {content}
    //                         <div className="controls ms-2">
    //                             <label>
    //                                 Pivoter
    //                                 <Slider
    //                                     style={{color: "green"}}
    //                                     value={rotation}
    //                                     min={0}
    //                                     max={360}
    //                                     step={1}
    //                                     aria-labelledby="rotate"
    //                                     onChange={(e, rotation) => setRotation(rotation)}
    //                                     className="range"
    //                                 />
    //                             </label>
    //                             <label>
    //                                 Zoom
    //                                 <Slider
    //                                     style={{color: "red"}}
    //                                     value={zoom}
    //                                     min={1}
    //                                     max={3}
    //                                     step={0.1}
    //                                     aria-labelledby="zoom"
    //                                     onChange={(e, zoom) => setZoom(zoom)}
    //                                     className="range"
    //                                 />
    //                             </label>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </Col>
    //         )}
    //
    //     </div>
    );
};

export default EasyCropImage;