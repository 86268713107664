import * as moment from "moment";
import 'moment/locale/fr';

export const handleValidDate = (date) => {
  moment.locale('fr');
  const date1 = moment(new Date(date)).format("LLL");
  return date1;
};

export const handleValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("HH:mm");
  
  return updateTime;
};
