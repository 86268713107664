import React, { useState } from 'react';
import { Drawer} from 'antd';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getMembers} from "../../../../api/users/usersApi";
import {useDispatch} from "react-redux";
import {Link, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {assignAuditToTechnician, removeAssignation} from "../../../../api/audits/auditsApi";
import SimpleBar from "simplebar-react";
import {UserRoleFormat} from "../../../../helpers/role_format";
import avatar from "../../../../assets/images/users/avatar-user.png";


const AddTechnicianDrawer = ({open, setOpen, audit}) => {
    const {data: technicians, isError, error, isFetched: isFetchedTechnician} = useQuery("technicians", getMembers, {
        //select: (data) => data.filter((item) => item.userRole === 'ROLE_MOBILE_USER')
    });

    // console.log(technicians)
    // const addSpaceMutation = useMutation(addSpace, {
    //
    // })
    const onClose = () => {
        setOpen(false);
    };


    // ===============================================
    const [searchFilter, setSearchFilter] = useState('');
    const param = useParams();

    const assignUserToAuditMutation = useMutation(assignAuditToTechnician, {
        // onSuccess: () => {
        //     // Invalidates cache and refetch data
        //     queryClient.invalidateQueries("audit");
        //     toast.success("Technicien ajouté avec succès!");
        // },
        // onError: (error) => {
        //     // toast.error(error);
        // },
    });

    const removeUserToAuditMutation = useMutation(removeAssignation, {
        // onSuccess: () => {
        //     // Invalidates cache and refetch data
        //     queryClient.invalidateQueries("audit");
        //     toast.success("Technicien supprimé avec succès!");
        // },
        // onError: (error) => {
        //     toast.error(error);
        // },
    });

    const handleFilter = (e) => {
        setSearchFilter(e.target.value);
    };

    const filteredData = technicians?.filter(
        (item) =>
            item.firstname.toLowerCase().includes(searchFilter.toLowerCase()) ||
            item.lastname.toLowerCase().includes(searchFilter.toLowerCase())
    );

    const handleAssignMember = (item) => {
        // const data = {
        //     userId: item.userId,
        //     assignedUser: props?.auditItem?.assignedToTechnicianId,
        //     auditId: param.id
        // }
        //
        // assignUserToAuditMutation.mutate(data);
    }

    const handleRemoveMember = (item) => {
        // const data = {
        //     userId: item.userId,
        //     auditTaskId: param.id
        // }
        // removeUserToAuditMutation.mutate(data)
    }
    // ===============================================

    return (
        <>
            <Drawer
                zIndex={9999}
                title="Affecter à un technicien"
                width={320}
                onClose={onClose}
                open={open}
                maskClosable={false}
                styles={{
                    // body: {
                    //     paddingBottom: 80,
                    // },
                }}
            >
                <div className="search-box mb-3">
                    <input
                        type="text"
                        className="form-control bg-light border-light"
                        placeholder="Recherche par nom..."
                        value={searchFilter}
                        onChange={handleFilter}
                    />
                    <i className="ri-search-line search-icon"></i>
                </div>
                <SimpleBar style={{maxHeight: "610px"}} className="p-3 pt-0">
                    {filteredData && filteredData.filter((user) => user.userRole === "ROLE_MOBILE_USER").map((item, i) => {
                        return [
                            <div className="d-flex align-items-center my-3" key={i}>
                                <div className="avatar-xxs flex-shrink-0 me-3">
                                    <img
                                        src={avatar}
                                        alt=""
                                        className="img-fluid rounded-circle"
                                    />
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="fs-12 mb-0">
                                        <Link to="#" className="text-body d-block">
                                            {item?.firstname} {item?.lastname}
                                        </Link>
                                    </h5>
                                    <span className="text-muted fs-11">
                            {UserRoleFormat(item?.userRole)}
                          </span>
                                </div>
                                <div className="flex-shrink-0">
                                    {audit?.assignedToTechnicianId === item?.userId
                                        ? (
                                            <button
                                                onClick={() => handleRemoveMember(item)}
                                                type="button"
                                                className="btn btn-sm btn-danger"
                                            >
                                                Supprimer
                                            </button>
                                        )
                                        : (
                                            <button
                                                onClick={() => handleAssignMember(item)}
                                                type="button"
                                                className="btn btn-sm btn-light"
                                            >
                                                Assigner
                                            </button>
                                        )
                                    }
                                </div>
                            </div>,
                        ];
                    })}
                </SimpleBar>
            </Drawer>
        </>
    );
};
export default AddTechnicianDrawer;