import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { UserRoleFormat } from "../../../helpers/role_format";
import dummyImg from "../../../assets/images/users/avatar-user.png";
import { Link } from "react-router-dom";
import {useQuery} from "react-query";
import {getMemberById} from "../../../api/users/usersApi";
const MemberCard = ({ userId, taskId, modal_backdrop, setmodal_backdrop, onClickDelete }) => {
  const {
    isLoading,
    isError,
    error,
    data: userCard,
  } = useQuery(["userCard", userId], () => userId && getMemberById(userId));
  const [user, setUser] = useState();

  useEffect(() => {
    if (userCard) {
      setUser(userCard);
    }
  }, [userCard]);

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader className="d-flex justify-content-end">
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_backdrop(false);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="">
          <div id="contact-view-detail">
            <div className="text-center">
              <div className="position-relative d-inline-block">
                <img
                  src={`${
                    user?.userImageUrl
                      ? process.env.REACT_APP_API_IMG_URL +
                        "/" + user?.userImgUrl
                      : dummyImg
                  }`}
                  alt=""
                  className="avatar-lg rounded-circle img-thumbnail"
                />
                <span className="contact-active position-absolute rounded-circle bg-success">
                  <span className="visually-hidden"></span>
                </span>
              </div>
              <h5 className="mt-4 mb-1">
                {user?.firstname} {user?.lastname}
              </h5>
              <p className="text-muted">{UserRoleFormat(user?.userRole)}</p>
            </div>
            <div className="table-responsive">
              <Table className="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <th className="fw-medium" scope="row">Nom Utilisateur:</th>
                    <td>{user?.username}</td>
                  </tr>
                  <tr>
                    <th className="fw-medium" scope="row">Adresse Email:</th>
                    <td>{user?.email}</td>
                  </tr>
                  <tr>
                    <th className="fw-medium" scope="row">Nº Téléphone:</th>
                    <td>{user?.mobileNumber}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="mt-4">
            <div className="hstack gap-2 justify-content-center">
              <Link
                to={`/profile/${user?.userId}`}
                className="btn btn-link link-success fw-medium"
              >
                <i className="ri-eye-line me-1 align-middle"></i> Profil
              </Link>
              <Link
                onClick={() => {
                    onClickDelete({userId, taskId})
                    setmodal_backdrop(false)
                }}
                className="btn btn-danger"
              >
                Supprimer
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
    // <Modal
    //   isOpen={modal_backdrop}
    //   toggle={() => {
    //     tog_backdrop();
    //   }}
    //   backdrop={"static"}
    //   id="staticBackdrop"
    //   centered
    // >
    //   <ModalHeader>
    //     <h5 className="modal-title" id="staticBackdropLabel">
    //       Modal title
    //     </h5>
    //     <Button
    //       type="button"
    //       className="btn-close"
    //       onClick={() => {
    //         setmodal_backdrop(false);
    //       }}
    //       aria-label="Close"
    //     ></Button>
    //   </ModalHeader>
    //   <ModalBody className="text-center p-5">
    //     <Card id="contact-view-detail">
    //       <CardBody className="text-center">
    //         <div className="d-flex justify-content-end">
    //           <button onClick={handleCloseView} className="btn btn-outline">
    //             <i className="ri-close-line"></i>
    //           </button>
    //         </div>
    //         <div className="position-relative d-inline-block">
    //           <img
    //             src={`${
    //               info.userImgUrl
    //                 ? process.env.REACT_APP_API_URL +
    //                   "/data-file/print/" +
    //                   info.userImgUrl
    //                 : dummyImg
    //             }`}
    //             alt=""
    //             className="avatar-lg rounded-circle img-thumbnail"
    //           />
    //           <span className="contact-active position-absolute rounded-circle bg-success">
    //             <span className="visually-hidden"></span>
    //           </span>
    //         </div>
    //         <h5 className="mt-4 mb-1">
    //           {info.firstname || "XXX"} {info.lastname || "XXX"}
    //         </h5>
    //         <p className="text-muted">
    //           {UserRoleFormat(info.userRole) || "XXX"}
    //         </p>

    //         <ul className="list-inline mb-0">
    //           <li className="list-inline-item avatar-xs">
    //             <Link
    //               to="#"
    //               className="avatar-title bg-success-subtle text-success fs-15 rounded"
    //             >
    //               <i className="ri-phone-line"></i>
    //             </Link>
    //           </li>
    //           <li className="list-inline-item avatar-xs">
    //             <Link
    //               to="#"
    //               className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
    //             >
    //               <i className="ri-mail-line"></i>
    //             </Link>
    //           </li>
    //           <li className="list-inline-item avatar-xs">
    //             <Link
    //               to={`/profile/${info.userId}`}
    //               className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
    //             >
    //               <i className="ri-eye-line"></i>
    //             </Link>
    //           </li>
    //         </ul>
    //       </CardBody>
    //       <CardBody>
    //         <h6 className="text-muted text-uppercase fw-semibold mb-3">
    //           Informations Personnelles
    //         </h6>
    //         <div className="table-responsive table-card">
    //           <Table className="table table-borderless mb-0">
    //             <tbody>
    //               <tr>
    //                 <td className="fw-medium">Nom Utilisateur</td>
    //                 <td>{info.username || "@username"}</td>
    //               </tr>
    //               <tr>
    //                 <td className="fw-medium">Email</td>
    //                 <td>{info.email || "example@mail.com"}</td>
    //               </tr>
    //               <tr>
    //                 <td className="fw-medium">Téléphone</td>
    //                 <td>{info.phone || "000-0000-0000"}</td>
    //               </tr>
    //               <tr>
    //                 <td className="fw-medium">Nombre d'Audit</td>
    //                 <td>
    //                   <span className="badge bg-primary-subtle text-primary me-1">
    //                     {info.auditTasks?.length || 0}
    //                   </span>
    //                 </td>
    //               </tr>
    //               <tr>
    //                 <td className="fw-medium">Compte</td>
    //                 <td>
    //                   {info.enabled ? (
    //                     <span className="badge bg-primary-subtle text-success me-1">
    //                       Active
    //                     </span>
    //                   ) : (
    //                     <span className="badge bg-primary-subtle text-danger me-1">
    //                       Desactive
    //                     </span>
    //                   )}
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </Table>
    //         </div>
    //       </CardBody>
    //     </Card>
    //     <div className="mt-4">
    //       <h4 className="mb-3">You've made it!</h4>
    //       <p className="text-muted mb-4">
    //         {" "}
    //         The transfer was not successfully received by us. the email of the
    //         recipient wasn't correct.
    //       </p>
    //       <div className="hstack gap-2 justify-content-center">
    //         <Link
    //           to="#"
    //           className="btn btn-link link-success fw-medium"
    //           onClick={() => setmodal_backdrop(false)}
    //         >
    //           <i className="ri-close-line me-1 align-middle"></i> Close
    //         </Link>
    //         <Link to="#" className="btn btn-success">
    //           Completed
    //         </Link>
    //       </div>
    //     </div>
    //   </ModalBody>
    // </Modal>
  );
};

export default MemberCard;
