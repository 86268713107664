import {Card, CardBody, Col} from "reactstrap";
import companyImg from "../../../assets/images/companies/company-logo.png";
import {Link} from "react-router-dom";
import React from "react";

const CustomerOverview = ({info, setInfo}) => {
    return (
        <Col xxl={3}>
            <Card id="company-view-detail">
                <CardBody className="text-center">
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={() => setInfo(null)}
                            className="btn btn-outline"
                        >
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                    <div className="position-relative d-inline-block">
                        <div className="avatar-md">
                            <div className="avatar-title bg-light rounded-circle">
                                <img
                                    src={`${
                                        info?.clientImageUrl
                                            ? process.env.REACT_APP_API_URL +
                                            "/data-file/" +
                                            info.clientImageUrl
                                            : companyImg
                                    }`}
                                    alt=""
                                    className="avatar-sm rounded-circle object-fit-cover"
                                />
                            </div>
                        </div>
                    </div>
                    <h5 className="mt-3 mb-1">
                        {info?.name || "Entreprise introuvable!"}
                    </h5>
                    {/* <p className="text-muted">{info.owner || "Michael Morris"}</p> */}

                    <ul className="list-inline mb-0">
                        <li className="list-inline-item avatar-xs">
                            <Link
                                to="#"
                                className="avatar-title bg-success-subtle text-success fs-15 rounded"
                            >
                                <i className="ri-global-line"></i>
                            </Link>
                        </li>{" "}
                        <li className="list-inline-item avatar-xs">
                            <Link
                                to={`mailto:${info?.email}`}
                                className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                            >
                                <i className="ri-mail-line"></i>
                            </Link>
                        </li>{" "}
                        <li className="list-inline-item avatar-xs">
                            <Link
                                to={`mailto:${info?.phone}`}
                                className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                            >
                                <i className="ri-phone-line"></i>
                            </Link>
                        </li>
                    </ul>
                </CardBody>
                <div className="card-body">
                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                        Information
                    </h6>
                    <p className="text-muted mb-4">
                        A propos de {info?.name}...
                    </p>
                    <div className="table-responsive table-card">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <tr>
                                <td className="fw-medium">Site web</td>
                                <td>
                                    <Link
                                        to="#"
                                        className="link-primary text-decoration-underline"
                                    >
                                        {info?.website || "Site web Introuvale!"}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-medium">Adresse</td>
                                <td>{info?.address || "Adresse Introuvale!"}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">Email</td>
                                <td>{info?.email || "Email Introuvale!"}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">Téléphone</td>
                                <td>{info?.phone || "Téléphone Introuvale!"}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">Fax</td>
                                <td>{info?.fax || "Fax Introuvale!"}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Card>
        </Col>
    )
}

export  default CustomerOverview;