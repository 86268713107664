import React from "react";
import { Link, NavLink } from "react-router-dom";
import {Card, CardBody, Col, Row} from "reactstrap";

const TaskDescription = ({audit}) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h5 className="mb-3">Description</h5>

                    <p className="text-muted mb-4">
                        {audit?.description || 'Aucune description trouvée...'}
                    </p>
                    <div>

                        <Row className="mb-4">
                            <Col lg={6}>
                                <h5 className="mb-3">Informations de la Boîte</h5>
                                <ul className="text-muted vstack gap-2">
                                    <li>
                                        Référence Boîte : {audit?.box?.boxReference}
                                    </li>
                                    <li>
                                        Nombre de Câble : {audit?.box?.cablesNumber}
                                    </li>
                                    <li>Nombre de Cassette : {audit?.box?.cassetteNumber}</li>
                                    <li>
                                        Type de Boîte : {audit?.box?.boxType?.type}
                                    </li>
                                    <li>
                                        Environnement : {audit?.place?.type}
                                    </li>
                                    <li>Adresse : {audit?.place?.address}</li>
                                    <li>
                                        Coordonnées : {" "}
                                            <span className="text-body fw-medium">
                                              {audit?.place?.location?.latitude && (
                                                  <Link
                                                      target="_blank"
                                                      to={`https://maps.google.com/maps?q=${audit?.place?.location?.latitude},${audit?.place?.location?.longitude}&z=10`}
                                                  >
                                                      lat = {audit?.place?.location?.latitude} | lon ={" "}
                                                      {audit?.place?.location?.longitude}
                                                  </Link>
                                              )}
                                            </span>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg={6}>
                                <h5 className="mb-3">Contact</h5>
                                <ul className="text-muted vstack gap-2">
                                    <li>
                                        Nom : {audit?.contact?.lastname || ''}
                                    </li>
                                    <li>
                                        Prénom : {audit?.contact?.firstname || ''}
                                    </li>
                                    <li>
                                        Email : {audit?.contact?.email || ''}
                                    </li>
                                    <li>Téléphone : {audit?.contact?.phone || ''}</li>
                                </ul>
                            </Col>
                        </Row>

                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default TaskDescription;
