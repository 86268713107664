import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  getProject,
  deleteProjectList as onDeleteProjectList,
} from "../../../../slices/thunks";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useCallback, useState } from "react";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { deleteProject } from "../../../../api/projects/projectsApi";
import AddEditFormProject from "../../components/addEditFormProject";
import {Button, Empty} from "antd";

const SubProjectTab = ({ subProjects, setProject }) => {
  const queryClient = useQueryClient();
    const deleteProjectMutation = useMutation(deleteProject, {
        onSuccess: () => {
        // Invalidates cache and refetch data
        queryClient.invalidateQueries("projects");
        toast.success("Le sous projet a été supprimé avec succès");
        },
        onError: (error) => {
        // An error happened!
        toast.error(error);
        },
    });


  const [subProjectItem, setSubProjectItem] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const taskbarwidth = (item) => {
    if (item.totalTaskCount != 0) {
      return (item.completedTaskCount * 100) / item.totalTaskCount;
    }
    return 0;
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setSubProjectItem(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleClickSubProject = useCallback(
    (arg) => {
      const subProjectItem = arg;
      setSubProjectItem({
        projectId: subProjectItem.projectId,
        projectName: subProjectItem.projectName,
        projectDescription: subProjectItem.projectDescription,
        deadline: subProjectItem.deadline,
        status: subProjectItem.status,
        projectImgUrl: subProjectItem.projectImgUrl
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const onClickData = (project) => {
    setSubProjectItem(project);
    setDeleteModal(true);
  };

  const handleDeleteSubProjectData = () => {
    if (subProjectItem) {
      deleteProjectMutation.mutate(subProjectItem.projectId)
      setDeleteModal(false);
    }
  };

  if (subProjects && !subProjects?.length>0) {
      return (
          <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                  height: 150,
              }}
              description={
                  <span>
                Aucun sous projet trouvé!
              </span>
              }
          >
              {/*<Button*/}
              {/*    type="primary">Créer Maintenant*/}
              {/*</Button>*/}
          </Empty>
      )
  }

  return (
    <React.Fragment>
      <DeleteModal
        item={subProjectItem?.projectName}
        show={deleteModal}
        onDeleteClick={() => handleDeleteSubProjectData()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Row>
        {(subProjects || []).map((item, key) => (
          <Col lg={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex gap-1 align-items-center justify-content-end my-n2">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      style={{ position: "relative", zIndex: "1000 !important" }}
                      tag="button"
                      className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                    >
                      <FeatherIcon icon="more-horizontal" className="icon-sm" />
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <Link to={`/projects/overview/${item.projectId}`}>
                          <DropdownItem>
                              <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                              Ouvrir
                          </DropdownItem>
                      </Link>
                      <Link onClick={() => handleClickSubProject(item)}>
                        <DropdownItem>
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          Modifier
                        </DropdownItem>
                      </Link>
                      <DropdownItem divider />
                      <Link onClick={() => onClickData(item)}>
                        <DropdownItem>
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                          Supprimer
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <h5 className="fs-16 fw-semibold">{item.projectName}</h5>
                <p className="text-muted">{item.projectDescription || '...'}</p>
                <div className="d-flex flex-wrap justify-content-around">
                  <p className="text-muted mb-0">
                    <i
                      className={`fs-18 align-middle mdi mdi-numeric-${item.totalTaskCount}-circle text-danger`}
                    ></i>
                    <span className="mt-1">Tâche(s) Totale(s)</span>
                  </p>
                  <p className="text-muted mb-0">
                    <i
                      className={`fs-18 align-middle mdi mdi-numeric-${item.completedTaskCount}-circle text-success`}
                    ></i>
                    <span className="mt-1">Tâche(s) Complète(s)</span>
                  </p>
                </div>
              </CardBody>
              <div
                className="progress animated-progess rounded-bottom rounded-0"
                style={{ height: "6px" }}
              >
                <div
                  className={"progress-bar rounded-0 bg-danger"}
                  role="progressbar"
                  style={{ width: `${taskbarwidth(item)}%` }}
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  // key={index}
                ></div>
                <div
                  className={"progress-bar rounded-0 bg-success"}
                  role="progressbar"
                  style={{ width: `${100 - taskbarwidth(item)}%` }}
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  // key={index}
                ></div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <AddEditFormProject 
        projectItem={subProjectItem}
        setProject={setProject} 
        modal={modal} 
        setModal={setModal}
        isEdit={isEdit} 
        toggle={toggle} 
      />
    </React.Fragment>
  );
};

export default SubProjectTab;
