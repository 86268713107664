import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";
const api = new APIClient();

export const createClient = async (data) => {
    const response =  await api.create(url.ADD_ENTERPRISE, data);
    if (response && data.taskId) {
        await api.create(url.ASSIGN_DEMANDE_TO_ENTERPRISE({auditTaskId: data.taskId,clientId: response.id}))
    }
    return response;
}

export const getClients = async () => {
    const response = await api.get(url.GET_ENTERPRISES, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    return response;
}

export const updateClient = async (data) => {
    const response = await api.put(`${url.UPDATE_ENTERPRISE}/${data.clientId}`, data.formData);
    return response;
}

export const deleteClient = async (id) => {
    const response = await api.delete(url.DELETE_ENTERPRISE+'/'+id);
    return {id, ...response.data};
}

export const getClientById = async (id) => {
    const response = await api.get(url.GET_ENTERPRISES+'/'+id);
    return response;
}
