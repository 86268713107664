import {Card, CardBody} from "reactstrap";
import MyCarousel from "../../../Components/Common/MyCaroussel";
import React from "react";

const BoxImageSection = ({content}) => {
    return (
        <Card>
            <CardBody>
                <MyCarousel content={content}/>
            </CardBody>
        </Card>
    )
}

export default BoxImageSection;