import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import React, {useContext, useState} from "react";
import classnames from "classnames";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addBoxImgType, deleteBoxImgType, getBoxTypeImgs } from "../../api/boxImages/boxImgAPI";
import AttachedFileComponent from "../../Components/Common/AttachedFileComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import {Carousel} from "react-responsive-carousel";
import EasyCropImage from "../../Components/Common/CropImage/EasyCropImage";
import TableEditable from "../audits/overview/cables/TableEditable";
import {Empty, Result, Spin} from "antd";
import HistoryContext from "../../Components/Hooks/HistoryContext";
const Resources = () => {
  const history = useContext(HistoryContext)
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    error,
    data: boxImg,
  } = useQuery("boxImages", getBoxTypeImgs);

  SwiperCore.use([Autoplay]);

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const [modalImage, setModalImage] = useState(false);
  const [item, setItem] = useState(null);
  function handleOpenModal(value) {
    if (value) setItem({img: value, name: value})
    setModalImage(!modalImage);
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  //OffCanvas
  const [isOpen, setIsOpen] = useState(false);

  const toggleOffCanvas = () => {
    setIsOpen(!isOpen);
  };

  //Dropdown
  const [selectedFiles, setSelectedFiles] = useState([]);

  const toggledropDown = () => {
    // setDropdownOpen(!dropdownOpen);
    setaddfilemodal(true);
  };

  const [addfilemodal, setaddfilemodal] = useState(false);
  const handleCloseModal = () => {
    setaddfilemodal(false);
  };

  const addBoxImgMutation = useMutation(addBoxImgType, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("boxImages");
      toast.success("Nouveau type de boite ajouté avec succès");
    },
    onError: (error) => {
      // An error happened!
      toast.error(error);
    },
  });

  const deleteBoxImgMutation = useMutation(deleteBoxImgType, {
    onSuccess: () => {
      queryClient.invalidateQueries("boxImages");
      toast.success("Type de Boite supprimé avec succès");
    }
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      imageName: "",
    },
    validationSchema: Yup.object({
      imageName: Yup.string(),
    }),
    onSubmit: (values) => {
      const { imageName } = values;
      const formData = new FormData();
      formData.append("imageName", imageName);
      formData.append("image", selectedFiles[0]);
      formData.append("company", JSON.parse(sessionStorage.getItem("authUser"))?.company)

      addBoxImgMutation.mutate(formData);
      validation.resetForm();
    },
  });

  const downloadImage = (img_name) => {
    saveAs(`${process.env.REACT_APP_API_IMG_URL}/${img_name}`, `${img_name}`); // Put your image URL here.
  };

  if (isLoading) {
    return <Spin spinning={isLoading} fullscreen />
  }

  if (isError) {
    return (
        <Result
            status="warning"
            title={`${error.message}}`}
            extra={
              <Button type="primary" key="console" onClick={() => window.location.reload()}>
                Refresh
              </Button>
            }
        />
    );
  }

  // if (boxImg?.boxTypesImageNames && !boxImg?.boxTypesImageNames?.length>0) {
  //   return (
  //       <Empty
  //           image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
  //           imageStyle={{
  //             height: 150,
  //           }}
  //           description={
  //             <span>
  //                 Aucune image type de boîte n'est trouvée!
  //             </span>
  //           }
  //       >
  //         <Button
  //             onClick={toggledropDown}
  //             type="primary">Ajouter un type de boîte Maintenant
  //         </Button>
  //       </Empty>
  //   )
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
              page="Type de Boite"
              history={history}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <Row className="justify-content-center mb-4">
                  </Row>
                </CardHeader>
                <div>
                  <Nav className="nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-image-fill text-muted align-bottom me-1"></i>{" "}
                        Images Type de Boîte
                      </NavLink>
                    </NavItem>
                    <li className="nav-item ms-auto me-3">
                      <Button
                          color="primary"
                          className="btn btn-primary"
                          outline
                          onClick={toggledropDown}
                      >
                        {/*<i className="ri-add-fill fs-15"/>{" "}*/}
                        Nouveau type de boîte
                      </Button>
                    </li>
                  </Nav>
                </div>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                      <div className="gallery-light">
                        <Row>
                          {(boxImg?.boxTypesImageNames || [])
                              .filter((img) => img.split("_").at(0) !== "tmp")
                              .map((item, key) => (
                                  <Col xl={2} lg={4} sm={6} key={key}>
                                    <Card className="gallery-box">
                                      <div className="gallery-container">
                                        <Link
                                            onClick={() => handleOpenModal(item)}
                                            className="image-popup"
                                            title=""
                                            to=""
                                        >
                                          <img
                                              className="gallery-img img-fluid mx-auto"
                                              src={`${process.env.REACT_APP_API_IMG_URL}/${item}`}
                                              alt=""
                                              style={{width:'100%', height:'200px', objectFit: 'cover'}}
                                          />
                                        </Link>
                                      </div>
                                      <div className="box-content">
                                        <div className="d-flex align-items-center mt-2">
                                          <div className="flex-grow-1 text-muted">
                                            {item.toUpperCase().split("--").at(2) || item.toUpperCase()}
                                          </div>
                                          <div className="flex-shrink-0">
                                            <div className="d-flex gap-3">
                                              <button
                                                  onClick={() => deleteBoxImgMutation.mutate(item)}
                                                  type="button"
                                                  className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                              >
                                                <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>{" "}
                                              </button>
                                              <button
                                                  onClick={() => downloadImage(item)}
                                                  type="button"
                                                  className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                              >
                                                <i className="ri-download-fill text-muted align-bottom me-1"></i>{" "}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  </Col>
                              ))}
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/*Modal add image type*/}
      <Modal
        isOpen={addfilemodal}
        centered={true}
        toggle={handleCloseModal}
        className={"className"}
        size="md"
        backdrop={"static"}
      >
        <ModalHeader
            toggle={handleCloseModal}
            tag="h5"
            className="p-3 bg-info-subtle modal-title"
        >
          
        </ModalHeader>
        <ModalBody className={"className"} size="lg">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={12}>
                <AttachedFileComponent
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <Label for="boxtypename" className="form-label">
                    Type de Boîte
                  </Label>
                  <Input
                    name="imageName"
                    type="text"
                    className="form-control"
                    id="boxtypename"
                    placeholder="Entrez le nom du type de boite"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.imageName || ""}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    <i className="ri-save-3-line align-bottom me-1"></i>
                    Enregistrer
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      {/*Modal Image Crop*/}
      <Modal
          id="modalImageFullscreenXl"
          className="modal-xl"
          isOpen={modalImage}
          toggle={() => {
            handleOpenModal();
          }}
          backdrop={"static"}
      >
        <ModalHeader
            className="modal-title"
            id="modalImageFullscreenXl"
            toggle={() => {
              handleOpenModal();
            }}
        ></ModalHeader>
        <ModalBody>
          <EasyCropImage
              item={item}
              isCassette={false}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Resources;
