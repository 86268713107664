import {useRef, useState} from "react";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import {useMutation} from "react-query";
import {createMultiTasks} from "../../../../api/audits/auditsApi";
import {message} from "../../../../Components/constants/message";
import {FileUploader} from "../../../../Components/Common/FileUpload/FileUploader";

const AddMultiTask = ({queryClient}) => {
    const [event, setEvent] = useState(null)
    const addMultiTasksMutation = useMutation(createMultiTasks, {
        onSuccess: (data) => {
            // Invalidates cache and refetch data
            //queryClient.invalidateQueries("audits");
            // if (data.registeredUsersCount === data.totalUsersCount) {
            //     toast.success("Utilisateurs ajoutés avec succès!");
            // } else {
            //     toast.error(Object.keys(data.unregisteredUsers).join('; \n** '));
            // }
            if (Object.keys(data.unregisteredTasks).length > 0) {
                // data.unregisteredTasks.map((msg) => )
                // for (let index = 0; index < Object.keys(data.unregisteredTasks).length; index++) {
                //   console.log(Object.keys(data.unregisteredTasks))
                toast.error(Object.keys(data.unregisteredTasks).join('; \n** '));
                event.target.value = null;
                // }
            } else {
                toast.success(message.AUDIT_CREATED_SUCCESSFULLY);
                event.target.value = null;
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries("audits");
            event.target.value = null;
        },
        onError: (error) => {
            toast.error(error)
        }
    });

    const fileType=['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFileUpload = (e) => {
        if (e.target.files[0]) {
            setEvent(e)
            if (e.target.files[0] && fileType.includes(e.target.files[0].type)) {
                const reader = new FileReader();
                reader.readAsBinaryString(e.target.files[0]);
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const parsedData = XLSX.utils.sheet_to_json(sheet)

                    const company = JSON.parse(sessionStorage.getItem("authUser"))?.company

                    addMultiTasksMutation.mutate(parsedData.map((item) => ({
                        name: item?.Taches || "",
                        description: item?.description || "",
                        address: item?.Adresse || "",
                        priority: item?.Priorite || "",
                        dueDate: new Date(Math.round((item?.Date_Intervention - 25569) * 864e5)) || "",
                        endDate: new Date(Math.round((item?.Date_limite - 25569) * 864e5)) || "",
                        projectName: item?.Projet || "",
                        interlocFirstname: item?.Prenom_contact || "",
                        interlocLastname: item?.Nom_contact || "",
                        interlocEmail: item?.Email_contact || "",
                        interlocPhone: item?.Telephone_contact || "",
                        userName: item?.Username_Technicien || "",
                        userEmail: item?.Email_Technicien || "",
                        company: company && company
                    })));
                }
            } else {
                toast.error("Format de fichier non valide !")
            }
        }
    };

    return <FileUploader handleFileUpload={handleFileUpload}/>
}

export default AddMultiTask;