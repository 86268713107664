export const UserRoleFormat = (role) => {
    switch (role) {
        case 'ROLE_MOBILE_USER':
            return 'Technicien';
        case 'ROLE_BACK_OFFICE_MANAGER':
            return 'Ingénieur';
        default:
            return 'Admin';
    }
}

export const ReverseUserRoleFormat = (role) => {
    switch (role) {
        case 'Technicien':
            return 'ROLE_MOBILE_USER';
        case 'Ingenieur':
            return 'ROLE_BACK_OFFICE_MANAGER';
        default:
            return 'ROLE_API_MANAGER';
    }
}