import React, {useContext, useState} from 'react';
import { Container } from 'reactstrap';
import AllTasks from './component/AllTasks';
import BreadCrumb from '../../Components/Common/BreadCrumb/BreadCrumb';
import HistoryContext from "../../Components/Hooks/HistoryContext";

const Audits = () => {
    const history = useContext(HistoryContext)
    document.title=`Tâches d'audit | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;

    return (
        <React.Fragment>
            <div className="page-content">           
                <Container fluid>
                    <BreadCrumb
                        page="Tâches"
                        history={history}
                    />
                    <AllTasks />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Audits;