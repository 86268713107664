import React, {useContext, useEffect, useState} from "react";
import TaskContext from "../../../../../hook/TaskContext";
import LineCassetteInput from "./LineCassetteInput";

const EditLinesCassetteDrawer = ({cassette}) => {
    const {cassettesLines, cables} = useContext(TaskContext);
    const [lines, setLines] = useState([]);
    const [isEdit, setIsEdit] = useState(true);

    useEffect(() => {
        if (cassette?.cassetteId) {
            const foundItem = cassettesLines.find((item) => item.cassetteId === cassette.cassetteId);
            setLines(foundItem?.lineEntities)
        }

        if (cassette?.box_bottom_id) {
            const foundItem = cassettesLines.find((item) => item.box_bottom_id === cassette.box_bottom_id);
            setLines(foundItem?.lineEntities)
        }
    }, [cassette]);

    return (
        <>
            {lines && lines.map((line, i) => (
                <LineCassetteInput
                    cables={cables}
                    cassetteId={cassette.cassetteId}
                    lineData={line}
                    // handleDelete={() => handleDelete(i)}
                    key={i}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    lines={lines}
                    setLines={setLines}
                />
            ))}
        </>
    )
}

export default EditLinesCassetteDrawer;