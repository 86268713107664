import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {AutoComplete, Breadcrumb, Button, Input, Space} from "antd";
import Search from "antd/es/input/Search";
import {PlusOutlined} from "@ant-design/icons";

const BreadCrumb = ({ children, history, data, setKey, handleClick, btnName, page }) => {
    const currentPath = useLocation();
    const menuItems = history?.filter((path, index) => path !== currentPath.pathname && history.indexOf(path) === index).map((item, index) => ({
        key: index,
        label: (
            <Link to={item}>
                {item}
            </Link>
        ),
    }))

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/dashboard">Tableau de bord</Link>,
                                },
                                {
                                    title: 'Historiques',
                                    menu: {
                                        items: menuItems.reverse(),
                                    },
                                },
                                {
                                    title: <Link to={currentPath.pathname}>{page}</Link>,
                                },
                            ]}
                        />
                        <div className="search-box">
                            <Space>
                                {children && children()}
                                {data && (
                                    <AutoComplete
                                        open={false}
                                        style={{
                                            width: 200,
                                        }}
                                        options={data}
                                        onChange={(e) => setKey(e)}
                                        placeholder="Recherche..."
                                        filterOption={(inputValue, option) =>
                                            option?.name?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }

                                    />
                                )}
                                {btnName && (
                                    <Button
                                        className="ms-2"
                                        type="primary"
                                        icon={<PlusOutlined/>}
                                        onClick={handleClick}
                                    >{btnName}
                                    </Button>
                                )}
                            </Space>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;