import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createClient, updateClient } from "../../../api/clients/clientsApi";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const AddEditForm = ({ modal, setModal, toggle, isEdit, client }) => {
  const queryClient = useQueryClient();

  const addClientMutation = useMutation(createClient, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("clients");
      toast.success("Le Client a été créé avec succès");
    },
    onError: (error) => {
      // An error happened!
      toast.error(error);
    },
  });

  const updateClientMutation = useMutation(updateClient, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("clients");
      toast.success("Le Client a été modifié avec succès");
    },
  });

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      clientImgUrl: "",
      name: (client && client.name) || "",
      address: (client && client.address) || "",
      email: (client && client.email) || "",
      phone: (client && client.phone) || "",
      fax: (client && client.fax) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Le nom de l'entreprise est obligatoire"),
      address: Yup.string().required(
        "L'adresse de l'entreprise est obligatoire"
      ),
      email: Yup.string().required("L'email de l'entreprise est obligatoire"),
      phone: Yup.string().required(
        "Le Téléphone de l'entreprise est obligatoire"
      ),
      fax: Yup.string(), //.required("Please Enter Company Fax"),
    }),
    onSubmit: (values) => {
      const { name, address, email, phone, fax, clientImageUrl } =
        validation.values;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("address", address);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("fax", fax);
      formData.append("clientImageUrl", clientImageUrl);
      formData.append(
        "company",
        JSON.parse(sessionStorage.getItem("authUser"))?.company
      );
      if (isEdit) {
        const data = {
          formData,
          clientId: client && client.id,
        };
        updateClientMutation.mutate(data);
        validation.resetForm();
      } else {
        const data = {
          client: {
            name, address, email, phone, fax, clientImageUrl,
            "company": JSON.parse(sessionStorage.getItem("authUser"))?.company
          },
        };
        addClientMutation.mutate(data);
        validation.resetForm();
      }
      toggle();
    },
  });

  return (
    <Modal
      id="showModalClient"
      isOpen={modal}
      toggle={toggle}
      centered
      size="md"
      backdrop={"static"}
    >
      <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
        {!!isEdit ? "Mise a jour Client" : "Nouveau Client"}
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <input type="hidden" id="id-field" />
          <Row className="g-3">
            {/* <Col lg={12}>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light rounded-circle">
                                    <img
                                      src={multiUser}
                                      alt="multiUser"
                                      id="companylogo-img"
                                      className="avatar-md rounded-circle object-fit-cover"
                                    />
                                  </div>
                                </div>
                              </div>
                              <h5 className="fs-13 mt-3">Logo Entreprise</h5>
                            </div>
                          </Col> */}

            <Col lg={12}>
              <div>
                <Label htmlFor="name-field" className="form-label">
                  Nom Entreprise
                  <span className="text-danger mx-1">*</span>
                </Label>

                <Input
                  name="name"
                  id="customername-field"
                  className="form-control"
                  placeholder="Nom de l'entreprise client"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <Label htmlFor="owner-field" className="form-label">
                  Adresse
                  <span className="text-danger mx-1">*</span>
                </Label>
                <Input
                  name="address"
                  id="owner-field"
                  className="form-control"
                  placeholder="Adresse de l'entreprise client"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address || ""}
                  invalid={
                    validation.touched.address && validation.errors.address
                      ? true
                      : false
                  }
                />
                {validation.touched.address && validation.errors.address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.address}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <Label htmlFor="star_value-field" className="form-label">
                  Email
                  <span className="text-danger mx-1">*</span>
                </Label>
                <Input
                  name="email"
                  id="star_value-field"
                  className="form-control"
                  placeholder="Email de l'entreprise client"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor="location-field" className="form-label">
                  Téléphone
                  <span className="text-danger mx-1">*</span>
                </Label>
                <Input
                  name="phone"
                  id="star_value-field"
                  className="form-control"
                  placeholder="Téléphone de l'entreprise client"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor="fax-field" className="form-label">
                  Fax
                </Label>
                <Input
                  name="fax"
                  id="fax-field"
                  className="form-control"
                  placeholder="Fax de l'entreprise client"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.fax || ""}
                  invalid={
                    validation.touched.fax && validation.errors.fax
                      ? true
                      : false
                  }
                />
                {validation.touched.fax && validation.errors.fax ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fax}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              color="light"
              onClick={() => {
                setModal(false);
              }}
            >
              {" "}
              Fermer{" "}
            </Button>
            <Button type="submit" color="success" id="add-btn">
              {" "}
              {!!isEdit ? "Modifier" : "Enregistrer"}{" "}
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddEditForm;
