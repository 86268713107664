import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "react-lazy-load-image-component/src/effects/blur.css";
import MemberCard from "./memberCard";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined, FileDoneOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Divider, Flex, Tag } from 'antd';

const text_color = (value)=>{
  
  switch (value) {
    case "In_Progress":
      return "text-primary";
      break;
    case "Completed":
      return "text-success"
      break;
    case "Validated":
      return "text-warning"
      break;
    default:
      return "text-body";
      break;
  }
}

const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

const handleValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("HH:mm");
  // const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};

const Project = (cell) => {
 return (
    <React.Fragment>
      {cell?.value && !cell?.value?.projectId == 0 ? (
        <Link
          to={`/projects/overview/${cell?.value?.projectId}`}
          className="fw-medium link-secondary"
        >
          <span className={"d-inline-block text text-truncate "+text_color(cell.row.values.status)} style={{ maxWidth: "80px" }}>{cell?.value?.projectName}</span>
        </Link>
      ) : (
        <Link className={"fw-medium link-secondary "+text_color(cell.row.values.status)}>
          Aucun projet
        </Link>
      )}
    </React.Fragment>
  );
};

const Tasks = (cell) => {
  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="flex-grow-1 tasks_name">{cell.value}</div>
        <div className="flex-shrink-0 ms-4">
          <ul className="list-inline tasks-list-menu mb-0">
            <li className="list-inline-item">
              <Link to="/apps-tasks-details">
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#">
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              </Link>
            </li>
            <li className="list-inline-item">
              <a
                className="remove-item-btn"
                data-bs-toggle="modal"
                href="#deleteOrder"
              >
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

const Createdat = (cell) => {
  return (
    <React.Fragment >
      <span className={"text "+text_color(cell.row.values.status)}>
      {handleValidDate(cell.value)}, {handleValidTime(cell.value)}
      </span>
    </React.Fragment>
  );
};

const Modifitedat = (cell) => {
  return (
    <React.Fragment>
      <span className={"text "+text_color(cell.row.values.status)}>
      {handleValidDate(cell.value)}, {handleValidTime(cell.value)} 
      </span>
    </React.Fragment>
  );
};

const Address = (cell) => {
  return <React.Fragment>
    <span 
      data-bs-toggle="tooltip"
      data-bs-trigger="hover"
      data-bs-placement="top"
      title={cell.value}
      className={"d-inline-block text-truncate " +text_color(cell.row.values.status)} 
      style={{ maxWidth: "90px" }}>{cell.value}</span>
    </React.Fragment>;
};

const AssignedTo = ({cell, onClickDeleteUser}) => {
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }

  return (
    <React.Fragment>
        {cell?.value.map((item, index) => {
          return (
            <Link
              key={index}
              onClick={() => {
                tog_backdrop();
                setUserInfo(item);
              }}
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-placement="top"
              title={`${item?.firstName} ${item?.lastName}`}
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="flex-shrink-0 avatar-xs me-1">
                    <div className={`avatar-title bg-secondary-subtle rounded-circle fs-10 `+text_color(cell.row.values.status)}>
                      {item?.firstName.charAt(0).toUpperCase()}
                      {item?.lastName.charAt(0).toUpperCase()}
                    </div>
                    {/* <img src={avatar} className="avatar-xxs rounded-circle" /> */}
                  </div>
                </div>
                <div className={"flex-grow-1 ms-1 name fs-12 "+text_color(cell.row.values.status)}>
                  {`${item?.firstName} ${item?.lastName}`}
                </div>
              </div>
            </Link>
          )
          // return !isEmpty(item?.imageUrl) ? (
          //   <Link
          //     key={index}
          //     onClick={() => {
          //       tog_backdrop();
          //       setUserInfo(item);
          //     }}
          //     className="avatar-group-item"
          //     data-bs-toggle="tooltip"
          //     data-bs-trigger="hover"
          //     data-bs-placement="top"
          //     title={`${item?.firstName} ${item?.lastName}`}
          //   >
          //     <LazyLoadImage
          //       effect="blur"
          //       src={`https://ik.imagekit.io/piscodeId/${item?.imageUrl}`}
          //       alt=""
          //       className="rounded-circle avatar-xxs"
          //     />
          //   </Link>
          // ) : (
          //   <Link
          //     key={index}
          //     onClick={() => {
          //       tog_backdrop();
          //       setUserInfo(item);
          //     }}
          //     className="avatar-group-item"
          //     data-bs-toggle="tooltip"
          //     data-bs-trigger="hover"
          //     data-bs-placement="top"
          //     title={`${item?.firstName} ${item?.lastName}`}
          //   >
          //     <img src={avatar} className="avatar-xxs rounded-circle" />
          //   </Link>
          // )
        })}
      <MemberCard 
        userId={userInfo?.userId}
        taskId={cell.row.original.id}
        modal_backdrop={modal_backdrop}
        setmodal_backdrop={setmodal_backdrop}
        onClickDelete={onClickDeleteUser}
      />
    </React.Fragment>
  );
};

const DueDate = (cell) => {
  return (
    <React.Fragment>
      <span className={"text "+text_color(cell.row.values.status)}> 
      {cell.value ? handleValidDate(cell.value) : "-- / -- / ----"}
  
      </span>
    </React.Fragment>
  );
};

const Status = (cell) => {
 
  return (
    <React.Fragment>
      {cell.value === "In_Progress" ? (
        // <span className="badge bg-secondary-subtle text-secondary text-uppercase">
        //   En Cours
        // </span>
          <Tag icon={<SyncOutlined spin />} color="processing">
            En cours
          </Tag>
      ) : cell.value === "Affected" ? (
        // <span className="badge bg-primary-subtle text-primary text-uppercase">
        //   Assignée
        // </span>
          <Tag icon={<ClockCircleOutlined />} color="default">
            En attente
          </Tag>
      ) : cell.value === "Created" ? (
        // <span className="badge bg-danger-subtle text-danger text-uppercase">
        //   Créée
        // </span>
          <Tag icon={<ClockCircleOutlined />} color="default">
            En attente
          </Tag>
      ) : cell.value === "Completed" ? (
        // <span className="badge bg-info-subtle text-info text-uppercase">
        //   Complète
        // </span>
          <Tag icon={<CheckCircleOutlined />} color="success">
            Complète
          </Tag>
      ) : cell.value === "Validated" ? (
        // <span className="badge bg-warning-subtle text-warning text-uppercase">
        //  Validée
        // </span>
          <Tag icon={<FileDoneOutlined />} color="warning">
            Validée
          </Tag>
      ) : cell.value === "Shared" ? (
        <span className="badge bg-success-subtle text-success text-uppercase">
          Partagée
        </span>
      ) : null}
    </React.Fragment>
  );
};

const Priority = (cell) => {
  return (
    <React.Fragment>
      {cell.value === "Medium" ? (
        <span className="badge bg-primary text-uppercase">Important</span>
      ) : cell.value === "High" ? (
        <span className="badge bg-warning text-uppercase">Urgent</span>
      ) : cell.value === "Highest" ? (
        <span className="badge bg-danger text-uppercase">Très urgent</span>
      ) : null}
    </React.Fragment>
  );
};

export {
  Project,
  Tasks,
  Createdat,
  Modifitedat,
  AssignedTo,
  DueDate,
  Status,
  Priority,
  Address,
  text_color
};
