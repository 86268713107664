import {Drawer, Tag} from "antd";
import React from "react";
import PreviewCompanyImg from "../components/previewCompanyImg";
import logoDark from "../../../assets/images/companies/company-logo.png";
import userImg from "../../../assets/images/users/avatar-user.png";
import {Card, CardBody, Col, Input, Label, Row} from "reactstrap";
import AppSummary from "../components/AppSummary";

const SpaceOverview = ({space, openSpace, setOpenspace}) => {
    const onClose = () => {
        setOpenspace(false);
    };
    return (
        <>
            <Drawer
                zIndex={9999}
                title={`${space?.clientSpace?.companyName}`}
                width={520}
                onClose={onClose}
                open={openSpace}
                maskClosable={false}
            >
                <Card
                    className="job-list-view-card overflow-hidden"
                    id="job-overview"
                >
                    {space?.clientSpace?.logoUrl ? (
                        <img
                            src={`${process.env.REACT_APP_API_IMG_URL}/${space?.clientSpace?.logoUrl}`}
                            alt=""
                            id="cover-img"
                            className="img-fluid background object-fit-cover"
                        />
                    ) : (
                        <img
                            src={logoDark}
                            alt=""
                            id="cover-img"
                            className="img-fluid background object-fit-cover"
                        />
                    )}
                    <CardBody>
                        <div className="avatar-md mt-n5">
                            <div className="avatar-title bg-light rounded-circle">
                                {space?.admin?.userImageUrl ? (
                                    <img
                                        src={`${process.env.REACT_APP_API_IMG_URL}/${space?.admin?.userImageUrl}`}
                                        alt=""
                                        className="avatar-xs view-companylogo"
                                    />
                                ) : (
                                    <img
                                        src={userImg}
                                        alt=""
                                        className="avatar-xs view-companylogo"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="mt-3">
                            <h5 className="view-title">{space?.clientSpace?.companyName}</h5>
                            <div className="hstack gap-3 mb-3">
                      <span className="text-muted">
                        <i className="ri-user-line me-1 align-bottom"></i>{" "}
                          <span className="view-companyname">{space?.admin?.firstname} {space?.admin?.lastname}</span>
                      </span>
                      <span className="text-muted">
                        <i className="ri-lock-2-line me-1 align-bottom"></i>{" "}
                        <Tag color="#87d068">Activé</Tag>
                      </span>
                            </div>
                            <div className="py-3 border border-dashed border-start-0 border-end-0 mt-4">
                                <Row>
                                    <Col lg={4} sm={6}>
                                        <div>
                                            <p className="mb-2 text-uppercase fw-semibold fs-12 text-muted">
                                                Licence
                                            </p>
                                            <h5 className="fs-14 mb-0 view-type">En cours</h5>
                                        </div>
                                    </Col>
                                    <Col lg={4} sm={6}>
                                        <div>
                                            <p className="mb-2 text-uppercase fw-semibold fs-12 text-muted">
                                                Technicien(s)
                                            </p>
                                            <h5 className="fs-14 mb-0 view-postdate">
                                                {space?.clientSpace?.techNumber}
                                            </h5>
                                        </div>
                                    </Col>
                                    <Col lg={4} sm={6}>
                                        <div>
                                            <p className="mb-2 text-uppercase fw-semibold fs-12 text-muted">
                                                Ingénieur(s)
                                            </p>
                                            <h5 className="fs-14 mb-0 view-experience">
                                                {space?.clientSpace?.engineerNumber}
                                            </h5>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="mt-4">
                            <h5 className="mb-3">Gestion Espace</h5>
                            <div>
                                <AppSummary dataColors='["--vz-info", "--vz-primary"]' />
                            </div>
                        </div>
                        <div className="mt-4">
                            <a target="_blank" href="http://localhost:3000/login" type="button" className="btn btn-secondary w-100">
                                Connexion
                            </a>
                        </div>
                    </CardBody>
                </Card>
            </Drawer>
        </>
    )
}

export default SpaceOverview;