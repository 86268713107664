import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import SimpleBar from "simplebar-react";
import { PriorityCode } from "../../helpers/priority_code_color";
import toast from "react-hot-toast";
import TechnicianList from "./TechnicianList";
import AddEditForm from "./component/addEditForm";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteTask, getTasks, updateTask } from "../../api/planning/planningApi";
import {AutoComplete, Badge, Button, Empty, FloatButton, Progress, Space, Spin, Tooltip} from "antd";
import HistoryContext from "../../Components/Hooks/HistoryContext";
import {getMembers} from "../../api/users/usersApi";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
  SyncOutlined
} from "@ant-design/icons";

const Planning = () => {
  const history = useContext(HistoryContext)
  document.title = `Planning | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;

  const queryClient = useQueryClient();
  const {
      isError,
      error,
      data: events,
  } = useQuery("tasks", getTasks);
  const {isLoading, data:techniciansQuery} = useQuery("users", getMembers, {
    select: (data) => data.filter((item) => item.userRole === 'ROLE_MOBILE_USER')
  })

  const [key, setKey] = useState("");
  const filteredData = techniciansQuery?.filter((item) =>
      item.firstname.toLowerCase().includes(key?.toLowerCase())
  );

  const [filteredTask, setFilteredTask] = useState([]);
  const [taskKey, setTaskKey] = useState(null);
  // const filteredTaskByStatus = events?.filter((item) => item.status === taskKey);
  // const filteredTaskByTech = events?.filter((item) => item.technicianId === taskKey);
  // const filteredTaskByName = events?.filter((item) => item.title.toLowerCase().includes(taskKey?.toLowerCase()));

  useEffect(() => {
    if (taskKey) {
      const filter = events?.filter((item) => item.status === taskKey || item.technicianId === taskKey)
      setFilteredTask(filter)
    }
  },[taskKey])

  const [event, setEvent] = useState({});
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(true);
  const [techniciens, setTechniciens] = useState([]);
  const [displayList, setDisplayList] = useState(false)
  const [eventsPerDay, setEventsPerDay] = useState([])
  const [eventsFilter, setEventsFilter] = useState([])

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setIsEdit(false);
      setIsEditButton(true);
    } else {
      setModal(true);
    }
  };

  useEffect(() => {
    setFilteredTask(events)
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, [events])

  const deleteTaskMutation = useMutation(deleteTask, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("tasks");
      toast.success("La tâche a été supprimé avec succès")
    },
  });

  const updateTaskMutation = useMutation(updateTask, {
    onSuccess: () => {
    // Invalidates cache and refetch data
    queryClient.invalidateQueries("tasks");
    toast.success("La tâche a été modifié avec succès")
    setSelectedItem(null)
    },
});

  // useEffect(() => {
  //   dispatch(onGetEvents());
  //   new Draggable(document.getElementById("external-events"), {
  //     itemSelector: ".external-event",
  //   });
  // }, [dispatch]);

  const onDrag = (item) => {
    setSelectedItem(item)
  };

  const handleDateClick = (arg) => {
    setEvent({
      dueDate: moment(arg.start).format('yyyy-MM-DDThh:mm:ss'),
      endDate: moment(arg.end).format('yyyy-MM-DDThh:mm:ss'),
    });
    toggle();
  };

  const handleEventClick = (arg) => {
    const event = events.filter((event) => event.id == arg.event._def.publicId);

    setEvent({
      id: event[0].id,
      name: event[0].title,
      description: event[0].description,
      address: event[0].location,
      dueDate: event[0].start,
      endDate: event[0].end,
      priority: event[0].priority,
      status: event[0].status,
      userId: event[0].technicianId
    });

    setIsEdit(true);
    setIsEditButton(false);
    toggle();
  };

  const handleDeleteEvent = () => {
    deleteTaskMutation.mutate(event.id)
    // dispatch(onDeleteEvent(event.id));
    setDeleteModal(false);
    toggle();
  };

  const submitOtherEvent = () => {
    document.getElementById("form-event").classList.remove("view-event");

    document
      .getElementById("event-title")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-category")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-start-date")
      .parentNode.classList.remove("d-none");
    document
      .getElementById("event-start-date")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-end-date")
      .parentNode.classList.remove("d-none");
    document
      .getElementById("event-end-date")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-location")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-description")
      .classList.replace("d-none", "d-block");
    document
      .getElementById("event-start-date-tag")
      .classList.replace("d-block", "d-none");
    document
      .getElementById("event-location-tag")
      .classList.replace("d-block", "d-none");
    document
      .getElementById("event-description-tag")
      .classList.replace("d-block", "d-none");
    // document.getElementById("btn-save-event").removeAttribute("hidden");
    setIsEditButton(true);
  };

  const onDrop = (event) => {
    if (selectedItem) {
      const updateDateTask = {
        id: selectedItem?.id,
        name: selectedItem?.title,
        description: selectedItem?.description,
        address: selectedItem?.location,
        priority: selectedItem?.priority,
        status: "In_Progress",
        dueDate: event.date,
        endDate: event.date,
        interlocFirstname: "",
        interlocLastname: "",
        interlocEmail: "",
        interlocPhone: "",
      };
      updateTaskMutation.mutate({formData: updateDateTask, id: selectedItem?.id, userId: null})
      // dispatch(onUpdateEvent(updateDateTask)).then((result) => {
      //   if (!result.error) {
      //     setSelectedItem(null)
      //   }
      // });
    } 
    // else {
    //   const date = event["date"];
    //   const day = date.getDate();
    //   const month = date.getMonth();
    //   const year = date.getFullYear();
  
    //   const currectDate = new Date();
    //   const currentHour = currectDate.getHours();
    //   const currentMin = currectDate.getMinutes();
    //   const currentSec = currectDate.getSeconds();
    //   const modifiedDate = new Date(
    //     year,
    //     month,
    //     day,
    //     currentHour,
    //     currentMin,
    //     currentSec
    //   );
  
    //   const draggedEl = event.draggedEl;
    //   const draggedElclass = draggedEl.className;
    //   if (
    //     draggedEl.classList.contains("external-event") &&
    //     draggedElclass.indexOf("fc-event-draggable") === -1
    //   ) {
    //     const addNewTask = {
    //       // name: task?.title,
    //       // description: task?.description,
    //       // address: task?.location,
    //       // dueDate: event.date,
    //       // endDate: event.date,
    //       // priority: task?.priority,
    //       // interlocFirstname: "",
    //       // interlocLastname: "",
    //       // interlocEmail: "",
    //       // interlocPhone: "",
    //     };
    //     dispatch(onUpdateEvent(addNewTask));
    //   }
    // }
  };

  const onEventDrop = (item) => {
    const updateDateTask = {
      ...item,
      status: "In_Progress",
      interlocFirstname: "",
      interlocLastname: "",
      interlocEmail: "",
      interlocPhone: "",
    }
    updateTaskMutation.mutate({formData: updateDateTask, id: item?.id, userId: null})
    // dispatch(onUpdateEvent(updateDateTask)).then((result) => {
    //   if (!result.error) setSelectedItem(null)
    // });
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
              page="Planning"
              history={history}
              handleClick={toggle}
              btnName="Nouvelle Tâche"
          />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={2} >
                  <Card className="card-h-100 border border-dark">
                    <CardBody>
                      <AutoComplete
                          open={false}
                          style={{
                            width: '100%',
                          }}
                          options={techniciansQuery}
                          onChange={(e) => setKey(e)}
                          placeholder="Recherche..."
                          filterOption={(inputValue, option) =>
                              option?.firstname?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }

                      />

                      <div id="external-events">
                        <br />
                        <SimpleBar className="chat-room-list">
                          <ul className="list-unstyled contact-list">
                            {filteredData?.map((item, key) => (
                                <li key={key} className="ps-0 pe-0" onClick={() => setTaskKey(item.userId)}>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-2">
                                      <div className="avatar-xxs">
                                        {item.userImgUrl ?
                                            <img src={`${process.env.REACT_APP_API_IMG_URL}/${item.userImgUrl}`}
                                                 className="img-fluid rounded-circle" alt=""/> :
                                            <span className="avatar-title rounded-circle bg-primary fs-10">
                                              {item.firstname.charAt(0)}{item.lastname.charAt(0)}
                                            </span>}
                                      </div>
                                    </div>
                                    <div className="flex-grow-1" onClick={(e) => {
                                      // userChatOpen(
                                      //     item.id,
                                      //     item.name,
                                      //     item.status,
                                      //     item.roomId,
                                      //     item.image
                                      // );
                                    }}>
                                      <p className="text-truncate contactlist-name mb-0"
                                         style={{maxWidth: "140px"}}>{item.firstname} {item.lastname}</p>
                                    </div>
                                    <div className="flex-shrink-0 me-2">
                                      <Tooltip title={
                                        <Space direction="horizontal">
                                          <Badge count={item?.waitingTasks} showZero color="#1E1E1E" />
                                          <Badge count={item?.inProgressTasks} showZero color="#598BFF" />
                                          <Badge count={item?.completedTasks} showZero color="#77D6D9" />
                                          <Badge count={item?.validatedTasks} showZero color="#FBAD58" />
                                        </Space>
                                      } color="#F1F1F1">
                                        <i className="ri-todo-line fs-6"/>
                                      </Tooltip>
                                      <Tooltip title={<Progress percent={item?.progression} steps={10} />} color="#F1F1F1">
                                        <i className="ri-timer-line fs-6"/>
                                      </Tooltip>
                                    </div>
                                    <div className="flex-shrink-0 me-1">
                                      <UncontrolledDropdown>
                                        <DropdownToggle tag="a" className="text-muted">
                                          <i className="ri-more-2-fill"/>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem><i className="ri-user-2-line text-muted me-2 align-bottom"/>Profil</DropdownItem>
                                          <DropdownItem><i
                                              className="ri-delete-bin-6-line text-muted me-2 align-bottom"/>Supprimer</DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                </li>))}
                          </ul>
                        </SimpleBar>

                      </div>
                    </CardBody>
                  </Card>
                  {/*{displayList ? (*/}
                  {/*    <Card className="card-h-100">*/}
                  {/*      <CardHeader>*/}
                  {/*        <h6>Techniciens</h6>*/}
                  {/*      </CardHeader>*/}
                  {/*      <CardBody>*/}
                  {/*        <div id="external-events">*/}
                  {/*          <SimpleBar style={{height: "250px"}} className="px-3 mx-n3 mb-2">*/}
                  {/*            {[...eventsPerDay.reduce((mp, o) => {*/}
                  {/*              if (!mp.has(o.technicianId)) mp.set(o.technicianId, {...o, count: 0});*/}
                  {/*              mp.get(o.technicianId).count++;*/}
                  {/*              return mp;*/}
                  {/*            }, new Map).values()].map((item) => <TechnicianList technicianId={item?.technicianId}*/}
                  {/*                                                                taskCount={item?.count}/>)}*/}
                  {/*          </SimpleBar>*/}
                  {/*        </div>*/}
                  {/*      </CardBody>*/}
                  {/*    </Card>*/}
                  {/*) : null}*/}
                </Col>

                <Col xl={10}>
                  <Card className="card-h-100 border border-dark ">
                    <CardBody>
                      <FullCalendar
                          businessHours={{
                            // days of week. an array of zero-based day of week integers (0=Sunday)
                            daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Saturday

                            startTime: '8:00', // a start time (10am in this example)
                            endTime: '17:00', // an end time (6pm in this example)
                          }}
                          datesSet={(arg) => {
                            arg.view.type === 'dayGridDay' ? setDisplayList(true) : setDisplayList(false)
                            setEventsPerDay(events?.filter((item) => (arg.start.getTime() >= new Date(item.start).getTime() && arg.start.getTime() <= new Date(item.end).getTime() - 1)));
                          }}
                          plugins={[
                            // BootstrapTheme,
                            dayGridPlugin,
                            interactionPlugin,
                            listPlugin
                          ]}

                          initialView="dayGridWeek"
                          firstDay= '1'
                          locale= 'fr'
                          slotDuration={"00:15:00"}
                          handleWindowResize={true}
                          // themeSystem="bootstrap"
                          headerToolbar={{
                            left: "prevYear,prev,next,nextYear",
                            center: "title",
                            right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                          }}
                          eventMouseEnter={(info) => {
                          }}
                          events={filteredTask}

                          // events={events.filter((task) => task.start?.length !== 0 || task.end?.length !== 0)}
                          editable={true}
                          droppable={true}
                          selectable={true}
                          eventClick={handleEventClick}
                          select={handleDateClick}
                          drop={onDrop}

                          eventDrop={(arg) => {
                            const task = events.filter((event) => event.id == arg.event._def.publicId);
                        if (task[0]) {
                          onEventDrop({
                            id: task[0]?.id,
                            name: task[0]?.title,
                            description: task[0]?.description,
                            address: task[0]?.location,
                            priority: task[0]?.priority,
                            dueDate: arg.event._instance.range.start,
                            endDate: arg.event._instance.range.end,
                          });
                        }
                      }}
                    />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div style={{ clear: "both" }}></div>
              
              <AddEditForm
                modal={modal}
                toggle={toggle}
                isEdit={isEdit}
                event={event}
                isEditButton={isEditButton}
                submitOtherEvent={submitOtherEvent}
                setDeleteModal={setDeleteModal}
              />

            </Col>
          </Row>
          <FloatButton.Group
              shape="square"
              style={{
                right: 94,
              }}
          >
            <Tooltip title="En attentes">
              <FloatButton onClick={() => setTaskKey("Affected")} icon={<ClockCircleOutlined style={{color: "#1E1E1E"}} />} />
            </Tooltip>
            <Tooltip title="En cours">
              <FloatButton onClick={() => setTaskKey("In_Progress")} icon={<SyncOutlined style={{color: "#598BFF"}} />} />
            </Tooltip>
            <Tooltip title="Terminées">
              <FloatButton onClick={() => setTaskKey("Completed")} icon={<CheckCircleOutlined style={{color: "#77D6D9"}} />} />
            </Tooltip>
            <Tooltip title="Validées">
              <FloatButton onClick={() => setTaskKey("Validated")} icon={<FileDoneOutlined style={{color: "#FBAD58"}} />} />
            </Tooltip>
          </FloatButton.Group>
        </Container>
      </div>
    </React.Fragment>
  );

};

Planning.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Planning;
