import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react'

const styles = StyleSheet.create({
   row:{
    flexDirection:"row",
    borderBottomWidth:1
   }
   ,
   tab:{
    flex:1,
    padding:10,
   
   }
   
  });

const PdfRowData = ({titre , value }) => {
  return (
  <View style={styles.row}>
   
    <View style={styles.tab}>
        <Text style={{fontSize:13}}>{titre}</Text>
    </View>

    <View style={styles.tab}>
        {value}
    </View>
    
  </View>
  )
}

export default PdfRowData;
