import React, {useContext} from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import Widgets from "./component/Widgets";
import ActiveProjects from "./component/ActiveProjects";
import TasksSchedules from "./component/TasksSchedules";
import { ProjectRadialBar, TaskRadialBar } from "./component/DashboardChart";
import { useQuery, useQueryClient } from "react-query";
import { getDashboardMetrics } from "../../api/dashboard/dashboardAPI";
import DashboardSkeleton from "../../Components/Common/skeletons/dashboardSkeleton";
import GoogleMapsBalises from "./component/GoogleMapsBalises";
import {Button, Result} from "antd";
import HistoryContext from "../../Components/Hooks/HistoryContext";

const index = () => {
  const history = useContext(HistoryContext)
  document.title = `Tableau de bord | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;

  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    error,
    data: metrics,
  } = useQuery("metrics", getDashboardMetrics);

  if (isLoading) {
    return <DashboardSkeleton/>;
  }

  if (isError) {
    return (
        <Result
            status="warning"
            title={`${error.message}}`}
            extra={
              <Button type="primary" key="console" onClick={() => window.location.reload()}>
                Refresh
              </Button>
            }
        />
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb page="Tableau de bord" history={history} />
            <Row>
              <Widgets metrics={metrics} />
              <Col xl={6}>
                <GoogleMapsBalises balises={metrics?.boxPlaces} />
              </Col>
            </Row>
            <Row>
              <ActiveProjects projects={metrics?.projects} />
              <Col xxl={3} >
                <Card className="border border-dark">
                  {/*<CardHeader>*/}
                  {/*  <h4 className="card-title mb-0">TÂCHES</h4>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <TaskRadialBar
                        metrics={metrics}
                        dataColors='["#C9C9C9", "#2642FF", "#BCED09", "--vz-dark"]'
                    />
                    <ProjectRadialBar
                        metrics={metrics}
                        dataColors='["#C9C9C9", "#2642FF", "#BCED09", "--vz-dark"]'
                    />
                  </CardBody>
                </Card>
              </Col>
              {/*<Col xxl={4}>*/}
              {/*  <Card className="border border-dark">*/}
              {/*    <CardHeader>*/}
              {/*      <h4 className="card-title mb-0">PROJETS</h4>*/}
              {/*    </CardHeader>*/}
              {/*    <CardBody>*/}
              {/*      <ProjectRadialBar*/}
              {/*          metrics={metrics}*/}
              {/*          dataColors='["#C9C9C9", "#2642FF", "#BCED09", "--vz-dark"]'*/}
              {/*      />*/}
              {/*    </CardBody>*/}
              {/*  </Card>*/}
              {/*</Col>*/}
              <TasksSchedules tasks={metrics?.tasks} />
            </Row>
            <Row>

            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default index;
